import React, {useRef} from "react";
import TestimonialCard from "../../../components/Cards/Testimonial/TestimonialCard";
import Carousel from "react-elastic-carousel";
import "./Testimonial.scss";
import Community from "../../../components/Community/Community.jsx"

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 768, itemsToShow: 2 },
  { width: 1200, itemsToShow: 2 },
];

const Testimonial = () => {
  const carousel = useRef(null);
  return (
    <div className="testimonial-box">
      <div className="testimonial-description">
        <h2 className="testimonials-heading">Testimonials</h2>
        <p className="testimonials-heading-desc">What our 25000+ students have to say</p>
        <div className="testimonial-carousel-container">

          <Carousel
            ref={carousel}
            // renderArrow={myArrow}
            className="testimonial-carousal"
            showArrows={false}
            showEmptySlots={false}
            itemPadding={[5, 5]}
            focusOnSelect={true}
            breakPoints={breakPoints}
          >
            <TestimonialCard name={"ARYAN SRIVASTAVAV"} text={"When I joined the self-paced course to acquaint myself with the basics of Cloud Computing, I was full of doubts. But a week into the course, I was totally interested in the course and even started enjoying it. Foxmula has great videos and study material. The course not only taught me about cloud computing but also about the job market and how to actually handle interviews. Although I was initially confused, now I know my stuff and am very grateful to Foxmula for their platform."}></TestimonialCard>
            <TestimonialCard name={"Parul Manhas"} text={"Foxmula's courses have truly transformed the track of my career for good. Their courses have enhanced my skills and have equipped me with the best technologies to make myself valuable in the job market. I got a job in a reputed MNC through them and it has really helped me achieve a life I always wanted for myself."}></TestimonialCard>
            <TestimonialCard name={"SULAGNA CHATTERJEE"} text={"I am very satisfied with their courses. I thought I knew a lot about Machine Learning before I joined but these courses taught me things I wasn't even aware of. Their teaching content is simply great!"}></TestimonialCard>
            <TestimonialCard name={"Aryan Atul"} text={"I participated in their intensive profile building program and it has been the best decision I made career wise. The live sessions were so informative and the internship experience was nice too."}></TestimonialCard>
            <TestimonialCard name={"Abhiroop Banerjee"} text={"Foxmula's project based internship is a godsend for everyone who is not sure how to enter the industry or get the right job. These people train you so well and work on your strengths to make them better. They offer so many options and without any upfront fees which is really great."}></TestimonialCard>
            <TestimonialCard name={"Axat Shah"} text={"I did the MTA certification from Microsoft and it has been a great help in landing me various internships. I never thought I would ever get the ability to make a choice but this certification has really made my resume a lot better than it was before it."}></TestimonialCard>
            <TestimonialCard name={"Muskan Saxena"} text={"My experience with Foxmula is very positive. Their videos are informative and mentors are knowledgeable. They provide various opportunities to grow and it has been a successful journey with them."}></TestimonialCard>
            <TestimonialCard name={"Sachin Kelwa"} text={"The courses offered by Foxmula and the project based internship that followed it was a really great experience for me. It was fun learning with them."}></TestimonialCard>
            <TestimonialCard name={"Avinash Sinha"} text={"Foxmula has great support which has made my experience with the course really good. I would recommend it to everyone who is looking to learn new skills."}></TestimonialCard>
            <TestimonialCard name={"Abhishek Raj"} text={"I did a Microsoft course with Foxmula and it was really nice. Without a doubt a great experience and got to learn a lot too which has helped my prospects."}></TestimonialCard>
          </Carousel>
          <button onClick={() => carousel.current.slidePrev()} className="arrow-prev">❮</button>
          <button onClick={() => carousel.current.slideNext()} className="arrow-next">❯</button>
        </div>
      </div>

      <Community/>

    </div>
  );
};

// Index.getInitialProps = ({ req }) => {
//   let userAgent;
//   if (req) {
//     userAgent = req.headers["user-agent"];
//   } else {
//     userAgent = navigator.userAgent;
//   }
//   const parser = new UAParser();
//   parser.setUA(userAgent);
//   const result = parser.getResult();
//   const deviceType = (result.device && result.device.type) || "desktop";
//   return { deviceType };
// };
export default Testimonial;
