import React, { useRef } from "react";
import Carousel from "react-elastic-carousel";

import Adobe from '../../../../assests/images/certificates/adobe_certificate.jpeg'
import foxmula from '../../../../assests/images/certificates/foxmula_certificate.jpeg'
import internship from '../../../../assests/images/certificates/internship.png'
import "./Microsoft.scss";

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 },
];


const Microsoft = () => {
    const carousel = useRef(null);
    const textCarousel = useRef(null)
    return (
        <div className="Microsoft color-primary">
            <div className="Microsoft-left">
                <div className="testimonial-carousel-container">

                    <Carousel
                        ref={carousel}
                        // renderArrow={myArrow}
                        className="testimonial-carousal"
                        showArrows={false}
                        showEmptySlots={false}
                        itemPadding={[5, 5]}
                        focusOnSelect={true}
                        breakPoints={breakPoints}
                    >
                        <MicrosoftCard img={Adobe}/>
                        <MicrosoftCard img={foxmula}/>
                        <MicrosoftCard img={internship}/>

                    </Carousel>
                    <button onClick={() => {carousel.current.slidePrev(); textCarousel.current.slidePrev()}} className="arrow-prev">❮</button>
                <button onClick={() => {carousel.current.slideNext(); textCarousel.current.slideNext()}} className="arrow-next">❯</button>
                </div>
            </div>
            <div className="Microsoft-right">
                <div className="testimonial-carousel-container">

                    <Carousel
                        ref={textCarousel}
                        // renderArrow={myArrow}
                        className="testimonial-carousal"
                        showArrows={false}
                        showEmptySlots={false}
                        focusOnSelect={true}
                        breakPoints={breakPoints}
                    >
                        <MicrosoftCardText title={"Adobe Certified Professional in Graphic Design & Illustration Using Adobe Illustrator"} desc={"Digital media gurus bring their unique vision to life every day with shapes, color, and effects through Adobe Illustrator, the industry-standard vector graphics software. Earn an industry-recognized certification for Adobe Illustrator and prove your proficiency."}/>
                        <MicrosoftCardText title={"Foxmula Certificaiton"} desc={"Foxmula certification is curated keeping in mind the need of the learners. Our certification will equip the learners with Adobe Illustrator skills and ensure that they have the necessary practical knowledge to take on any challenges that they may face in the domain. Foxmula certification comes with a plethora of benefits that are designed to advance the leaner’s career."}/>
                        <MicrosoftCardText title={"Project-based Internships"} desc={"We offer our learners project-based internships in collaboration with our partner companies. These internships will provide candidates with first-hand work experience in their domain and a chance to apply the knowledge they are gaining, with great time flexibility. Through our internships, we seek to provide candidates with experience and a practical learning experience."}/>
                    </Carousel>
                   
                </div>
                </div>
               </div>
               
    )
}

export default Microsoft;

const MicrosoftCard = ({img}) => {
    return (
        <div className="MicrosoftCard">
            <img src={img} alt="" />
        </div>
    )
}

const MicrosoftCardText = ({title,desc}) => {
    return (
        <div>
        <div className="Microsoft-right-heading">{title}</div>
                <div className="Microsoft-right-desc">{desc}</div>
            </div>
    )
}



