import React from "react";
import { Link } from "react-router-dom";

function Navigation() {
  return (
    <div className="footerCard">
      <h5 className="footer-heading">Navigation</h5>
      <Link to="/about">
        <p className="footer-link">About</p>
      </Link>
      <Link to="/courses">
        <p className="footer-link">Courses</p>
      </Link>
      <Link to="/business">
        <p className="footer-link">Business</p>
      </Link>
      <Link to="/jobs">
        <p className="footer-link">Jobs</p>
      </Link>
      <a href="http://bootcamp.foxmula.com/">
        <p className="footer-link">Bootcamp</p>
      </a>
      {/* <a href="https://www.back.foxmula.com/events"> */}
      <a href="/events">
        <p className="footer-link">Events</p>
      </a>
    </div>
  );
}
export default Navigation;
