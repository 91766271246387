import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {anotherName} from "./store/actions/changeNameAction"

const ReduxExample = () => {
  const name = useSelector((state) => state.changeName.name);
  const dispatch = useDispatch()
  const changeName = () => {
    dispatch(anotherName());
  };
  return (
    <>
      <h1>{name}</h1>
      <div className="accent1">
        <h1>Accent1</h1>
      </div>
      <div className="accent2">
        <h1>Accent2</h1>
      </div>
      <div className="primaryColor">
        <h1>Primary color</h1>
      </div>
      <button className=""
        onClick={() => {
          changeName();
        }}
      >
        Change Name
      </button>
    </>
  );
};

export default ReduxExample;
