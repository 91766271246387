import React from "react";
import "./SearchBar.scss";

//Icon
import { ReactComponent as SearchLogo } from "../../assets/icons/mdi_magnify.svg";

const SearchBar = ({
  value = "",
  setValue = (e) => e,
  text = "Enter here",
}) => {
  const handleChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  return (
    <div className="searchbar">
      <SearchLogo className="icon" />
      <input
        type="text"
        className="search-input"
        placeholder={`${text}`}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default SearchBar;
