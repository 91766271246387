import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'
import "../Jobs.scss";
import { Container } from '@mui/material';
import React, { useState, useEffect } from "react";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import IconButton from '@material-ui/core/IconButton';


const UploadResume = ({ setActiveStep, setSelectedFile }) => {

    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isSelected, setIsSelected] = useState(false);


    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

    };


    return <>
        <Container>
            <div className="resume-header" gutterBottom >
                Is your resume fit for you Dream Job?
            </div>
            <div className="resume-content" gutterBottom >
                Our resume checker evaluates your resume based on important parameters sought by recruiters and hiring managers. Get tips on how to improve your CV and get more interviews.
            </div>
            <center>

                <input
                    type="file"
                    accept="application/pdf,application/msword,
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    onChange={changeHandler}
                />
                <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span" className="resume-button m-2"  >
                        Upload resume
                    </Button>
                </label>
                <div>
                    <small class="text-muted">as .pdf or .docx file</small>
                </div>
                <br />
                <br />
            </center>
        </Container>

    </>

}

export default UploadResume