import { USER_DETAILS } from "../actions/actionTypes";

const initialState = {
  userDetails: [],
};

const userDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default userDetailReducer;
