/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./LMSSection.scss";

// Icons
import AddIcon from "@mui/icons-material/Add";

// Components
import WeekCard from "./WeekCard/WeekCard";

function LMSSection({ certificateData, setCertificateData }) {
  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    if (certificateData ? (certificateData.lms.weeks ? true : false) : false) {
      setWeeks([...certificateData.lms.weeks]);
    } else {
      setWeeks([]);
    }
    console.log("LMS Section:", weeks);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddWeek = (e) => {
    e.preventDefault();
    let newID = weeks.length;
    setWeeks([...weeks, { week: newID }]);
  };

  return (
    <div className="weekCard-container">
      {weeks.map((week, index) => {
        return (
          <WeekCard key={index} week={week} weeks={weeks} setWeeks={setWeeks} />
        );
      })}
      <a onClick={handleAddWeek} className="btn-add">
        <AddIcon sx={{ fontSize: 70 }} />
      </a>
    </div>
  );
}

export default LMSSection;
