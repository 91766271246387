import Plus from '../../assests/Graphics/Icons/Plus.svg';
import Minus from '../../assests/Graphics/Icons/Minus.svg';
import Button from '../../components/botton/button';

const Table = ({ table, handleClick, open, setOpen }) => {
    return (
        <div className="worktable">
            <div className="worktable-header text-bold" onClick={() => handleClick(table.id)}>
                <span>{table.title}</span>
                {
                    table.expanded ?
                        <img className={table.expanded ? "vector" : "rotated vector"} src={Minus} alt="" /> :
                        <img className={table.expanded ? "vector" : "rotated vector"} src={Plus} alt="" />
                }

            </div>
            <div className={table.expanded ? "worktable-desc" : "hidden worktable-desc"}>
                {
                    table.items.map((item, idx) => <p key={idx} className="worktable-desc-item">{item}</p>)
                }
                {table.title === 'TRAINING' ? <Button type="accentsecondary" text="VIEW CURRICULUM" onClick={() => setOpen(!open)} /> : null}
            </div>
        </div>
    )
}

export default Table;