/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "../../../components/botton/button";

import "./ExamsPage.scss";
import { useCookies } from "react-cookie";
// import { useHistory } from "react-router-dom";
import { ReactComponent as PeopleIcon } from "../../assets/icons/people-icon.svg";
import { Link } from "react-router-dom";

import { getAllPrograms } from "../../helpers/adminapicall";

const ExamsPage = () => {
  const [ProgramsList, setProgramsList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(["refresh", "accessToken"]);

  const updateProgramList = () => {
    //FIXME: After refresh token is fixed, uncomment this and change cookies.accessToken to res.data.newAuthToken
    // axios
    //   .get(`https://foxmula.herokuapp.com/user/refreshToken`, {
    //     withCredentials: true,
    //   })
    //   .then((res) => {
    //     console.log("Refresh Token Received", res.data);
    //     setCookie("accessToken", res.data.newAuthToken, { path: "/" });

    getAllPrograms(cookies.accessToken)
      .then((res) => {
        setProgramsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // });
  };

  useEffect(() => {
    updateProgramList();
  }, []);

  return (
    <>
      <div className="exam-header">
        {/* BreadCrumbs */}
        <span className="text-accent-2 text-bold">Exams</span>
        {/* Add Program Button */}
      </div>

      <div className="exam-main">
        {ProgramsList.map((exam, idx) => {
          return (
            <ExamCard
              key={idx}
              exam={exam}
              updateProgramList={updateProgramList}
            />
          );
        })}
      </div>
    </>
  );
};

const ExamCard = ({ exam }) => {
  return (
    <div className="program-view-card">
      <div className="card-details">{exam.programName}</div>
      <div className="card-view-stats">
        <Button
          type="secondary stats-btn"
          text={exam.enrolled}
          Icon={PeopleIcon}
        />
      </div>
      <div className="card-view">
        <Link to={`manage-exam/${exam.programId}`}>
          <Button type="secondary view-btn" text="View" />
        </Link>
      </div>
    </div>
  );
};

export default ExamsPage;
