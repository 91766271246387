import React from "react";

const ProfileInfo = () => {
  return (
    <div className="profile-info-container">
      <div className="avatar">
        <img
          src="https://imgr.search.brave.com/K0I5DalEKq9KVJeY9zHlaJxlFqUr_v-cKze1jWpepzI/fit/512/512/ce/1/aHR0cDovL2QyMnI1/NGdubXVod21rLmNs/b3VkZnJvbnQubmV0/L3B1YmxpYy9pbWcv/ZGVmYXVsdC11c2Vy/LWF2YXRhci1kYzZm/MmRhOS5naWY.gif"
          alt=""
        />
      </div>
      <div className="avatar-details">
        <span className="avatar-name">Full Name</span>
        <span className="avatar-role">Role</span>
      </div>
    </div>
  );
};
export default ProfileInfo;
