import React from "react";
import Button from "../../../components/botton/button";
import CareersImage from "../../../assests/images/CareersImage.png";
import "./Careers.scss";

const Careers = () => {
  return (
    <div className="Careers color-secondary-1">
      <div className="Careers-seperated"><strong>Careers</strong> you dream of</div>
      <div className="Careers-container">
        <div className="Careers-container-left">
          <div className="Careers-container-left-title">
            Professional training and placement service for your domain
          </div>
          <div className="Careers-container-left-desc">
            There's no end to learning but there are endless beginnings. We bring you an exciting hamper of online technology courses and internships to unravel at the push of a button. Dive deep into your domain, change your field and get a guaranteed job or learn a new skill, the possibilities are endless. Grab the pen and write your story. The time is now.
          </div>
          {/* <Button customStyle={{ margin: '0 auto' }} type="primary" text="VIEW OUR CAREER COURSES" onClick={() => window.location = "/courses"} /> */}
          <Button customStyle={{ margin: '0 auto' }} type="primary" text="VIEW OUR CAREER COURSES" onClick={() => window.location.hash = "/courses"} />
        </div>
        <div className="Careers-container-right">
          <img src={CareersImage} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Careers;
