import React from "react";
import "./button.scss";

const Button = ({
  type,
  text,
  Icon,
  onClick = (e) => e,
  customStyle = null,
  className = ""
}) => {
  return (
    <button
      className={`btn btn-${type} ${className}`}
      onClick={onClick}
      style={customStyle}
      disabled={type === "disabled" && "disabled"}
    >
      {Icon && <Icon className="icon" />}
      {text}
    </button>
  );
};

export default Button;
