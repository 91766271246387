import React from 'react';
import './OurNetwork.scss';
import aws from '../../assests/Graphics/Logos/Company/CompanyLogoLargeColoured/aws.png';
import certiport from '../../assests/Graphics/Logos/Company/CompanyLogoLargeColoured/certiport.png';
import delloite from '../../assests/Graphics/Logos/Company/CompanyLogoLargeColoured/delloite.png';
import ibm from '../../assests/Graphics/Logos/Company/CompanyLogoLargeColoured/ibm.png';
import jp from '../../assests/Graphics/Logos/Company/CompanyLogoLargeColoured/jp.png';
import microsoft from '../../assests/Graphics/Logos/Company/CompanyLogoLargeColoured/microsoft.png';
import nordstrom from '../../assests/Graphics/Logos/Company/CompanyLogoLargeColoured/nordstrom.png';
import pwc from '../../assests/Graphics/Logos/Company/CompanyLogoLargeColoured/pwc.png';
import sap from '../../assests/Graphics/Logos/Company/CompanyLogoLargeColoured/sap.png';


const OurNetwork = () =>{
    return(
        <div className="ournetwork">
            <div className="ournetwork-header">
                <div className="ournetwork-header-title text-bold">Our Hiring & Partner Network</div>
                <div className="ournetwork-header-desc">See where our 13000+ alumini work at and the companies we partnered with to get you the best.</div>
            </div>
            <div className="ournetwork-imgs">

                <div className="ournetwork-imgs-img"><img src={nordstrom} alt="Company Logo" /></div>&nbsp;
                <div className="ournetwork-imgs-img"><img src={jp} alt="Company Logo" /></div>&nbsp;
                <div className="ournetwork-imgs-img"><img src={pwc} alt="Company Logo" /></div>&nbsp;
                <div className="ournetwork-imgs-img"><img src={delloite} alt="Company Logo" /></div>
                <div className="ournetwork-imgs-img"><img src={microsoft} alt="Company Logo" /></div>
                <div className="ournetwork-imgs-img"><img src={aws} alt="Company Logo" /></div>
                <div className="ournetwork-imgs-img"><img src={ibm} alt="Company Logo" /></div>
                <div className="ournetwork-imgs-img"><img src={certiport} alt="Company Logo" /></div>
                <div className="ournetwork-imgs-img"><img src={sap} alt="Company Logo" /></div>
            </div>
        </div>
    )
}

export default OurNetwork;