import React, { useRef, useState } from 'react';
// import { NavLink, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { NavLink, Route, HashRouter as Router, Switch } from "react-router-dom";
import { BlogCard, BlogCard1, BlogCard2 } from '../Jobs/BlogCard/BlogCard';
import './AllCourses.scss';

import CourseCard from '../../components/CourseCard/CourseCard';
import { useHistory } from 'react-router-dom';
import { ReactComponent as DownArrow } from '../../assests/Graphics/Icons/DownArrow.svg';
import { ReactComponent as Search } from '../../assests/Graphics/Icons/Search.svg';
import Header from "../../components/layout/Header/header";
import Footer from "../../components/layout/Footer/Footer";
import microsoft from "../../assests/images/ml.PNG"
import cloud from "../../assests/images/cloud.PNG"
import data from "../../assests/images/IMG_2238.PNG"
import cyber from "../../assests/images/cyber.PNG"
import photoshop from "../../assests/images/photoshop.PNG"
import illustrator from "../../assests/images/illustrator.PNG"
import dream_waiver from "../../assests/images/dream_waiver.PNG"
import animate from "../../assests/images/animate.PNG"
import premier from "../../assests/images/premier.PNG"
import indesign from "../../assests/images/indesign.PNG"
import after from "../../assests/images/aafter_effect.PNG"
import autocad from "../../assests/images/autocaddesk.PNG"
import inventor from "../../assests/images/inventor.PNG"
import fusion from "../../assests/images/fusion.PNG"
import revit from "../../assests/images/autorevit.PNG"
import maya from "../../assests/images/maya.jpeg"
import artist from "../../assests/images/userartist.PNG"
import programmer from "../../assests/images/unity_prog.PNG"
import vr from "../../assests/images/unity_vr.PNG"
import network from "../../assests/images/network+.PNG"
import security from "../../assests/images/security+.PNG"
import project from "../../assests/images/project+.PNG"
import office from "../../assests/images/IMG_2240.PNG"
import dsmax from "../../assests/images/3dsmax.PNG"
import Essentials from '../homepage/Essentials/Essentials';
import Careers from '../homepage/Careers/Careers';
import Intensive from '../homepage/Intensive/Intensive';
import Carousel from "react-elastic-carousel";

const AllCourses = () => {
    const history = useHistory()
    const [levels, setlevels] = useState([
        {
            id: 1,
            name: "All Levels",
            link: "",
            active: true
        },
        {
            id: 2,
            name: "Learner",
            link: "essentials",
            active: false
        },
        {
            id: 3,
            name: "Professional",
            link: "professional",
            active: false
        },
        // {
        //     id: 4,
        //     name: "Job Guarentee",
        //     link: "careers",
        //     active: false
        // },
    ])
    const handleClick = (lid) => {
        let newlevels = [...levels];
        for (var i = 0; i < 4; i++) {
            if (i === lid - 1) {
                newlevels[i].active = true;
            }
            else {
                newlevels[i].active = false;
            }
        }
        setlevels(newlevels);
        window.location = `/courses/${newlevels[lid - 1].link}`
        // window.location.hash = `/courses/${newlevels[lid - 1].link}`
    }

    return (
        <>
            <Header />
            <div className="allcourses">
                <div className="allcourses-search">
                    <SearchBar placehold="Search courses" />
                </div>

                <div className="allcourses-container">
                    <Router>
                        <div className="allcourses-container-left">
                            <SideBar levels={levels} handleClick={handleClick} />
                        </div>
                        <div className="allcourses-container-right">
                            <Switch>
                                <Route exact path="/courses/All-courses" component={All} />
                                <Route exact path="/courses/" component={Popular} />
                                <Route exact path="/courses/essentials" component={Learners} />
                                <Route exact path="/courses/professional" component={Professional} />
                                <Route exact path="/courses/careers" component={Guarenteed} />
                            </Switch>
                        </div>
                    </Router>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default AllCourses;

const SearchBar = ({ placehold }) => {
    return (
        <div className="SearchBar">
            <Search className="SearchBar-icon" />
            <input type="text" placeholder={`${placehold}`} />
        </div>
    )
}

const SideBar = ({ levels, handleClick }) => {
    const [course, setCourse] = useState(false);
    const [Level, setLevel] = useState(false);

    const handleCourse = () => {
        setCourse(!course);
    }
    const handleLevel = () => {
        setLevel(!Level);
    }
    return (
        <div className="SideBar">
            <div className="SideBar-large">
                <div className="SideBar-top">
                    <div className="SideBar-top-title text-bold">AVAILABLE FIELDS</div>
                    {/* <Router> */}
                    <div className="SideBar-top-links">
                        <NavLink exact activeClassName="SideBar-selected" to="/courses/">Popular Courses</NavLink>
                        <NavLink exact activeClassName="SideBar-selected" to="/courses/All-courses">All Courses</NavLink>
                    </div>
                    {/* </Router> */}
                </div>
                <div className="SideBar-large">
                    <div className="SideBar-top-title text-bold">LEVEL</div>
                    <div className="SideBar-top-links">
                        {/* {
                            levels.map(level => <div key={level.key} onClick={() => handleClick(level.id)} className={level.active ? "active SideBar-level-links-link" : "SideBar-level-links-link"}>{level.name}</div>)
                        } */}
                        <NavLink exact activeClassName="SideBar-selected" to="/courses/">All Levels</NavLink>
                        <NavLink exact activeClassName="SideBar-selected" to="/courses/essentials">Learner</NavLink>
                        <NavLink exact activeClassName="SideBar-selected" to="/courses/professional">Professional</NavLink>
                        {/* <NavLink exact activeClassName="SideBar-selected" to="/courses/career">Job Guarentee</NavLink> */}
                    </div>
                </div>
            </div>

            <div className="SideBar-small">
                <div className="SideBar-left">
                    <div className="SideBar-header">
                        <span>Popular Courses</span>
                        <DownArrow onClick={handleCourse} />
                    </div>
                    {
                        course ?
                            <div className="SideBar-top-links">
                                <NavLink onClick={handleCourse} exact activeClassName="SideBar-selected" to="/courses/">Popular Courses</NavLink>
                                <NavLink onClick={handleCourse} exact activeClassName="SideBar-selected" to="/courses/All-courses">All Courses</NavLink>
                            </div> : ""
                    }
                </div>
                <div className="SideBar-right">
                    <div className="SideBar-header">
                        <span>Levels</span>
                        <DownArrow onClick={handleLevel} />
                    </div>
                    {
                        Level ?
                            <div className="SideBar-level-links">
                                {
                                    levels.map(level => <div key={level.key} onClick={() => handleClick(level.id)} className={level.active ? "active SideBar-level-links-link" : "SideBar-level-links-link"}>{level.name}</div>)
                                }
                            </div> : ""
                    }
                </div>
            </div>
        </div>
    )
}



const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
];


const Popular = () => {
    const slider = useRef(null);
    const carousel = useRef(null);

    return (
        <div className="popular">
            <div className="popularcourses color-secondary-1">
                <div className="popularcourses-header">
                    <div className="popularcourses-header-title text-bold">POPULAR COURSES</div>
                    <div className="popularcourses-header-line"><hr /></div>
                    <div className="popularcourses-header-buttons">
                        <button onClick={() => { carousel.current?.slidePrev(); }} className="btn-prev">❮</button>
                        <button onClick={() => { carousel.current?.slideNext(); }} className="btn-next">❯</button>
                    </div>
                </div>
                <div className="popularcourses-cards" ref={slider}>
                    <Carousel
                        itemsToShow={4}
                        itemsToScroll={4}
                        ref={carousel}
                        itemPadding={[5, 5]}
                        focusOnSelect={true}
                        showArrows={false}
                        breakPoints={breakPoints}
                    >
                        <CourseCard trending={true} href={"/machine-learning-using-python"} img={microsoft} name="Machine learning using python" />
                        <CourseCard trending={false} href={"/cloud-computing"} img={cloud} name="Cloud Computing" />
                        <CourseCard trending={true} href={"/datascience"} img={data} name="Data Science" />
                        <CourseCard trending={true} href={"/cyber-security"} img={cyber} name="Cyber Security" />
                        <CourseCard trending={true} href="/web-development" img={microsoft} name={"Web Development"} />
                        <CourseCard trending={true} href="/ui-development" img={microsoft} name={"UI Development"} />
                        <CourseCard trending={true} href="/python" img={microsoft} name={"Python Fundamentals"} />
                        <CourseCard trending={true} href="/application-development" img={microsoft} name={"Application Development Fundamentals"} />
                        <CourseCard trending={true} href="/web-development-using-react" img={microsoft} name={"Web Development using React Js"} />
                        <CourseCard trending={true} href="/network-fundamentals" img={microsoft} name={"Network Fundamentals"} />
                        <CourseCard trending={true} href="/java" img={microsoft} name={"Java Fundamentals"} />
                        <CourseCard trending={true} href="/data-structure" img={microsoft} name={"Data Structure & Algorithm"} />
                        <CourseCard trending={true} href="/deep-learning" img={microsoft} name={"Deep Learning using Tensor Flow"} />
                        <CourseCard trending={true} href={"/adobe/photoshop"} img={photoshop} name="Adobe Photoshop" />
                        <CourseCard trending={true} href={"/adobe/illustrator"} img={illustrator} name="Adobe Illustrator" />
                        <CourseCard trending={true} href={"/adobe/animate"} img={animate} name="Adobe Animate" />
                        <CourseCard trending={true} href={"/adobe/dreamwaiver"} img={dream_waiver} name="Adobe Dream Waiver" />
                        <CourseCard trending={true} href={"/adobe/premier"} img={premier} name="Adobe Premier Pro" />
                        <CourseCard trending={true} href={"/adobe/indesign"} img={indesign} name="Adobe InDesign" />
                        <CourseCard trending={true} href={"/adobe/after"} img={after} name="Adobe After Effects" />
                        <CourseCard trending={true} href={"/autocad"} img={autocad} name="Autodesk AutoCAD" />
                        <CourseCard trending={true} href={"/autodeskinventor"} img={inventor} name="Autodesk Inventor" />
                        <CourseCard trending={true} href={"/autodeskfusion"} img={fusion} name="AutoDesk Fusion 360 " />
                        <CourseCard trending={true} href={"/autodeskrevit"} img={revit} name="Autodesk Revit" />
                        <CourseCard trending={true} href={"/autodeskmaya"} img={maya} name="Autodesk Maya" />
                        <CourseCard trending={true} href={"/autodesk3ds"} img={dsmax} name="Autodesk 3DS Max" />
                        <CourseCard trending={true} href={"/unity/programmer"} img={programmer} name="Unity Certified Programmer" />
                        <CourseCard trending={true} href={"/unity/vr"} img={vr} name="Unity Certified VR Developer" />
                        <CourseCard trending={true} href={"/unity/artist"} img={artist} name="Unity Certified Artist" />
                        <CourseCard trending={true} href={"/comptianetwork"} img={network} name="CompTIA Network+" />
                        <CourseCard trending={true} href={"/comptiasecurity"} img={security} name="CompTIA Security+" />
                        <CourseCard trending={true} href={"/comptiaproject"} img={project} name="CompTIA Project+" />
                        <CourseCard trending={true} href={"/microsoftoffice"} img={office} name="Microsoft Office" />
                    </Carousel>
                </div>
            </div>
            <div className="popularcards">
                <BlogCard />
                <BlogCard1 />
                <BlogCard2 />
            </div>

        </div>
    )
}









const All = () => {
    return (
        <div className="All">
            <div className="All-cards">
                <CourseCard trending={true} href={"/machine-learning-using-python"} img={microsoft} name="Machine learning using python" />
                <CourseCard trending={false} href={"/cloud-computing"} img={cloud} name="Cloud Computing" />
                <CourseCard trending={true} href={"/datascience"} img={data} name="Data Science" />
                <CourseCard trending={true} href={"/cyber-security"} img={cyber} name="Cyber Security" />
                <CourseCard trending={true} href="/web-development" img={microsoft} name={"Web Development"} />
                <CourseCard trending={true} href="/ui-development" img={microsoft} name={"UI Development"} />
                <CourseCard trending={true} href="/python" img={microsoft} name={"Python Fundamentals"} />
                <CourseCard trending={true} href="/application-development" img={microsoft} name={"Application Development Fundamentals"} />
                <CourseCard trending={true} href="/web-development-using-react" img={microsoft} name={"Web Development using React Js"} />
                <CourseCard trending={true} href="/network-fundamentals" img={microsoft} name={"Network Fundamentals"} />
                <CourseCard trending={true} href="/java" img={microsoft} name={"Java Fundamentals"} />
                <CourseCard trending={true} href="/data-structure" img={microsoft} name={"Data Structure & Algorithm"} />
                <CourseCard trending={true} href="/deep-learning" img={microsoft} name={"Deep Learning using Tensor Flow"} />
                <CourseCard trending={true} href={"/adobe/photoshop"} img={photoshop} name="Adobe Photoshop" />
                <CourseCard trending={true} href={"/adobe/illustrator"} img={illustrator} name="Adobe Illustrator" />
                <CourseCard trending={true} href={"/adobe/animate"} img={animate} name="Adobe Animate" />
                <CourseCard trending={true} href={"/adobe/dreamwaiver"} img={dream_waiver} name="Adobe Dream Waiver" />
                <CourseCard trending={true} href={"/adobe/premier"} img={premier} name="Adobe Premier Pro" />
                <CourseCard trending={true} href={"/adobe/indesign"} img={indesign} name="Adobe InDesign" />
                <CourseCard trending={true} href={"/adobe/after"} img={after} name="Adobe After Effects" />
                <CourseCard trending={true} href={"/autocad"} img={autocad} name="Autodesk AutoCAD" />
                <CourseCard trending={true} href={"/autodeskinventor"} img={inventor} name="Autodesk Inventor" />
                <CourseCard trending={true} href={"/autodeskfusion"} img={fusion} name="AutoDesk Fusion 360 " />
                <CourseCard trending={true} href={"/autodeskrevit"} img={revit} name="Autodesk Revit" />
                <CourseCard trending={true} href={"/autodeskmaya"} img={maya} name="Autodesk Maya" />
                <CourseCard trending={true} href={"/autodesk3ds"} img={dsmax} name="Autodesk 3DS Max" />
                <CourseCard trending={true} href={"/unity/programmer"} img={programmer} name="Unity Certified Programmer" />
                <CourseCard trending={true} href={"/unity/vr"} img={vr} name="Unity Certified VR Developer" />
                <CourseCard trending={true} href={"/unity/artist"} img={artist} name="Unity Certified Artist" />
                <CourseCard trending={true} href={"/comptianetwork"} img={network} name="CompTIA Network+" />
                <CourseCard trending={true} href={"/comptiasecurity"} img={security} name="CompTIA Security+" />
                <CourseCard trending={true} href={"/comptiaproject"} img={project} name="CompTIA Project+" />
                <CourseCard trending={true} href={"/microsoftoffice"} img={office} name="Microsoft Office" />
            </div>
        </div>
    )
}
const Learners = () => {
    return (
        <div className="All">
            <div className="All-cards">
                <CourseCard trending={true} href={"/machine-learning-using-python"} img={microsoft} name="Machine learning using python" />
                <CourseCard trending={false} href={"/cloud-computing"} img={cloud} name="Cloud Computing" />
                <CourseCard trending={true} href={"/datascience"} img={data} name="Data Science" />
                <CourseCard trending={true} href={"/cyber-security"} img={cyber} name="Cyber Security" />
                <CourseCard trending={true} href="/web-development" img={microsoft} name={"Web Development"} />
                <CourseCard trending={true} href="/ui-development" img={microsoft} name={"UI Development"} />
                <CourseCard trending={true} href="/python" img={microsoft} name={"Python Fundamentals"} />
                <CourseCard trending={true} href="/application-development" img={microsoft} name={"Application Development Fundamentals"} />
                <CourseCard trending={true} href="/web-development-using-react" img={microsoft} name={"Web Development using React Js"} />
                <CourseCard trending={true} href="/network-fundamentals" img={microsoft} name={"Network Fundamentals"} />
                <CourseCard trending={true} href="/java" img={microsoft} name={"Java Fundamentals"} />
                <CourseCard trending={true} href="/data-structure" img={microsoft} name={"Data Structure & Algorithm"} />
                <CourseCard trending={true} href="/deep-learning" img={microsoft} name={"Deep Learning using Tensor Flow"} />
                <CourseCard trending={true} href={"/adobe/photoshop"} img={photoshop} name="Adobe Photoshop" />
                <CourseCard trending={true} href={"/adobe/illustrator"} img={illustrator} name="Adobe Illustrator" />
                <CourseCard trending={true} href={"/adobe/animate"} img={animate} name="Adobe Animate" />
                <CourseCard trending={true} href={"/adobe/dreamwaiver"} img={dream_waiver} name="Adobe Dream Waiver" />
                <CourseCard trending={true} href={"/adobe/premier"} img={premier} name="Adobe Premier Pro" />
                <CourseCard trending={true} href={"/adobe/indesign"} img={indesign} name="Adobe InDesign" />
                <CourseCard trending={true} href={"/adobe/after"} img={after} name="Adobe After Effects" />
                <CourseCard trending={true} href={"/autocad"} img={autocad} name="Autodesk AutoCAD" />
                <CourseCard trending={true} href={"/autodeskinventor"} img={inventor} name="Autodesk Inventor" />
                <CourseCard trending={true} href={"/autodeskfusion"} img={fusion} name="AutoDesk Fusion 360 " />
                <CourseCard trending={true} href={"/autodeskrevit"} img={revit} name="Autodesk Revit" />
                <CourseCard trending={true} href={"/autodeskmaya"} img={maya} name="Autodesk Maya" />
                <CourseCard trending={true} href={"/autodesk3ds"} img={dsmax} name="Autodesk 3DS Max" />
                <CourseCard trending={true} href={"/unity/programmer"} img={programmer} name="Unity Certified Programmer" />
                <CourseCard trending={true} href={"/unity/vr"} img={vr} name="Unity Certified VR Developer" />
                <CourseCard trending={true} href={"/unity/artist"} img={artist} name="Unity Certified Artist" />
                <CourseCard trending={true} href={"/comptianetwork"} img={network} name="CompTIA Network+" />
                <CourseCard trending={true} href={"/comptiasecurity"} img={security} name="CompTIA Security+" />
                <CourseCard trending={true} href={"/comptiaproject"} img={project} name="CompTIA Project+" />
                <CourseCard trending={true} href={"/microsoftoffice"} img={office} name="Microsoft Office" />
            </div>
        </div>
    )
}
const Professional = () => {
    return (
        <div className="All">
            <div className="All-cards">
                <CourseCard trending={true} href={"/adobe/photoshop"} img={photoshop} name="Adobe Photoshop" />
                <CourseCard trending={true} href={"/adobe/illustrator"} img={illustrator} name="Adobe Illustrator" />
                <CourseCard trending={true} href={"/adobe/animate"} img={animate} name="Adobe Animate" />
                <CourseCard trending={true} href={"/adobe/dreamwaiver"} img={dream_waiver} name="Adobe Dream Waiver" />
                <CourseCard trending={true} href={"/adobe/premier"} img={premier} name="Adobe Premier Pro" />
                <CourseCard trending={true} href={"/adobe/indesign"} img={indesign} name="Adobe InDesign" />
                <CourseCard trending={true} href={"/adobe/after"} img={after} name="Adobe After Effects" />
                <CourseCard trending={true} href={"/autocad"} img={autocad} name="Autodesk AutoCAD" />
                <CourseCard trending={true} href={"/autodeskinventor"} img={inventor} name="Autodesk Inventor" />
                <CourseCard trending={true} href={"/autodeskfusion"} img={fusion} name="AutoDesk Fusion 360 " />
                <CourseCard trending={true} href={"/autodeskrevit"} img={revit} name="Autodesk Revit" />
                <CourseCard trending={true} href={"/autodeskmaya"} img={maya} name="Autodesk Maya" />
                <CourseCard trending={true} href={"/autodesk3ds"} img={dsmax} name="Autodesk 3DS Max" />
                <CourseCard trending={true} href={"/unity/programmer"} img={programmer} name="Unity Certified Programmer" />
                <CourseCard trending={true} href={"/unity/vr"} img={vr} name="Unity Certified VR Developer" />
                <CourseCard trending={true} href={"/unity/artist"} img={artist} name="Unity Certified Artist" />
                <CourseCard trending={true} href={"/comptianetwork"} img={network} name="CompTIA Network+" />
                <CourseCard trending={true} href={"/comptiasecurity"} img={security} name="CompTIA Security+" />
                <CourseCard trending={true} href={"/comptiaproject"} img={project} name="CompTIA Project+" />
            </div>
        </div>
    )
}

const Guarenteed = () => {
    return (
        <div className="All">
            <div className="All-cards"> </div>
        </div>
    )
}


