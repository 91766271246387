import React from "react";
import ProfileInfo from "../ProfileInfo/ProfileInfo";
import SearchBar from "../SearchBar/SearchBar";

const NavBar = () => {
  return (
    <nav className="admin-navbar">
      <SearchBar />
      <ProfileInfo />
    </nav>
  );
};

export default NavBar;
