import React, { useEffect, useState } from "react";
import "./ManageExam.scss";

// Components
import ListSelect from "../../../../components/ListSelect/ListSelect";
import StudentSection from "./StudentSection/StudentSection";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

// APIs
import { getBookingInfo, getProgramById } from "../../../helpers/adminapicall";

//Section Components
import ResultSection from "./ResultSection/ResultSection";
import OverviewSection from "./OverviewSection/OverviewSection";

import CertificateSection from "./CertificateSection/CertificateSection";

const ManageExam = (props) => {
  const [currentWindow, setCurrentWindow] = useState("overview");
  const [operatingMonth, setOperatingMonth] = useState("");
  const [operatingYear, setOperatingYear] = useState("");
  const [bookings, setBookings] = useState([]);
  const { examId } = useParams();
  const [examName, setExamName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(["refresh", "accessToken"]);
  if (examId) {
    getProgramById(examId, cookies.accessToken).then((res) => {
      setExamName(res.data.certificate.certificateName);
    });
  }
  // set Month in numeric
  const getMonth = () => {
    switch (operatingMonth) {
      case "January":
        return "01";
      case "February":
        return "02";
      case "March":
        return "03";
      case "April":
        return "04";
      case "May":
        return "05";
      case "June":
        return "06";
      case "July":
        return "07";
      case "August":
        return "08";
      case "September":
        return "09";
      case "October":
        return "10";
      case "November":
        return "11";
      case "December":
        return "12";
      default:
        return "";
    }
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (examId && operatingMonth && operatingYear) {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatingMonth, operatingYear]);

  // FIXME: Reduce the no of calls to the server
  useEffect(() => {
    if (examId && operatingMonth && operatingYear) {
      getBookingInfo(
        examId,
        getMonth(),
        operatingYear,
        cookies.accessToken
      ).then((res) => {
        setBookings(res.data.bookings);
        console.log(res.data.bookings);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examId, operatingMonth, operatingYear]);

  // months array
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // years array
  const years = ["2020", "2021"];

  return (
    <>
      <div className="CertificatePageHeader">
        {/* BreadCrumbs */}
        <span>
          EXAMS{" "}
          <span className="active-breadcrumbs">/ {examName.toUpperCase()}</span>
        </span>
        <span>
          <ListSelect
            label="Operating month"
            value={operatingMonth}
            setValue={setOperatingMonth}
            options={months}
          />
          <ListSelect
            label="Operating year"
            value={operatingYear}
            setValue={setOperatingYear}
            options={years}
          />
        </span>
      </div>

      {!loading ? (
        <>
          <ManageExamHeader
            currentWindow={currentWindow}
            setCurrentWindow={setCurrentWindow}
          />
          {currentWindow === "overview" && (
            <OverviewSection
              bookings={bookings}
              setBookings={setBookings}
              month={getMonth()}
              year={years}
            />
          )}
          {currentWindow === "students" && (
            <StudentSection bookings={bookings} setBookings={setBookings} />
          )}
          {currentWindow === "result section" && (
            <ResultSection bookings={bookings} setBookings={setBookings} />
          )}
          {currentWindow === "certificate detail" && (
            <CertificateSection bookings={bookings} setBookings={setBookings} />
          )}
        </>
      ) : (
        <div className="no-data-container">
          Please select operating month and year
        </div>
      )}
    </>
  );
};

const ManageExamHeader = ({ currentWindow, setCurrentWindow }) => {
  let headers = [
    "overview",
    "students",
    "result section",
    "certificate detail",
  ];
  return (
    <div className="component-header">
      {headers.map((header) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className={`component-header-item component-header-item${
            currentWindow === header ? "-selected" : ""
          }`}
          onClick={() => setCurrentWindow(header)}
        >
          {header.toUpperCase()}
        </a>
      ))}
    </div>
  );
};

export default ManageExam;
