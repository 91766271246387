import React, { useEffect } from 'react'
import {useHistory, useParams} from 'react-router-dom'
import axios from 'axios'

function Verify() {
const params = useParams()
const history = useHistory()
useEffect(()=>{
    axios
    // .get('https://www.back.foxmula.com/login/verify',{headers:{authorization:params?.id}})
    .get('/login/verify',{headers:{authorization:params?.id}})
    .then((res)=>{
        console.log("verified")
        window.localStorage.setItem('token',params.id)
        window.localStorage.setItem('uid',res?.data?.user?.id)
        console.log(params.id)
        console.log(res?.data)
        history.push('/')
    })
    .catch((err)=>{
        
        alert(`Unauthorized! Please login again ${err.response.data.message}`)
        // window.location = 'https://www.back.foxmula.com/login'
        window.location = '/login'
        // window.location.hash = '/login'
    })
})

  return (
    <div>Verifying...</div>
  )
}

export default Verify