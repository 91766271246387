
import './LandingPage.scss';
import student from '../../../assests/images/Student.png'
import { useHistory } from 'react-router-dom';
import { useEffect, React, useState } from "react";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button1 from '@mui/material/Button';
import Button from "../../../components/botton/button"
import data from '../data'
import CancelSharpIcon from '@mui/icons-material/CancelSharp';

const LandingPage = () => {
  const history = useHistory()
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [contact, setContact] = useState("");
  const [programs, setPrograms] = useState(null)
  const [courseCopy, setCourseCopy] = useState(null)
  const [course, setCourses] = useState(data)
  const [selectedCourse, setSelectedCourse] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ email, firstName, lastName, contact, organization, tags: ['Website'] })
    const tags = ['Website']
    axios
      // .post("https://www.back.foxmula.com/zendesk/sell", {
      .post("/zendesk/sell", {
        email, firstName, lastName, contact, organization, courseName: 'Web Development', tags
      })
      .then((res) => {
        console.log(res)
        alert("Request Sent! You'll hear from us soon.")
        setOpen(false)
        setEmail("")
        setContact("")
        setFirstName("")
        setLastName("")
        setOrganization("")
      })
      .catch((err) => {
        console.log(err.response.data.message)
        alert(err.response.data.message)
        setOpen(false)
        setEmail("")
        setContact("")
        setFirstName("")
        setLastName("")
        setOrganization("")
      })
  };

  const handleChange = (event) => {
    console.log(event.target.value)
    console.log(course)
    setPrograms(event.target.value);
    const newCourse = course.filter((i) => i.value.includes(event.target.value))
    setCourseCopy(newCourse)
  };

  const handleCourse = (event) => {
    setSelectedCourse(event.target.value)
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="landing">
      <div className="landing-background">
        <div className="landing-background-rectangle">
        </div>
        <div className="landing-background-curved">
          <svg viewBox="0 0 1366 208" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0C435 0 1366 0 1366 0V207.5C1366 207.5 1144.5 27.5 683 27.5C221.5 27.5 0 207.5 0 207.5V0Z" fill="#ECF4FF" /></svg>
        </div>
      </div>
      <div className="landing-content">
        <div className="landing-content-left">
          <div className="landing-content-left-title text-bold">Get your skills, job ready</div>
          <div className="landing-content-left-desc">It <strong>sucks</strong>  being hidden in the millions. We see you’re different. We will put you on the frontline.</div>
          <div className="landing-content-left-buttons">
            <Button type="secondary" onClick={() => { history.push('/courses') }} text="VIEW OUR COURSES" />
            <Button type="primary" onClick={() => setOpen(!open)} text="Get Councelling" />
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <div className="modal-header">
                <Fade in={open}>

                  <Box className="card" sx={style}>
                    <Typography
                      className="card-header"
                      id="transition-modal-title"
                      variant="h4"
                      component="h2"
                      sx={{ textAlign: "center", margin: "0 auto", marginBottom: "10px" }}

                    >
                      COUNSELLING
                      <CancelSharpIcon sx={{position:'absolute', right:0, marginRight:'20px', cursor:'pointer'}} onClick={handleClose} />
                    </Typography>

                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      sx={{ margin: "0 auto", marginTop: "10px", my: "10px" }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <TextField
                      id="standard-adornment-password"
                      label="First Name"
                      variant="outlined"
                      sx={{ margin: "0 auto", my: "10px" }}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                    <TextField
                      id="standard-adornment-password"
                      label="Last Name"
                      variant="outlined"
                      sx={{ margin: "0 auto", my: "10px" }}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                    <TextField
                      id="standard-adornment-password"
                      label="Contact"
                      variant="outlined"
                      sx={{ margin: "0 auto", my: "10px" }}
                      onChange={(e) => {
                        setContact(e.target.value);
                      }}
                    />
                    <TextField
                      id="standard-adornment-password"
                      label="College Name"
                      variant="outlined"
                      sx={{ margin: "0 auto", my: "10px", marginBottom: "20px" }}
                      onChange={(e) => {
                        setOrganization(e.target.value);
                      }}
                    />
                    <FormControl sx={{ my: "10px", minWidth: 210, margin: "0 auto", marginBottom: "30px" }}>
                      <InputLabel id="demo-simple-select-autowidth-label">Doamin of Interest</InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={programs}
                        onChange={handleChange}
                        autoWidth
                        label="Programs"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>Learners</MenuItem>
                        <MenuItem value={2}>Programmer</MenuItem>
                        <MenuItem value={3}>Career</MenuItem>
                      </Select>
                    </FormControl>
                    {programs && (
                      <FormControl sx={{ margin: "0 auto", minWidth: 210, my: "20px", marginBottom: "20px" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Select Course</InputLabel>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          value={selectedCourse}
                          onChange={handleCourse}
                          autoWidth
                          label="Programs"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {courseCopy?.map((details, index) => {
                            return (
                              <MenuItem value={details.name}>{details.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>)}
                    <Button1
                      onClick={(e) => handleSubmit(e)}
                      sx={{
                        width: "50%",
                        marginLeft: "25%",
                        marginRight: "25%",
                        marginTop: "10%"
                      }} variant="contained">Get COUNSELLING</Button1>
                  </Box>
                </Fade>
              </div>
            </Modal>
          </div>
        </div>
        <div className="landing-content-right">
          <img src={student} alt="" />
        </div>
      </div>

    </div>

  )
};

export default LandingPage;
