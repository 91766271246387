import logo from '../../../assests/images/logo.png';
import '../AllJobs/AllJobs.scss'
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from "@mui/material/Grid";
import { Link } from 'react-router-dom'

const JobCard = ({ job, handleActive, active }) => {

    const matches = useMediaQuery('(min-width:600px)');
    const matchesTable = useMediaQuery('(min-width:900px)');

    function parseDate(str) {
        var mdy = str.split('/');
        return new Date(mdy[2], mdy[0] - 1, mdy[1]);
    }

    function datediff(first, second) {
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }


    return (
        <Grid container direction={`${matches ? "row" : "column"}`} onClick={() => handleActive(job._id)}>
            <Grid container item className={`jobcard ${active._id === job._id ? 'color-secondary-1' : ''}`}>
                <div className="jobcard-left">
                    <img src={logo} alt={`${job.company} logo`} />
                    <span className="text-bold " >{job.company}</span>
                </div>
                <div className={` ${matchesTable ? "jobcard-middle" : "mx-3 my-1 "} ${matches && !matchesTable ? "jobcard-w-60" : ""}  ${matches ? "jobcard-middle" : "mx-3 my-1 jobcard-w-60"}`}>
                    <Link to={`/singlejob/${job._id}`} className="jobcard-middle-name text-bold pointer" >{job.title}</Link>
                    <div className="jobcard-middle-location">{job.location}, India </div>
                    <br />
                    <ul className="jobcard-middle-list">
                        <li> {job.duration} months</li>
                        <li>{job.payRange} </li>
                    </ul>
                </div>
                {
                    matchesTable
                        ? <Grid item direction={`${!matchesTable ? "row" : "column"}`} className="jobcard-right ">
                            <div className="jobcard-right-top">
                                <div className="jobcard-right-top-text text-bold">10/10</div>
                                <div className="jobcard-right-top-skills">Skills matched</div>
                            </div>
                            <div className="jobcard-right-top">
                                <div className="jobcard-right-top-text"> {datediff(job.createdAt, new Date())} day ago</div>
                            </div>
                        </Grid>
                        : null
                }
            </Grid>
            {
                !matchesTable
                    ? <Grid container item direction={`${!matchesTable ? "row" : "column"}`} className={`jobcard-right ${active.id === job.id ? 'color-secondary-1' : ''}`}>
                        <div className="jobcard-right-top">
                            <div className="jobcard-right-top-text text-bold">10/10</div>
                            <div className="jobcard-right-top-skills">Skills matched</div>
                        </div>
                        <div className="jobcard-right-top">
                            <div className="jobcard-right-top-text"> {datediff(job.createdAt, new Date())} day ago</div>
                        </div>
                    </Grid>
                    : null
            }
            <hr className="job-divider my-2 " />
        </Grid >
    )
}
export default JobCard;