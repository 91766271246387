import React, { useState, useEffect } from "react";
import "./IssuesPage.scss";

import IssueCard from "./IssueCard/IssueCard";
import { getAllIssues } from "../../helpers/adminapicall";
import { useParams } from "react-router-dom";
import ListSelect from "../../../components/ListSelect/ListSelect";

const IssuesPage = () => {
  const [issues, setIssues] = useState([]);
  const { certificateID } = useParams();
  console.log(certificateID);

  useEffect(() => {
    if (certificateID) {
      getAllIssues(certificateID).then((res) => {
        setIssues([...res]);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filteredIssues, setFilteredIssues] = useState([]);
  const [filterElements, setFilterElements] = useState();
  const filterElementsOptions = [
    "ALL",
    "VIDEO",
    "READING",
    "QUIZZES",
    "PRACTICE",
  ];
  const [filterTypes, setFilterTypes] = useState();
  const filterTypesOptions = [
    "ALL",
    "AUDIO",
    "ACCENT",
    "EXPLANATION",
    "OTHER",
    "WRONG_INFORMATION",
    "QUESTION",
  ];
  const [filterStatus, setFilterStatus] = useState();
  const filterStatusOptions = ["ALL", "PENDING", "RESOLVED", "CLOSED"];

  const handleFilterElements = () => {
    // seach issues with the selected filter element
    if (issues.length > 0) {
      setFilteredIssues([...issues]);
      if (filterElements) {
        if (filterElements === "ALL") {
          setFilteredIssues([...filteredIssues]);
        } else {
          setFilteredIssues(
            issues.filter((issue) => issue.element === filterElements)
          );
        }
      }
      if (filterTypes) {
        if (filterTypes === "ALL") {
          setFilteredIssues([...filteredIssues]);
        } else {
          setFilteredIssues(
            filteredIssues.filter((issue) => issue.type === filterTypes)
          );
        }
      }
      if (filterStatus) {
        console.log("In here");
        if (filterStatus === "ALL") {
          console.log("In All");
          setFilteredIssues([...filteredIssues]);
        } else {
          setFilteredIssues(
            filteredIssues.filter((issue) => issue.status === filterStatus)
          );
        }
      }
      console.log("Filtered Issues: ", filteredIssues);
    }
  };

  useEffect(() => {
    handleFilterElements();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterElements, filterTypes, filterStatus]);

  return (
    <>
      {/* Dashboard main header */}
      <div className="CertificatePageHeader">
        {/* BreadCrumbs */}
        <span>
          CERTIFICATE PROGRAMS{" "}
          <span className="active-breadcrumbs"> / ISSUES</span>
        </span>
      </div>
      {/* Sorting Menu */}
      <div className="issue-header">
        <span className="active-breadcrumbs issues">
          {filterElements || filterTypes || filterStatus
            ? filteredIssues.length > 0
              ? `${filteredIssues.length} Issues available`
              : "0 issues available"
            : issues.length > 0
            ? `${issues.length} Issues available`
            : "0 issues available"}{" "}
        </span>
        <ListSelect
          label="Issue Elements"
          value={filterElements}
          setValue={setFilterElements}
          options={filterElementsOptions}
        />
        <ListSelect
          label="Type of Issues"
          value={filterTypes}
          setValue={setFilterTypes}
          options={filterTypesOptions}
        />
        <ListSelect
          label="Issue Status"
          value={filterStatus}
          setValue={setFilterStatus}
          options={filterStatusOptions}
        />
      </div>
      {/*  */}
      <div className="issues-main">
        {/* <ModifyCardModal /> */}
        {filterElements || filterTypes || filterStatus ? (
          filteredIssues.length > 0 ? (
            filteredIssues.map((issue) => (
              <IssueCard key={issue.id} issue={issue} />
            ))
          ) : (
            <div className="no-issues flex-center">
              <h1>No issues found</h1>
            </div>
          )
        ) : issues.length > 0 ? (
          issues.map((issue) => <IssueCard key={issue.id} issue={issue} />)
        ) : (
          <div className="no-issues flex-center">
            <h1>No issues found</h1>
          </div>
        )}
      </div>
      {/* <Modal>Hello Modal</Modal> */}
    </>
  );
};

export default IssuesPage;
