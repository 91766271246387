import "./App.scss";

import ReduxExample from "../ReduxExample";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import HomePage from "../pages/homepage/HomePage";
//import Footer from "../components/layout/Footer/Footer";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import history from ".././history";
// import Header from "../components/layout/Header/header";
import Course from "../pages/Course/MicrosoftCourse/Course";
import Verify from "../pages/Verify/Verify";
import AdobeAfter from "../pages/Course/AdobeAfterEffects/Course";
import AdobeAnimate from "../pages/Course/AdobeAnimate/Course";
import AdobeDream from "../pages/Course/AdobeDreamWeaver/Course";
import AdobeIllustrator from "../pages/Course/AdobeIllustrator/Course";
import AdobeInDesign from "../pages/Course/AdobeInDesign/Course";
import AdobePhotoshop from "../pages/Course/AdobePhotoshop/Course";
import AdobePremierPro from "../pages/Course/AdobePremierPro/Course";
import Autocad from "../pages/Course/AutoCad/Course";
import AutoDesk3DX from "../pages/Course/AutoDesk3DX/Course";
import AutoDeskFusion from "../pages/Course/AutoDeskFusion/Course";
import AutoDeskInventor from "../pages/Course/AutoDeskInventor/Course";
import AutoDeskMaya from "../pages/Course/AutoDeskMaya/Course";
import AutoDeskRevit from "../pages/Course/AutoDeskRevit/Course";
import Cloud from "../pages/Course/CloudComputing/Course";
import ComptiaNetwork from "../pages/Course/ComptiaNetwork/Course";
import ComptiaProject from "../pages/Course/ComptiaProject/Course";
import ComptiaSecurity from "../pages/Course/ComptiaSecurity/Course";
import Cyber from "../pages/Course/Cyber/Course";
import UIDev from "../pages/Course/UIDevelopment/Course";
import Java from '../pages/Course/JavaFundamentals/Course';
import Web from "../pages/Course/WebDevelopment/Course";
import Deep from "../pages/Course/DeepLearning/Course";
import DataStructure from "../pages/Course/DataStructure/Course";
import AppDevelopment from "../pages/Course/ApplicationDevelopment/Course";
import NetworkFundamentals from "../pages/Course/NetworkFundamentals/Course";
import Python from "../pages/Course/Python/Course";
import ReactCourse from "../pages/Course/React/Course";
import DataScience from "../pages/Course/DataScience/Course";
import MicrosoftOffice from "../pages/Course/MicrosoftOffice/Course";
import UnityProgrammer from "../pages/Course/UnityCertifiedProgrammer/Course";
import UnityArtist from "../pages/Course/UnityCertifiedUserArtist/Course";
import UnityVR from "../pages/Course/UnityCertifiedVrDeveloper/Course"
import ProtectedRoute from "./Routes/ProtectedRoute";
import AllCourses from "../pages/AllCourses/AllCourses";
import Jobs from "../pages/Jobs/Jobs";
import { ResumePage } from "../pages/Jobs/Jobs";
import LoginOtp from "../pages/LoginOtp";

import { SingleJob } from "../pages/SingleJob/SingleJob";
import AllJobs from "../pages/Jobs/AllJobs/AllJobs";
import AdminDashboard from "../admin/AdminDashboard";
import { useCookies } from "react-cookie";
import BusinessPage from "../pages/BusinessPage";
import AboutPage from "../pages/AboutPage"
import Register from "../components/Register/Register.js";
import Payment from "../components/Register/Payment.js";
import Checkout from "../pages/Checkout/Checkout";
import PrivacyPage from "../pages/PrivacyPage";
import TermsAndUse from "../pages/TermsAndUse";
import TermsAndCondition from "../pages/TermsAndCondition";
import ScrollToTop from "../components/scrollToTop/ScrollToTop";
import { useState, useEffect } from "react";
import axios from "axios";
import PhoneVerify from "./PhoneVerify";
import Success from '../pages/Success';
// import { Login } from "../pages/Login";
const App = () => {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(["refresh"]);
  const isAuth = () => {
    return cookies.refresh ? true : false;
  };
  
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isVerified, setIsVerified] = useState(false);
	const [userDetails, setUserDetails] = useState(null);
  const [updatePhone, setUpdatePhone] = useState(false);

  useEffect(() => {
    if (window.localStorage.getItem("uid")) {
      axios
        .get(`/getUserDetails/${window.localStorage.getItem('uid')}`)
        .then((res) => {
          console.log(res?.data?.user);
					setIsLoggedIn(true);
					setIsVerified(res?.data?.user?.isPhoneVerified);
					setUserDetails(res?.data?.user);
        })
        .catch((error) => {
					console.log(error);
				});
    }
  }, []);

  if (isLoggedIn && !isVerified && userDetails) {
		return (
      <PhoneVerify 
        userDetails={userDetails} 
        setUpdatePhone={setUpdatePhone}
        updatePhone={updatePhone} 
      />
    );
	}

  return (
    <Router>
      <main>
        <ScrollToTop />
        {/* <Header /> */}
        <Switch history={history}>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/login/:id" component={Verify} />
          <Route exact path="/success/:id" component={Success} />
          <Route path="/example" component={ReduxExample} />
          <Route path="/courses" component={AllCourses} />
          <Route exact path="/machine-learning-using-python" component={Course} />
          <Route exact path="/adobe/after" component={AdobeAfter} />
          <Route exact path="/adobe/photoshop" component={AdobePhotoshop} />
          <Route exact path="/adobe/animate" component={AdobeAnimate} />
          <Route exact path="/adobe/dreamwaiver" component={AdobeDream} />
          <Route exact path="/adobe/illustrator" component={AdobeIllustrator} />
          <Route exact path="/adobe/indesign" component={AdobeInDesign} />
          <Route exact path="/adobe/premier" component={AdobePremierPro} />
          <Route exact path="/autocad" component={Autocad} />
          <Route exact path="/autodesk3ds" component={AutoDesk3DX} />
          <Route exact path="/autodeskfusion" component={AutoDeskFusion} />
          <Route exact path="/autodeskinventor" component={AutoDeskInventor} />
          <Route exact path="/autodeskmaya" component={AutoDeskMaya} />
          <Route exact path="/autodeskrevit" component={AutoDeskRevit} />
          <Route exact path="/cloud-computing" component={Cloud} />
          <Route exact path="/comptianetwork" component={ComptiaNetwork} />
          <Route exact path="/comptiaproject" component={ComptiaProject} />
          <Route exact path="/comptiasecurity" component={ComptiaSecurity} />
          <Route exact path="/cyber-security" component={Cyber} />
          <Route exact path="/web-development" component={Web} />
          <Route exact path="/ui-development" component={UIDev} />
          <Route exact path="/python" component={Python} />
          <Route exact path="/application-development" component={AppDevelopment} />
          <Route exact path="/web-development-using-react" component={ReactCourse} />
          <Route exact path="/network-fundamentals" component={NetworkFundamentals} />
          <Route exact path="/data-structure" component={DataStructure} />
          <Route exact path="/deep-learning" component={Deep} />
          <Route exact path="/java" component={Java} />
          <Route exact path="/datascience" component={DataScience} />
          <Route exact path="/microsoftoffice" component={MicrosoftOffice} />
          <Route exact path="/unity/programmer" component={UnityProgrammer} />
          <Route exact path="/unity/artist" component={UnityArtist} />
          <Route exact path="/unity/vr" component={UnityVR} />
          <Route exact path="/jobs" component={Jobs} />
          <Route exact path="/business" component={BusinessPage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/checkout/:id/:state/:orderId?" component={Checkout} />
          <Route exact path="/privacypolicy" component={PrivacyPage} />
          <Route exact path="/terms" component={TermsAndCondition} />
          <Route exact path="/termsanduse" component={TermsAndUse} />
          <Route exact path="/resume" component={ResumePage} />
          <Route exact path="/login-otp" component={LoginOtp} />
          <Route
            exact
            path="/job/search/:searchInput/:loc"
            component={AllJobs}
          />
          <Route exact path="/singlejob/:id" component={SingleJob} />
          {/* <Route exact path="/admin/dashboard" component={AdminDashboard} /> */}
          <ProtectedRoute
            path="/admin/dashboard"
            component={AdminDashboard}
            isAuth={isAuth}
          />

          {/* <Route exact path="/allcourses" component={AllCourses} /> */}
          {/* Protected Route Example
          <ProtectedRoute path="/profile" component={Profile} isAuth={isAuth}/> */}
        </Switch>
        {/* <Footer /> */}
      </main>
    </Router>
  );
};

export default App;
