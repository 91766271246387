import React from "react";
import { Link } from "react-router-dom";

//faq
//privacy
//terms and conditions

function UsefulLinks() {
    return (
        <div className="footerCard">
            <h5 className="footer-heading">Usefull Links</h5>
            <a href="https://blog.foxmula.com/"> <p className="footer-link">Blogs</p></a>
            <Link to="/privacypolicy"> <p className="footer-link">Privacy Policy</p></Link>
            <Link to="/terms"> <p className="footer-link">Terms and Conditions</p></Link>
            <Link to="/termsanduse"> <p className="footer-link">Terms and Use</p></Link>
        </div>

    );
}
export default UsefulLinks;
