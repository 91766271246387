import React from "react";
import Navigation from "./Navigation/Navigation";
import UsefulLinks from "./UsefulLinks/UsefulLinks";
import GetinTouch from "./GetInTouch/GetinTouch";
import Address from "./Address/Address";
import "./footer.css";

const Footer = () => {
  return (
    <div className=" container-fluid footer">
      {/* <div className="col-lg"> */}
      <div className="row justify-content-center footer-row  " >
        <div className="col-xl-4 col-lg-6 col-md-12 cd-sm-12 footerCard"  ><Address /> </div>
        <div className="col-xl-3 col-lg-3 col-md-12 cd-sm-12 footerCard"  > <Navigation /></div>
        <div className="col-xl-3 col-lg-3 col-md-12 cd-sm-12 footerCard"  > <UsefulLinks /></div>
        <div className="col-xl-2 col-lg-auto col-md-12 cd-sm-12 footerCard" ><GetinTouch /></div>
        {/* </div> */}
      </div>
      {/* <div className="col-lg "> */}
      <div className="row justify-content-center footer-row">
        <div className="col">
          <hr className="mb-4" />
          <p style={{ color: "#ffffff" }}>© 2022 Foxmula</p>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
export default Footer;
