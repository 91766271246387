import React from 'react';
import './KeyHighlights.scss';

import { ReactComponent as Fundamentals} from '../../../../assests/Graphics/Icons/fundamentals.svg';
import { ReactComponent as Selfplaced} from '../../../../assests/Graphics/Icons/selfplaced.svg';
import { ReactComponent as Internship} from '../../../../assests/Graphics/Icons/internship.svg';
import { ReactComponent as Acrossindia} from '../../../../assests/Graphics/Icons/acrossindia.svg';
import { ReactComponent as Microsoft} from '../../../../assests/Graphics/Icons/microsoft.svg';
import { ReactComponent as World} from '../../../../assests/Graphics/Icons/world.svg';

const KeyHighlights =() => {
    return(
        <div className="KeyHighlights">
            <div className="KeyHighlights-header">
                <div className="KeyHighlights-header-title text-bold">Key Highlights</div>
                <div className="KeyHighlights-header-subtitle">Why do students give a funk about this ...</div>
            </div>
            <div className="KeyHighlights-cards">
                <div className="KeyHighlights-cards-card">
                    <div className="KeyHighlights-cards-card-logo"><Fundamentals/></div>
                    <div className="KeyHighlights-cards-card-desc">Focus kept on <span className="KeyHighlights-cards-card-desc-highlight">fundamentals</span> on which advanced topics are built upon.</div>
                </div>
                <div className="KeyHighlights-cards-card">
                    <div className="KeyHighlights-cards-card-logo"><World/></div>
                    <div className="KeyHighlights-cards-card-desc">Learn with <span className="KeyHighlights-cards-card-desc-highlight">real world projects</span> and application oriented teaching. Mugging up is for the stupid.</div>
                </div>
                <div className="KeyHighlights-cards-card">
                    <div className="KeyHighlights-cards-card-logo"><Microsoft/></div>
                    <div className="KeyHighlights-cards-card-desc"><span className="KeyHighlights-cards-card-desc-highlight">Microsoft Technology Associate</span> (MTA) & Foxmula certification to strengthen your profile.</div>
                </div>
                <div className="KeyHighlights-cards-card">
                    <div className="KeyHighlights-cards-card-logo"><Selfplaced/></div>
                    <div className="KeyHighlights-cards-card-desc">50+ hours of <span className="KeyHighlights-cards-card-desc-highlight"> self placed </span> learning sessions to dive deep into the subject with working professionals.</div>
                </div>
                <div className="KeyHighlights-cards-card">
                    <div className="KeyHighlights-cards-card-logo"><Acrossindia/></div>
                    <div className="KeyHighlights-cards-card-desc">Learn with students <span className="KeyHighlights-cards-card-desc-highlight"> across India </span> and be part of a community geared towards implementing change.</div>
                </div>
                <div className="KeyHighlights-cards-card">
                    <div className="KeyHighlights-cards-card-logo"><Internship/></div>
                    <div className="KeyHighlights-cards-card-desc"><span className="KeyHighlights-cards-card-desc-highlight">Internship </span> provided to give students a glimpse into the actual work in the field.</div>
                </div>
            </div>
        </div>
    )
}

export default KeyHighlights;