/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./CertificateSection.scss";
import Button from "../../../../components/botton/button";
import { useEffect } from "react";
import { ReactComponent as AddIconBlue } from "../../../assets/icons/add-icon-blue.svg";
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload-icon.svg";
import ListSelect from "../../../../components/ListSelect/ListSelect";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router";
import {
  createCertificate,
  getAllPrograms,
  getProgramById,
} from "../../../helpers/adminapicall";

const CertificateSection = ({ certificateData, setCertificateData }) => {
  const [cookies, setCookie] = useCookies(["refresh", "accessToken"]);
  const [certificateList, setCertificateList] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState("");
  const [certificateFields, setCertificateFields] = useState();
  const { pathname } = useLocation();

  // Certificate Fields States
  const [certificate_name, setCertificate_name] = useState();
  const [certificate_image, setCertificate_image] = useState("");
  const [certificate_description, setCertificate_description] = useState("");
  const [template_image, setTemplate_image] = useState("");
  const [keyHighlights, setKeyHighlights] = useState([
    {
      name: "",
      description: "",
      image: "",
    },
  ]);
  const [skills, setSkills] = useState([
    {
      name: "",
      description: "",
      image: "",
    },
  ]);
  const [jobs, setJobs] = useState(null);
  const [exams, setExams] = useState([
    {
      title: "",
      description: "",
    },
  ]);
  const [compitatorFeatures, setCompitatorFeatures] = useState([""]);

  const [compitators, setCompitators] = useState([
    {
      image_link: "",
      features: [false],
    },
  ]);
  // End of Certificate Fields States

  const handleAddCompitatorFeature = () => {
    setCompitatorFeatures([...compitatorFeatures, ""]);
    // Add new feature in all compuitators
    compitators.forEach((compitator) => {
      compitator.features.push(false);
    });
  };

  const handleAddCompitator = () => {
    // Add new compitator based on the compitatorFeatures
    let newCompitatorFeatures = [];
    compitatorFeatures.forEach((feature) => {
      newCompitatorFeatures.push(false);
    });
    setCompitators([
      ...compitators,
      {
        image_link: "",
        features: newCompitatorFeatures,
      },
    ]);
  };

  const handleRemoveCompitator = (index) => {
    let newCompitators = [...compitators];
    newCompitators.splice(index, 1);
    setCompitators([...newCompitators]);
  };

  useEffect(() => {
    // Filling the certificate fields from the data
    getAllPrograms(cookies.accessToken)
      .then((res) => {
        let certificatesRespone = res.data.map((program) => {
          return {
            programName: program.programName,
            programId: program.programId,
          };
        });
        setCertificateList(certificatesRespone);
      })
      .catch((err) => {
        console.log(err);
      });

    if (certificateData) {
      setCertificateFields(certificateData.certificate);
      console.log("Certificate Fields: ", certificateFields);
    }
  }, [certificateData]);

  // useEffect(() => {
  //   if (certificateFields) {
  //     fillFields(certificateFields);
  //   }
  // }, [certificateFields]);

  const getCompitatorPayload = () => {
    let compitatorPayload = [];

    const getFeatures = (checkmarks, featureNames) => {
      let features = [];
      checkmarks.forEach((checkmark, index) => {
        if (featureNames[index]) {
          features.push({
            name: featureNames[index],
            status: checkmark ? 1 : 0,
          });
        }
      });
      return features;
    };

    compitators.forEach((compitator) => {
      compitatorPayload.push({
        compitator: "compitator", //FIXME: Remove compitator field in backend then remove this
        image_link: compitator.image_link,
        feature: getFeatures(compitator.features, compitatorFeatures),
      });
    });

    return compitatorPayload;
  };

  const importCertificate = () => {
    if (certificateList.length > 0 && selectedCertificate) {
      let id = certificateList.find(
        (certificate) => certificate.programName === selectedCertificate
      ).programId;

      getProgramById(id, cookies.accessToken)
        .then((res) => {
          // setCertificateFields(res.data.certificate);
          fillFields(res.data.certificate);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getJobs = () => {
    // Convert the jobs text into array seprated by comma
    let jobsArray = [];
    let newJobs = jobs;
    // if (newJobs && newJobs.length > 0) {
    //   newJobs.split(",").forEach((job) => {
    //     jobsArray.push(job.trim());
    //   });
    // }
    return newJobs;
  };

  const fillFields = (data) => {
    console.log("Filling Fields: ", data);
    //certificate_name
    if (data.certificateName) setCertificate_name(data.certificateName);
    //certificate_image
    if (data.certificateImage) setCertificate_image(data.certificateImage);
    //certificate_description
    if (data.certificateDescription)
      setCertificate_description(data.certificateDescription);
    //template_image
    if (data.template_image) setTemplate_image(data.template_image);
    //keyHighlights
    if (data.key_highlights) setKeyHighlights(data.key_highlights);
    //skills
    if (data.skills) setSkills(data.skills);
    //jobs
    if (data.jobs) setJobs(data.jobs);
    //exams
    if (data.exams) setExams(data.exams);
    //compitatorFeatures
    if (data.compitator_features)
      setCompitatorFeatures(data.compitatorFeatures);
    //compitators
    if (data.compitators) setCompitators(data.compitators);
  };

  const handleCreate = () => {
    // setCertificateData({
    //   ...certificateData,
    //   certificateFields,
    // });

    let newJobs = getJobs();
    let certificateFeatures = getCompitatorPayload();
    let programObj = {
      certificates: [
        {
          certificate_name,
          certificate_description,
          certificate_image,
          template_image,
          certificate_link: "https://google.com",
          key_highlights: keyHighlights,
          skills,
          jobs: newJobs,
          exams,
          certificateFeatures,
        },
      ],
      lms: [],
    };

    console.log("Program Object Created: ", programObj);
    createCertificate(programObj).then((res) => {
      console.log(res);
    });
  };
  const handleUpdate = () => {
    setCertificateData({
      ...certificateData,
      certificateFields,
    });
  };

  return (
    <div style={{ padding: "10rem 1rem", paddingTop: "3rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          gap: "2rem",
          flexWrap: "wrap",
        }}
      >
        <ListSelect
          label="Import from Certificates"
          value={selectedCertificate}
          setValue={setSelectedCertificate}
          options={certificateList.map((program) => {
            return program.programName;
          })}
          customStyle={{ width: "80%" }}
        />
        <Button
          type="secondary"
          text="IMPORT"
          customStyle={{ paddingRight: "4rem", paddingLeft: "4rem" }}
          onClick={importCertificate}
        />
      </div>

      {/* //////////////////// Course Name////////////////////////// */}
      <SectionHeading heading="Course Name" />
      <div className="section-two-col">
        <input
          type="text"
          className="input-field"
          placeholder="Name of the certificate"
          value={certificate_name}
          onChange={(e) => {
            e.preventDefault();
            setCertificate_name(e.target.value);
          }}
        />
        <UploadButton
          text="badge"
          image={certificate_image}
          setImage={setCertificate_image}
        />
      </div>

      {/* //////////////////// TEMPLATE////////////////////////// */}
      <SectionHeading heading="Certificate Template" />
      <div className="section-two-col" style={{ alignItems: "start" }}>
        <textarea
          type="text"
          className="input-field"
          placeholder="About the certificate here"
          cols="30"
          rows="10"
          value={certificate_description}
          onChange={(e) => setCertificate_description(e.target.value)}
        />

        <UploadButton
          text="certifcate template"
          image={template_image}
          setImage={setTemplate_image}
        />
      </div>

      {/* //////////////////// KEY HIGHLIGHTS  ////////////////////////// */}
      <SectionHeading heading="Key Highlights" />
      {keyHighlights.map((el, index) => (
        // TODO: clear code of image upload
        <FeatureInputCard
          key={index}
          featureName={`Key highlight ${index + 1}`}
          data={el}
          allData={keyHighlights}
          setAllData={setKeyHighlights}
        />
      ))}
      <div className="flex-all-center">
        <Button
          type="secondary"
          text="KEY HIGHLIGHT"
          Icon={AddIconBlue}
          onClick={() => {
            setKeyHighlights([
              ...keyHighlights,
              {
                name: "",
                description: "",
                image: "",
              },
            ]);
          }}
        />
      </div>

      {/* ////////////////////  SKILL  ////////////////////////// */}

      <SectionHeading heading="Skill" />
      {skills.map((el, index) => (
        <FeatureInputCard
          key={index}
          featureName={`Skill ${index + 1}`}
          data={el}
          allData={skills}
          setAllData={setSkills}
        />
      ))}
      <div className="flex-all-center">
        <Button
          type="secondary"
          text="SKILL"
          Icon={AddIconBlue}
          onClick={() => {
            setSkills([
              ...skills,
              {
                name: "",
                description: "",
                image: "",
              },
            ]);
          }}
        />
      </div>

      {/* //////////////////// JOBS U CAN GET ////////////////////////// */}
      <SectionHeading heading="Jobs that you can get with this certificate" />
      <textarea
        type="text"
        className="input-field"
        style={{ width: "98%", minHeight: "10rem" }}
        placeholder="Jobs here ( Seperated by comma )"
        value={jobs}
        onChange={(e) => setJobs(e.target.value.split(","))}
      />

      {/* ////////////////////////////// Exam Details ////////////////////////// */}
      <SectionHeading heading="Exam Details" />
      {exams.map((el, index) => (
        <FeatureInputCard
          showUpload={false}
          key={index}
          featureName={`Exam ${index + 1}`}
          data={el}
          allData={exams}
          setAllData={setExams}
        />
      ))}

      <div className="flex-all-center">
        <Button
          type="secondary"
          text="EXAM DETAIL"
          Icon={AddIconBlue}
          onClick={() => {
            setExams([
              ...exams,
              {
                title: "",
                description: "",
              },
            ]);
          }}
        />
      </div>

      {/* //////////////////// WHY THIS CERTIFICATE  ////////////////////////// */}
      <SectionHeading heading="Why this certificate" />
      <div style={{ position: "relative" }}>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
          }}
        >
          <div style={{ visibility: "hidden", height: "8rem" }}></div>
          {compitatorFeatures.map((el, index) => (
            <div key={index} className="feature-input-container">
              <input
                type="text"
                value={el}
                onChange={(e) => {
                  let newArray = [...compitatorFeatures];
                  newArray[index] = e.target.value;
                  setCompitatorFeatures(newArray);
                }}
                placeholder={`Feature ${index + 1}`}
                style={{ border: "none", width: "12rem" }}
              />
            </div>
          ))}
          <Button
            type="secondary"
            text="FEATURE"
            Icon={AddIconBlue}
            onClick={handleAddCompitatorFeature}
            customStyle={{
              height: "3.5rem",
              width: "9rem",
            }}
          />
        </div>

        <div className="compitator-container">
          {/* TODO: pass features in card too */}
          {compitators.map((compitator, index) => (
            <CompitatorCard
              key={index}
              compitatorIndex={index}
              featureCount={compitatorFeatures.length}
              compitator={compitator}
              compitators={compitators}
              setCompitators={setCompitators}
            />
          ))}

          <Button
            type="secondary"
            text="COMPITATOR"
            Icon={AddIconBlue}
            onClick={handleAddCompitator}
            customStyle={{
              marginTop: "1.5rem",
              height: "3.5rem",
            }}
          />
        </div>
      </div>
      <div className="flex-center m-5">
        {pathname.includes("/create") ? (
          <Button type="primary" text="CREATE" onClick={handleCreate} />
        ) : (
          <Button type="primary" text="UPDATE" onClick={handleUpdate} />
        )}
      </div>
    </div>
  );
};

const SectionHeading = ({ heading }) => (
  <div className="section-heading">
    <h3>{heading}</h3>
    {/* <span className="horizontal-line"></span> */}
    <hr className="horizontal-line" />
  </div>
);

const UploadButton = ({ text = null, image = "", setImage = (e) => e }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState(null);
  // TODO: Try to remove imageURL state and directly use the image
  const [imageURL, setImageURL] = useState("");

  // If we have already a image url
  useEffect(() => {
    if (image) {
      setImageURL(image);
    }
  }, [image]);

  const allowedTypes = ["image/png", "image/jpeg"];

  useEffect(() => {
    if (selectedImage) {
      let data = new FormData();
      data.append("image", selectedImage);
      axios
        .post(`https://foxmula.herokuapp.com/upload`, data)
        .then((response) => {
          setImageURL(response.data.link);
          setImage(response.data.link);
        });
    }
  }, [selectedImage]);

  const handleUpload = (e) => {
    let selected = e.target.files[0]; // getting file from input

    if (selected && allowedTypes.includes(selected.type)) {
      setSelectedImage(selected);
      setError("");
    } else {
      setSelectedImage(null);
      setError("Please select an image files (png/jpeg)");
    }
  };

  const handleRemove = (e) => {
    setSelectedImage(null);
    setImageURL("");
    setImage("");
  };

  return (
    <div
      className={`file-upload-btn ${
        selectedImage || imageURL ? "file-upload-btn-selected" : ""
      }`}
    >
      {selectedImage || imageURL ? (
        <>
          <div>
            <img
              style={{ maxHeight: "95px", maxWidth: "150px" }}
              src={imageURL ? imageURL : URL.createObjectURL(selectedImage)}
              alt=""
            />
          </div>
          {text && (
            <div>
              <input
                type="text"
                placeholder="Alt Text"
                style={{ border: "none" }}
              />
              <Button type="secondary" text="REMOVE" onClick={handleRemove} />
            </div>
          )}
        </>
      ) : (
        <>
          <div>
            <UploadIcon />
          </div>
          <input type="file" name="Select image" onChange={handleUpload} />
          {error ? (
            <div style={{ color: "red" }}>{error}</div>
          ) : (
            text && <div>Upload / Drag and Drop the {text}</div>
          )}
        </>
      )}
    </div>
  );
};

const FeatureInputCard = ({
  featureName, // text
  showUpload = true,
  data,
  allData,
  setAllData,
}) => {
  // Structure of data
  // {
  //   name: "", / title: "" for exams
  //   description: "",
  //   imageURL: "",
  // }

  let index = allData.findIndex((el) => el === data);

  const handleChange = (e) => {
    e.preventDefault();
    console.log(e.target.name);
    let newData = [...allData];
    newData[index] = { ...data, [e.target.name]: e.target.value };
    setAllData([...newData]);
  };

  const handleUpload = (url) => {
    let newData = [...allData];
    console.log("Updating image in index:", index);
    console.log("oldData", newData[index]);
    newData[index] = { ...data, image: url };
    console.log("newData", newData[index]);
    setAllData([...newData]);
    console.log("allData", allData);
  };

  return (
    <div className="feature-input-card">
      {showUpload && (
        <div>
          <UploadButton image={data.image} setImage={handleUpload} />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          flexGrow: 1,
          maxWidth: "500px",
        }}
      >
        <input
          type="text"
          className="input-field"
          placeholder={`${featureName} name here`}
          name={data.name !== undefined ? "name" : "title"}
          value={data.name ? data.name : data.title}
          onChange={handleChange}
        />
        <input
          type="text"
          className="input-field"
          rows="3"
          placeholder={`${featureName} description here`}
          name="description"
          value={data.description}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

const CompitatorCard = ({
  compitatorIndex,
  compitator,
  compitators,
  setCompitators,
  featureCount,
}) => {
  const handleCheckboxChange = (compitatorIndex, featureIndex, e) => {
    console.log("Change");
    let newCompitators = [...compitators];
    newCompitators[compitatorIndex].features[featureIndex] = e.target.checked;
    setCompitators([...newCompitators]);
  };

  const handleUpload = (url) => {
    let newCompitators = [...compitators];
    newCompitators[compitatorIndex].image_link = url;
    setCompitators([...newCompitators]);
  };

  return (
    <div className="compitator-card">
      <div style={{ height: "8rem" }}>
        <UploadButton image={compitator.image_link} setImage={handleUpload} />
      </div>
      {compitator.features.map((feature, featureIndex) => {
        // Condition to display checkbox not more than features
        if (featureIndex < featureCount)
          return (
            <div key={featureIndex} className="compitator-checkbox-container">
              <div className="round-checkbox">
                <input
                  type="checkbox"
                  id={`checkbox-feature-${compitatorIndex}-${featureIndex}`}
                  checked={feature}
                  onChange={(e) =>
                    handleCheckboxChange(compitatorIndex, featureIndex, e)
                  }
                />
                <label
                  for={`checkbox-feature-${compitatorIndex}-${featureIndex}`}
                ></label>
              </div>
            </div>
          );
      })}
    </div>
  );
};

export default CertificateSection;
