import React, { useState } from 'react'
import Footer from '../layout/Footer/Footer'
import Header from '../layout/Header/header'
import './Register.css'

function Register() {
    
    const [course,setCourse] = useState({cname:"ML", cid:'C13', amount:30, hybridAmount:20, blendedAmount:50})
    const [type, setType] = useState('')
    const [error,setError] = useState(null)
    const [success,setSuccess] = useState('success')
    return (


        <div>
            <Header />
            <main>
                <section id="hero_in" class="cart_section">
                    <div class="wrapper">
                        <div class="container">
                            <div class="bs-wizard clearfix">
                                <div class="bs-wizard-step active">
                                    <div class="text-center bs-wizard-stepnum" style={{color:"black"}}>{course?.cname}</div>
                                    <div class="progress">
                                        <div class="progress-bar"></div>
                                    </div>
                                    <a href="#0" class="bs-wizard-dot" align="center"> </a>
                                </div>

                                <div class="bs-wizard-step disabled">
                                    <div class="text-center bs-wizard-stepnum" style={{color:"black"}}>Payment</div>
                                    <div class="progress">
                                        <div class="progress-bar"></div>
                                    </div>
                                    <a href="#0" class="bs-wizard-dot"> </a>
                                </div>

                                <div class="bs-wizard-step disabled">
                                    <div class="text-center bs-wizard-stepnum" style={{color:"black"}}>Finish!</div>
                                    <div class="progress">
                                        <div class="progress-bar"></div>
                                    </div>
                                    <a href="#0" class="bs-wizard-dot"> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="bg_color_1">
                    <div class="container margin_60_35">
                        <div class="row">
                            <div class="col-lg-8">
                                {error && error.length > 0 &&
                                    <div class="alert alert-danger" role="alert">
                                        {error}
                                    </div>}

                                {(success && success.length > 0) &&
                                    <div class="alert alert-success" role="alert">
                                        {success}
                                    </div>
                                }
                                <div class="box_cart">
                                    <table class="table table-striped cart-list">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Course
                                                </th>
                                                <th>
                                                    Price
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>

                                                    <h3>{course?.cname}</h3>
                                                </td>
                                                <td>
                                                    {(type === 'hybrid') ? (
                                                        <h3>{course?.hybridAmount}INR</h3>
                                                    ) : (
                                                        type === 'online' ? (
                                                            <h3>{course?.amount} INR</h3>
                                                        ) : (
                                                            type === 'blended' ? (
                                                                <h3>{course?.blendedAmount} INR</h3>
                                                            ) : null
                                                        )
                                                    )}

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <form action="/register/<%=course?.cid%>/new/<%=type%>" method="POST">
                                        <div class="cart-options clearfix">
                                            <div class="float-left">
                                                <div class="apply-coupon">
                                                    {(course?.cid !== 'C13') &&
                                                        <div class="inline fields">
                                                            <div class="field">
                                                                <div class="ui checkbox">
                                                                    <input type="checkbox" name="msme" id="msme"
                                                                        class="hidden" />
                                                                    <label style={{fontSize:'15px'}}>MSME Certificate: + 2000
                                                                        INR (It is mostly beneficial for a teaching
                                                                        career)</label>
                                                                </div>
                                                                <p>A recognition under Ministry of Micro, Small & Medium
                                                                    Enterprises by Government of India (for Indian Citizens
                                                                    only).</p>
                                                            </div>


                                                            {course?.cid === 'C08' &&
                                                                <div class="field">
                                                                    <div class="ui checkbox">
                                                                        <input type="checkbox" name="unity" class="hidden" />
                                                                        <label style={{fontSize:'15px'}}>Unity Certificate (+
                                                                            10000 INR)</label>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                    {(course?.cid === 'C10' || course?.cid === 'C13') &&
                                                        <div>
                                                            <label>Select your certificate from the list:</label>
                                                            <div class="inline fields">
                                                                <div class="field">
                                                                    <div class="ui radio checkbox">
                                                                        <input type="radio" name="cert" value="revit"
                                                                            class="hidden" />
                                                                        <label style={{fontSize:'15px'}}>Revit</label>
                                                                    </div>
                                                                </div>

                                                                <div class="field">
                                                                    <div class="ui radio checkbox">
                                                                        <input type="radio" name="cert" value="maya"
                                                                            class="hidden" />
                                                                        <label style={{fontSize:'15px'}}>Maya</label>
                                                                    </div>
                                                                </div>

                                                                <div class="field">
                                                                    <div class="ui radio checkbox">
                                                                        <input type="radio" name="cert" value="inventor"
                                                                            class="hidden" />
                                                                        <label style={{fontSize:'15px'}}>Inventor</label>
                                                                    </div>
                                                                </div>

                                                                <div class="field">
                                                                    <div class="ui radio checkbox">
                                                                        <input type="radio" name="cert" value="autocad"
                                                                            class="hidden" />
                                                                        <label style={{fontSize:'15px'}}>Autocad</label>
                                                                    </div>
                                                                </div>

                                                                <div class="field">
                                                                    <div class="ui radio checkbox">
                                                                        <input type="radio" name="cert" value="dsmax"
                                                                            class="hidden" />
                                                                        <label style={{fontSize:'15px'}}>3DS Max</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }


                                                    <br></br>
                                                    {(course?.cid === 'C10') ?
                                                        (
                                                            <div>
                                                                <label>Select Additional Certificate (Select at most four
                                                                    certificates from the list & DO NOT SELECT THE ONE YOU HAVE
                                                                    SELECTED TO TAKE ABOVE):</label>
                                                                <div class="inline fields">
                                                                    <div class="field">
                                                                        <div class="ui checkbox">
                                                                            <input type="checkbox" value="revit" name="adcert"
                                                                                class="hidden" />
                                                                            <label style={{fontSize:'15px'}}>Revit (+ 1500
                                                                                INR)</label>
                                                                        </div>
                                                                    </div>

                                                                    <div class="field">
                                                                        <div class="ui checkbox">
                                                                            <input type="checkbox" name="adcert" value="maya"
                                                                                class="hidden" />
                                                                            <label style={{fontSize:'15px'}}>Maya (+ 1500
                                                                                INR)</label>
                                                                        </div>
                                                                    </div>

                                                                    <div class="field">
                                                                        <div class="ui checkbox">
                                                                            <input type="checkbox" name="adcert" value="inventor"
                                                                                class="hidden" />
                                                                            <label style={{fontSize:'15px'}}>Inventor (+ 1500
                                                                                INR)</label>
                                                                        </div>
                                                                    </div>

                                                                    <div class="field">
                                                                        <div class="ui checkbox">
                                                                            <input type="checkbox" name="adcert" value="autocad"
                                                                                class="hidden" />
                                                                            <label style={{fontSize:'15px'}}>Autocad (+ 1500
                                                                                INR)</label>
                                                                        </div>
                                                                    </div>

                                                                    <div class="field">
                                                                        <div class="ui checkbox">
                                                                            <input type="checkbox" name="adcert" value="dsmax"
                                                                                class="hidden" />
                                                                            <label style={{fontSize:'15px'}}>3DS Max (+ 1500
                                                                                INR)</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            (course?.cid === 'C13') ? (
                                                                <div>
                                                                    <label>Select Additional Certificate (Select at most four
                                                                        certificates from the list & DO NOT SELECT THE ONE YOU HAVE
                                                                        SELECTED TO TAKE ABOVE):</label>
                                                                    <div class="inline fields">
                                                                        <div class="field">
                                                                            <div class="ui checkbox">
                                                                                <input type="checkbox" name="adcert" value="revit"
                                                                                    class="hidden" />
                                                                                <label style={{fontSize:'15px'}}>Revit (+ 2000
                                                                                    INR)</label>
                                                                            </div>
                                                                        </div>

                                                                        <div class="field">
                                                                            <div class="ui checkbox">
                                                                                <input type="checkbox" name="adcert" value="maya"
                                                                                    class="hidden" />
                                                                                <label style={{fontSize:'15px'}}>Maya (+ 2000
                                                                                    INR)</label>
                                                                            </div>
                                                                        </div>

                                                                        <div class="field">
                                                                            <div class="ui checkbox">
                                                                                <input type="checkbox" name="adcert" value="inventor"
                                                                                    class="hidden" />
                                                                                <label style={{fontSize:'15px'}}>Inventor (+ 2000
                                                                                    INR)</label>
                                                                            </div>
                                                                        </div>

                                                                        <div class="field">
                                                                            <div class="ui checkbox">
                                                                                <input type="checkbox" name="adcert" value="autocad"
                                                                                    class="hidden" />
                                                                                <label style={{fontSize:'15px'}}>AutoCAD (+ 2000
                                                                                    INR)</label>
                                                                            </div>
                                                                        </div>

                                                                        <div class="field">
                                                                            <div class="ui checkbox">
                                                                                <input type="checkbox" name="adcert" value="dsmax"
                                                                                    class="hidden" />
                                                                                <label style={{fontSize:'15px'}}>3DS Max (+ 2000
                                                                                    INR)</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : null)}


                                                    <br />

                                                    <div class="form-group">
                                                        <div class="ui radio checkbox">
                                                            <input type="radio" name="tcode" checked="" value="amb"
                                                                class="hidden" tabindex="0" />
                                                            <label>
                                                                Ambassador/Offer Code (If any)
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {(type !== "hybrid") &&
                                                        <div class="form-group">
                                                            <div class="ui radio checkbox">
                                                                <input type="radio" name="tcode" value="rid" class="hidden"
                                                                    tabindex="0" />
                                                                <label>
                                                                    Old RID
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }
                                                    <br />
                                                    <div class="field">
                                                        <input type="text" id="code" value="" name="code"
                                                            placeholder="Code" class="form-control" />
                                                    </div>

                                                </div>
                                                <br />
                                                <br />

                                                {(course?.cid === 'C21' || course?.cid === 'C22') ? (
                                                    <div class="field">
                                                        <div class="ui checkbox" style={{background:'yellow', padding:'7px'}}>
                                                            <input type="checkbox" id="csubs" name="specialScheme"
                                                                value="on" class="hidden" style={{background:'yellow'}} />
                                                            <label style={{fontSize: '15px', marginBottom:'0px'}}>Check to pay <b>Rs. 5,000 now</b> and the remaining amount next month.</label>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div class="field">
                                                        <div class="ui checkbox">
                                                            <input type="checkbox" id="csubs" name="csubs" class="hidden" />
                                                            <label style={{fontSize: '15px'}}>Check if you would like to pay
                                                                half amount now, and the remaining half later. Your
                                                                feasibility is our Priority</label>
                                                        </div>
                                                    </div>
                                                )}

                                                <br />
                                                {(type === 'hybrid') &&

                                                    <div class="field">
                                                        <select id="city" name="city" class="form-control ui fluid dropdown"
                                                            required>
                                                            <option>Select city (required)</option>
                                                            <option value="Jaipur">Jaipur</option>
                                                            <option value="Ranchi">Ranchi</option>
                                                            <option value="Lucknow">Lucknow</option>
                                                            <option value="Delhi">Delhi</option>
                                                            <option value="Bengaluru">Bengaluru</option>
                                                            <option value="Chennai">Chennai</option>
                                                            <otpion value="Dehradun">Dehradun</otpion>
                                                            <option value="Ghaziabad">Ghaziabad</option>
                                                            <option value="Noida">Noida</option>
                                                            <option value="Agra">Agra</option>
                                                            <option value="Ahmedabad">Ahmedabad</option>
                                                            <option value="Kolkata">Kolkata</option>
                                                            <option value="Pune">Pune</option>
                                                            <option value="Mumbai">Mumbai</option>
                                                        </select>
                                                    </div>
                                                }
                                                <br />
                                                <div class="form-group">
                                                    <div class="float-left fix_mobile">
                                                        <button type="submit" id="submitBtn" class="btn_1 outline"
                                                        >Proceed to
                                                            Payment</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <aside class="col-lg-4" id="sidebar">
                                <div class="box_detail">
                                    <div id="total_cart">
                                        Total <span class="float-right"><span id="tot_amt">
                                            {(type === 'hybrid') ?
                                                course?.hybridAmount
                                                : ((type === 'online') ?
                                                    course?.amount
                                                    : ((type === 'blended') ?
                                                        course?.blendedAmount
                                                        : null))}
                                        </span> INR</span>
                                    </div>
                                    <div class="add_bottom_30">Almost ready for an adventure to a Job-ready profile. Also, beside the above total fees, we'll be needing a little extra. Just a small touch with dedication, focus and Internet from your end.</div>
                                    <a href="/courses" class="btn_1 full-width outline"><i class="icon-right"></i> Go back to Courses</a>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Register