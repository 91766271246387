import React from "react";
import IITKharagpur from "../../assests/Graphics/Logos/Colleges/CollegesXL/IITKharagpur.png"
import IITKanpur from "../../assests/Graphics/Logos/Colleges/CollegesXL/IITKanpur.png"
import IITBHU from "../../assests/Graphics/Logos/Colleges/CollegesXL/IITBHU.png"
import UPES from "../../assests/Graphics/Logos/Colleges/CollegesXL/UPES.png"
import NITJalandar from "../../assests/Graphics/Logos/Colleges/CollegesXL/NITJalandar.png"
import NITBhopal from "../../assests/Graphics/Logos/Colleges/CollegesXL/NITBhopal.png"
import VITVellore from "../../assests/Graphics/Logos/Colleges/CollegesXL/VITVellore.png"
import CU from "../../assests/Graphics/Logos/Colleges/CollegesXL/CU.png"
import Ramaiah from "../../assests/Graphics/Logos/Colleges/CollegesXL/Ramaiah.png"
import IITRopar from "../../assests/Graphics/Logos/Colleges/CollegesXL/IITRopar.png"
import Thappar from "../../assests/Graphics/Logos/Colleges/CollegesXL/Thappar.png"
import "./Community.scss"



const Community = () => {
    return (
        <div className="testimonials-community-container">
        <h2 className="testimonials-heading-community" >Our Community Partners</h2>
        <p className="testimonials-heading-desc-community">Our ever expanding active presence at</p>
        <div className="testimonial-community-college-container">
          <img src={IITKharagpur} alt="IITKharagpur" className="college-images"></img>
          <img src={IITKanpur} alt="IITKanpur" className="college-images"></img>
          <img src={IITBHU} alt="IITBHU" className="college-images"></img>
          <img src={UPES} alt="UPES" className="college-images"></img>
          <img src={NITJalandar} alt="NITJalandar" className="college-images"></img>
          <img src={NITBhopal} alt="NITBhopal" className="college-images"></img>
          <img src={VITVellore} alt="VITVellore" className="college-images"></img>
          <img src={CU} alt="CU" className="college-images"></img>
          <img src={Ramaiah} alt="Ramaiah" className="college-images"></img>
          <img src={IITRopar} alt="IITRopar" className="college-images"></img>
          <img src={Thappar} alt="Thappar" className="college-images"></img>
        </div>
      </div>

    );
};

export default Community;