/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./StudentSection.scss";

//Components
import Button from "../../../../../components/botton/button";
import SearchBar from "../../../../components/SearchBar/SearchBar"; //Inside UploadResultsPage
import ListSelect from "../../../../../components/ListSelect/ListSelect";
import Popper from "../../../../components/Popper/Popper";

//APIs
import { updateBookingStatus } from "../../../../helpers/adminapicall";
import { useCookies } from "react-cookie";

const StudentSection = ({
  // bookings, setBookings, //FIXME: uncomment
  data = {},
}) => {
  const [search, setSearch] = useState("");
  const [filterTries, setFilterTries] = useState("");
  const filterTriesOptions = ["1st", "2nd", "3rd"];
  const [filterStatus, setFilterStatus] = useState("");
  const filterStatusOptions = ["All", "PASSED", "FAILED", "ABSENT"];

  //TODO: Remove Temp data
  data = [
    {
      student_name: "Kathryn Murphy",
      student_email: "randomemailid@gmail.com",
      student_phone: "9875543210",
      try_status: "1st",
      exam_status: "passed",
      certificate_name_id: "4425QE",
      booking_status: "CANCELLED",
    },
    {
      student_name: "Ram",
      student_email: "ram123@gmail.com",
      student_phone: "987614412",
      try_status: "3rd",
      exam_status: "failed",
      certificate_name_id: "",
    },
    {
      student_name: "Shyam",
      student_email: "randomemailid@gmail.com",
      student_phone: "7546543210",
      try_status: "2nd",
      exam_status: "absent",
      certificate_name_id: "fsd5h",
      booking_status: "CANCELLED",
    },
    {
      student_name: "Raman",
      student_email: "randomemailid@gmail.com",
      student_phone: "98762343210",
      try_status: "1st",
      exam_status: "",
      certificate_name_id: "",
    },
    {
      student_name: "Raghav",
      student_email: "randomemailid@gmail.com",
      student_phone: "98765432752",
      try_status: "2nd",
      exam_status: "passed",
      certificate_name_id: "",
      booking_status: "CANCELLED",
    },
    {
      student_name: "Rohan",
      student_email: "randomemailid@gmail.com",
      student_phone: "2346543210",
      try_status: "1st",
      exam_status: "passed",
      certificate_name_id: "234gfeeee",
    },
    {
      student_name: "Laxaman",
      student_email: "randomemailid@gmail.com",
      student_phone: "9876556810",
      try_status: "3rd",
      exam_status: "",
      certificate_name_id: "",
      booking_status: "CANCELLED",
    },
    {
      student_name: "Zakhir",
      student_email: "randomemailid@gmail.com",
      student_phone: "9834543210",
      try_status: "1st",
      exam_status: "",
      certificate_name_id: "rfge4",
    },
    {
      student_name: "Vijay",
      student_email: "randomemailid@gmail.com",
      student_phone: "9876587910",
      try_status: "2nd",
      exam_status: "passed",
      certificate_name_id: "",
      booking_status: "CANCELLED",
    },
    {
      student_name: "Ajay",
      student_email: "randomemailid@gmail.com",
      student_phone: "98764446210",
      try_status: "3rd",
      exam_status: "passed",
      certificate_name_id: "",
    },
    {
      student_name: "Shivam",
      student_email: "randomemailid@gmail.com",
      student_phone: "9808943210",
      try_status: "1st",
      exam_status: "passed",
      certificate_name_id: "",
    },
    {
      student_name: "Lucky",
      student_email: "randomemailid@gmail.com",
      student_phone: "9878643210",
      try_status: "2nd",
      exam_status: "failed",
      certificate_name_id: "",
      booking_status: "CANCELLED",
    },
  ];

  const [bookings, setBookings] = useState([...data]); //FIXME: Remove this temp bookings and uncomment bookings in props
  const [searchBookings, setSearchBookings] = useState([]);

  // Sort data on search
  useEffect(() => {
    if (search) {
      let filteredData = bookings.filter((item) => {
        return (
          item.student_name.toLowerCase().includes(search.toLowerCase()) ||
          item.student_email.toLowerCase().includes(search.toLowerCase()) ||
          item.student_phone.includes(search) ||
          item.try_status.toLowerCase().includes(search.toLowerCase()) ||
          item.exam_status.toLowerCase().includes(search.toLowerCase()) ||
          item.certificate_name_id.toLowerCase().includes(search.toLowerCase())
        );
      });
      setSearchBookings([...filteredData]);
    } else if (filterTries) {
      let filteredData = bookings.filter((item) => {
        return item.try_status.toLowerCase() === filterTries.toLowerCase();
      });
      setSearchBookings([...filteredData]);
    } else if (filterStatus) {
      let filteredData = bookings.filter((item) => {
        return item.exam_status.toLowerCase() === filterStatus.toLowerCase();
      });
      setSearchBookings([...filteredData]);
    } else {
      setSearchBookings("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filterTries]);

  const updateBooking = (index, value) => {
    let newBookings = [...bookings];
    newBookings[index].booking_name_id = value;
    setBookings([...newBookings]);
  };

  return (
    <div className="results-container" style={{ padding: "2rem 0" }}>
      <div className="flex-all-center" style={{ gap: "1rem" }}>
        <SearchBar
          value={search}
          setValue={setSearch}
          text="Search name, email ID or phone number"
        />
        <ListSelect
          label="No. of tries"
          value={filterTries}
          setValue={setFilterTries}
          options={filterTriesOptions}
        />
        <ListSelect
          label="Status"
          value={filterStatus}
          setValue={setFilterStatus}
          options={filterStatusOptions}
        />
      </div>

      {/* Search Result Found */}
      {searchBookings.length > 0 && (
        <>
          <div>
            <span className="text-primary">{searchBookings.length}</span>{" "}
            results found
          </div>
          <div className="results-card-container">
            {searchBookings.map((certificate, index) => {
              return (
                <StudentCard
                  key={index}
                  index={index}
                  booking={certificate}
                  updateBooking={updateBooking}
                />
              );
            })}
            <span className="floating-btn-container">
              <Button type="primary" text="CONFIRM & UPDATE" />
            </span>
          </div>
        </>
      )}

      {/* Data */}
      {!filterTries && !search && (
        <div className="results-card-container">
          {bookings.map((booking, index) => {
            return (
              <StudentCard
                key={index}
                index={index}
                booking={booking}
                updateBooking={updateBooking}
              />
            );
          })}
          <span className="floating-btn-container">
            <Button type="primary" text="CONFIRM & UPDATE" />
          </span>
        </div>
      )}
    </div>
  );
};

const StudentCard = ({ index, booking, updateBooking }) => {
  const [cookies, setCookie] = useCookies(["refresh", "accessToken"]); //FIXME: remove token

  const handleCancelBooking = () => {
    updateBookingStatus(booking.booking_id, "CANCELLED", cookies.accessToken); //FIXME: remove token
  };

  return (
    <div className="result-card">
      <span className="result-card-item text-bold">{booking.student_name}</span>
      <span className="result-card-item">{booking.student_email}</span>
      <span className="result-card-item">{booking.student_phone}</span>
      <span
        className={`result-card-item text-bold ${
          booking.try_status === "3rd" && "text-accent-1"
        }`}
      >
        {booking.try_status} TRY
      </span>
      <span
        className="status-container result-card-item"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          justifyItems: "end",
          padding: "0.5rem",
        }}
      >
        <span
          className={`status-text text-bold status-text-${booking.exam_status}`}
        >
          {booking.exam_status.toUpperCase()}
        </span>
        <span className="text-bold">{booking.certificate_name_id}</span>
        <span>
          {booking.booking_status !== "CANCELLED" && (
            <Popper>
              <div
                className="add-popover-content text-danger text-bold"
                onClick={{ handleCancelBooking }}
              >
                Cancel Booking
              </div>
            </Popper>
          )}
        </span>
      </span>
    </div>
  );
};

export default StudentSection;
