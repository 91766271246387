const data = [
    {
        course: 'ML using python', data: [
            {
                title: "Introduction to Analytics/Data Science",
                items: ["What is Data Science?",
                    "What does Data Science involve?",
                    "Era of Data Science",
                    "Business Intelligence vs Data Science",
                    "Life cycle of Data Science",
                    "Tools of Data Science",
                ]
            },
            {
                title: "Statistical Inference",
                items: [
                    "What is Statistical Inference?",
                    "Terminologies of Statistics",
                    "Measures of Centers",
                    "Measures of Spread",
                    "Bivariate Analysis",
                    "Multi variate analysis",
                    "Plots"]
            },
            {
                title: "Excel",
                items: ["Data cleaning, Merging and Pivots",]
            },
            {
                title: "Python - Data Extraction, Wrangling and Exploration",
                items: [
                    "Data Analysis Pipeline",
                    "What is Data Extraction",
                    "Types of Data",
                    "Raw and Processed Data",
                    "Data Wrangling",
                    "Exploratory Data Analysis",
                    "Visualization of Data",
                ]
            },
            {
                title: "SQL",
                items: ["Merges, Processing, Group by",]
            },
            {
                title: "Introduction to Machine Learning",
                items: [
                    "What is Machine Learning?",
                    "Machine Learning Use-Cases",
                    "Machine Learning Process Flow",
                    "Machine Learning Categories",
                    "Supervised Learning",
                    "Linear Regression",
                    "Logistic Regression",]
            },
            {
                title: "Regression and Classification",
                items: [
                    "What is Classification and its use cases?",
                    "Decision Tree?",
                    "Algorithms for Decision Tree",
                    "Evaluation",
                    "Random Forest",]
            },
            {
                title: "Tableau",
                items: ["Creating reports",]
            },
            {
                title: "Unsupervised Learning",
                items: ["What is Clustering & its Use Cases?",
                    "What is K-means Clustering?",
                    "What is Hierarchical Clustering?",]
            },
            {
                title: "Introduction to AI, Projects, Grooming, etc",
            }
        ]
    },
    {
        course: 'Comptia Network+',
        LK:true,
        data: [
            { title: "Lesson 1", LK:true, items: ["Explaining the OSI Model and TCP / IP Models"] },

            { title: "Lesson 2", items: ["Explaining Properties of Network Traffic"] },

            { title: "Lesson 3", items: ["Installing and Configuring Switched Networks"] },

            { title: "Lesson 4", items: ["Configuring IP Networks"] },

            { title: "Lesson 5", items: ["Installing and Configuring Routed Networks"] },

            { title: "Lesson 6", items: ["Configuring and Monitoring Ports and Protocols"] },

            { title: "Lesson 7", items: ["Explaining Network Applications and Storage Services"] },

            { title: "Lesson 8", items: ["Monitoring and Troubleshooting Networks"] },

            { title: "Lesson 9", items: ["Explaining Networking Attacks and Mitigations"] },

            { title: "Lesson 10", items: ["Installing and Configuring Security Devices"] },

            { title: "Lesson 11", items: ["Explaining Authentication and Access Controls"] },

            { title: "Lesson 12", items: ["Deploying and Troubleshooting Cabling Solutions"] },

            { title: "Lesson 13", items: ["Implementing and Troubleshooting Wireless Technologies"] },

            { title: "Lesson 14", items: ["Comparing and Contrasting WAN Technologies"] },

            { title: "Lesson 15", items: ["Using Remote Access Methods"] },

            { title: "Lesson 16", items: ["Identifying Site Policies and Best Practices"] },
        ]
    },
    {
        course: "Comptia Security+",
        LK:true,
        data: [
            { title: "Lesson 1", LK:true, items: ["Comparing Security Roles and Security Controls"] },
            { title: "Lesson 2", items: ["Explaining Threat Actors and Threat Intelligence"] },
            { title: "Lesson 3", items: ["Performing Security Assessments"] },
            { title: "Lesson 4", items: ["Identifying Social Engineering and Malware"] },
            { title: "Lesson 5", items: ["Summarizing Basic Cryptographic Concepts"] },
            { title: "Lesson 6", items: ["Implementing Public Key Infrastructure"] },
            { title: "Lesson 7", items: ["Implementing Authentication Controls"] },
            { title: "Lesson 8", items: ["Implementing Identity and Account Management Controls"] },
            { title: "Lesson 9", items: ["Implementing Secure Network Designs"] },
            { title: "Lesson 10", items: ["Implementing Network Security Appliances"] },
            { title: "Lesson 11", items: ["Implementing Secure Network Protocols"] },
            { title: "Lesson 12", items: ["Implementing Host Security Solutions"] },
            { title: "Lesson 13", items: ["Implementing Secure Mobile Solutions"] },
            { title: "Lesson 14", items: ["Summarizing Secure Application Concepts"] },
            { title: "Lesson 15", items: ["Implementing Secure Cloud Solutions"] },
            { title: "Lesson 16", items: ["Explaining Data Privacy and Protection Concepts"] },
            { title: "Lesson 17", items: ["Performing Incident Response"] },
            { title: "Lesson 18", items: ["Explaining Digital Forensics"] },
            { title: "Lesson 19", items: ["Summarizing Risk Management Concepts"] },
            { title: "Lesson 20", items: ["Implementing Cybersecurity Resilience"] },
            { title: "Lesson 21", items: ["Explaining Physical Security"] },
        ]
    },
    {
        course: "Autodesk Revit",
        LK:true,
        data: [
            {
                title: "Domain 1 - Creating and Modifying Components",
                LK:true,
                items: [
                    "Creating and Modifying Components Pre-Assessment"

                    , "Workbook and Support Files"

                    , "Create Grids and Levels"

                    , "Working with Walls"

                    , "Doors and Windows"

                    , "Tag, Load, and Modify Components"

                    , "Creating and Modifying Components Labs"

                    , "Creating and Modifying Components Post-Assessment"]
            },
            {
                title: "Domain 2 - Modeling and Modifying Elements",
                items: ["Modeling and Modifying Elements Pre-Assessment",

                    "Roofs and Stairs",

                    "Ramps and Railings",

                    "Floors",

                    "Modify Elements",

                    "Toposurfaces and Columns",

                    "Modeling and Modifying Elements Labs",

                    "Modeling and Modifying Elements Post-Assessment"]
            },
            {
                title: "Domain 3 - Managing Views", items: ["Managing Views Pre-Assessment"

                    , "View Scaling and Detail"

                    , "Visibility"

                    , "Ranges and Duplicates"

                    , "Creating Views"

                    , "Managing Views Labs"

                    , "Managing Views Post-Assessment"]
            },
            {
                title: "Domain 4 - Managing Documentation", items: ["Managing Documentation Pre-Assessment"

                    , "Text and Dimensions"

                    , "Sheets and Schedules"

                    , "Managing Documentation Labs"

                    , "Managing Documentation Post-Assessment"]
            }
        ]
    },
    {
        course: "Autodesk Inventor",
        LK:true,
        data: [
            {
                title: "Domain 1 - User Interface and Navigation",
                LK:true,
                items: [
                    "Inventor Domain 1 Pre-Assessment"

                    , "Inventor Workbook and Support Files"

                    , "Touring the Interface"

                    , "ViewCube and Other Viewing Tools"

                    , "ViewCube Settings"

                    , "File Types and Standard Templates"

                    , "Inventor Domain 1 Labs"

                    , "Inventor Domain 1 Post-Assessment"]
            },
            {
                title: "Domain 2 - Assembly Modeling",
                items: ["Inventor Domain 2 Pre-Assessment",

                    "Assembly Constraints and Offsets",

                    "Ground Components",

                    "Apply Offsets",

                    "Degrees of Freedom",

                    "Create a Presentation Model",

                    "Inventor Domain 2 Labs",

                    "Inventor Domain 2 Post-Assessment"]
            },
            {

                title: "Domain 3 - Drawing", items: ["Inventory Domain 3 Pre-Assessment"
                    , "Control Sheet Size"
                    , "Add a Title Block"
                    , "Select and Place a Front View"
                    , "Create Views from Existing Views"
                    , "Add Annotation and Dimensioning"
                    , "Add Sheets"
                    , "Views from Assemblies and Presentations"
                    , "Add Balloons"
                    , "Create and Edit a Parts List"
                    , "Inventor Domain 3 Labs"
                    , "Inventor Domain 3 Post-Assessment"]
            },
            {
                title: "Domain 4 - Part Modeling", items: [
                    "Inventor Domain 4 Pre-Assessment"
                    , "Create Extrude Features"
                    , "Create Rectangular Patterns"
                    , "Create Circular Patterns"
                    , "Create a Shell Feature"
                    , "Apply Fillets"
                    , "Apply Chamfers"
                    , "Create Hole Features"
                    , "Create Revolve Features"
                    , "Place Threads"
                    , "Inventor Domain 4 Labs"
                    , "Inventor Domain 4 Post-Assessment"]

            },
            {
                title: "Domain 5 - Sketching", items: [
                    "Inventor Domain 5 Pre-Assessment",
                    "Apply Dimensions",
                    "Assign Geometric Constraints",
                    "Project Geometry",
                    "Geometric Shapes",
                    "Modify an Inventor Model",
                    "Inventor Domain 5 Labs",
                    "Inventor Domain 5 Post-Assessment"
                ]
            },
            {
                title: "Domain 6 - Browser Editing", items: [
                    "Inventor Domain 6 Pre-Assessment",
                    "Deleting Features",
                    "Suppressing Features",
                    "Toggle Visibility",
                    "Inventor Domain 6 Labs",
                    "Inventor Domain 6 Post-Assessment",
                ]
            }
        ]
    },
    {
        course: "Autodesk Autocad",
        LK:true,
        data: [
            {
                title: "Apply Basic Drawing Skills",LK:true,
                items: [
                    "Session 1 Pre-Assessment"
                    , "AutoCAD Course Resources"
                    , "Create Selection Sets"
                    , "Use Coordinate Systems"
                    , "Dynamic Input and Direct Distance"
                    , "Use Inquiry Commands"
                    , "Session 1 Labs"
                    , "Session 1 Post-Assessment"
                ]
            },
            {
                title: "Draw Objects, Draw with Accuracy",
                items: [
                    "Session 2 Pre-Assessment"
                    , "Draw Lines and Rectangles"
                    , "Draw Circles, Arcs, and Polygons"
                    , "Draw Polylines"
                    , "Draw with Accuracy"
                    , "Session 2 Labs"
                    , "Session 2 Post-Assessment"
                ]
            },
            {
                title: "Move and Copy Objects", items: [
                    "Session 3 Pre-Assessment"
                    , "Move and Copy Objects"
                    , "Rotate and Scale Objects"
                    , "Create and Use Arrays"
                    , "Trim and Extend Objects"
                    , "Offset and Mirror Objects"
                    , "Grip Editing, Fillet, and Chamfer"
                    , "Session 3 Labs"
                    , "Session 3 Post-Assessment"
                ]
            },
            {
                title: "Additional Drawing Techniques, Organize Objects, Reuse Content, Layouts, and Printing", items: [
                    "Session 4 Pre-Assessment"
                    , "Use Additional Drawing Techniques"
                    , "Organize Objects"
                    , "Reuse, Annotate, and Print"
                    , "Session 4 Labs"
                    , "Session 4 Post-Assessment"
                ]
            }
        ]
    },
    {
        course: "Adobe After Effects",
        data: [
            {
                title: "Domain 1: Working in the Visual Effects and Motion Graphics Industry",
                LK:true,
                items: [
                    "Domain 1 Pre-Assessment"
                    , "Workbook and Support Files"
                    , "Lesson 1 - Purpose, Audience, and Communication"
                    , "Lesson 2 - Copyright, Permissions, and Licensing; Video Production Principles"
                    , "Lesson 3 - Visual Effects and Design Principles"
                    , "Domain 1 Post-Assessment"
                    , "Session 1 Post-Assessment"
                ]
            },
            {
                title: "Domain 2: Project Setup and Interface",
                items: [
                    "Domain 2 Pre-Assessment"
                    , "Lesson 1 - Creating a Project"
                    , "Lesson 2 - Organizing and Customizing Workspaces"
                    , "Lesson 3 - Non-Visible Design Tools"
                    , "Lesson 4 - Import and Manage Assets"
                    , "Domain 2 Post-Assessment"
                ]
            },
            {
                title: "Domain 3: Organizing Projects", items: [
                    "Domain 3 Pre-Assessment",
                    "Lesson 1 - Use the Timeline Panel",
                    "Lesson 2 - Manage Layerss",
                    "Lesson 3 - Modify Layer Visibility",
                    "Domain 3 Post-Assessment",
                ]
            },
            {
                title: "Domain 4: Creating and Modifying Visual Elements", items: [
                    "Domain 4 Pre-Assessment",
                    "Lesson 1 - Use Core Tools to Create Content",
                    "Lesson 2 - Add and Animate Text",
                    "Lesson 3 - Adjust Footage in Compositions",
                    "Lesson 4 - Modify and Manipulate Digital Video",
                    "Lesson 5 - Apply and Adjust Effects",
                    "Lesson 6 - Create Composites",
                    "Lesson 7 - Apply and Modify Effects and Presets",
                    "Lesson 8 - Create and Modify Keyframes",
                    "Domain 4 Post-Assessment",
                ]
            },
            {
                title: "Domain 5: Publishing Digital Media", items: [
                    "Domain 5 Pre-Assessment",
                    "Lesson 1 - Prepare Compositions for Publishing",
                    "Lesson 2 - Export a Project",
                    "Domain 5 Post-Assessment",
                ]
            }
        ]
    },
    {
        course: "Adobe Animate",
        LK:true,
        data: [
            {
                title: "Domain 1: Working in the Animation Industry",
                LK:true,
                items: [
                    "Domain 1 Pre-Assessment"
                    , "Workbook and Support Files"
                    , "Lesson 1 - Purpose, Audience, and Communication; Copyright, Permissions, and Licensing"
                    , "Lesson 2 - Basic Design, Animation, and Interactive Media Principles"
                    , "Domain 1 Post-Assessment"
                ]
            },
            {
                title: "Domain 2: Project Setup and Interface",
                items: [
                    "Domain 2 Pre-Assessment"
                    , "Lesson 1 - Settings and Workspace"
                    , "Lesson 2 - Customize Workspaces"
                    , "Lesson 3 - Import and Manage Assets"
                    , "Lesson 4 - Colors, Swatches, Gradients, Presets"
                    , "Domain 2 Post-Assessment"
                ]
            },
            {
                title: "Domain 3: Organization of Documents", items: [
                    "Domain 3 Pre-Assessment",
                    "Lesson 1 - Manage Layers and Layer Visibility",
                    "Domain 3 Post-Assessment",
                ]
            },
            {
                title: "Domain 4: Create and Modify Multimedia Elements", items: [
                    "Domain 4 Pre-Assessment",
                    "Lesson 1 - Element Tools and Features",
                    "Lesson 2 - Add and Manipulate Text",
                    "Lesson 3 - Work with Selections",
                    "Lesson 4 - Manipulate Digital Graphics Part 1",
                    "Lesson 5 - Manipulate Digital Graphics Part 2",
                    "Lesson 6 - Effects, Graphic Styles, and Animations",
                    "Lesson 7 - Interactive and Dynamic Content",
                    "Lesson 8 - Audio and Video",
                    "Domain 4 Post-Assessment",
                ]
            },
            {
                title: "Domain 5: Publishing Digital Media", items: [
                    "Domain 5 Pre-Assessment",
                    " Lesson 1 - Use of Scenes",
                    "Lesson 2 - Export and Publish",
                    "Domain 5 Post-Assessment",
                ]
            }
        ]
    },
    {
        course: "Adobe Dream Waiver",
        data: [
            {
                title: "Domain 1 - Working in the Web Industry",
                LK:true,
                items: [
                    "Domain 1 Pre-Assessment"
                    , "Workbook and Support Files"
                    , " Lesson 1 - Purpose, Audience, Communication, and Permissions"
                    , "Lesson 2 - Key Technologies, Design Principles, and Best Practices"
                    , "Domain 1 Post-Assessment"
                ]
            },
            {
                title: "Domain 2: Project Setup and Interface",
                items: [
                    "Domain 2 Pre-Assessment",
                    "Lesson 1 - Create a New Site",
                    "Lesson 2 - Navigate, Organize, and Customize the Workspace",
                    "Lesson 3 - Non-Visible Design Tools and Managing Assets",
                    "Domain 2 Post-Assessment"
                ]
            },
            {
                title: "Domain 3 - Organizing Content on a Page", items: [
                    "Domain 3 Pre-Assessment",
                    " Lesson 1 - Page Structure and Design Concepts",
                    "Domain 3 Post-Assessment",
                ]
            },
            {
                title: "Domain 4 - Working with Code to Create and Modify Content", items: [
                    "Domain 4 Pre-Assessment",
                    "Lesson 1 - Using HTML",
                    "Lesson 2 - Table and Semantic Elements",
                    "Lesson 3 - Styling Through CSS",
                    "Lesson 4 - Interactivity Using Javascript",
                    "Domain 4 Post-Assessment",
                ]
            },
            {
                title: "Domain 5: Publishing Digital Media", items: [
                    "Domain 5 Pre-Assessment",
                    "Lesson 1 - Prepare Project and Publish Site",
                    "Domain 5 Post-Assessment",
                ]
            }
        ]
    },
    {
        course: "Adobe Illustrator",
        data: [
            {
                title: "Domain 1: Working in the Animation Industry",
                LK:true,
                items: [
                    "Domain 1 Pre-Assessment"
                    , "Workbook and Support Files"
                    , "Lesson 1 - Purpose, Audience, and Communication; Copyright, Permissions, and Licensing"
                    , "Lesson 2 - Basic Design, Animation, and Interactive Media Principles"
                    , "Domain 1 Post-Assessment"
                ]
            },
            {
                title: "Domain 2: Project Setup and Interface",
                items: [
                    "Domain 2 Pre-Assessment"
                    , " Lesson 1 - Document Creation and Workspaces"
                    , "Lesson 2 - Non-Printing Tools and Import Assets"
                    , "Lesson 3 - Colors, Swatches, and Gradients"
                    , "Lesson 4 - Brushes, Symbols, Styles, and Patterns"
                    , "Domain 2 Post-Assessment"
                ]
            },
            {
                title: "Domain 3: Organization of Documents", items: [
                    "Domain 3 Pre-Assessment",
                    "Lesson 1 - Layers",
                    "Lesson 2 - Layer Visibility",
                    "Domain 3 Post-Assessment",
                ]
            },
            {
                title: "Domain 4: Create and Modify Multimedia Elements", items: [
                    "Domain 4 Pre-Assessment",
                    "Lesson 1 - Create Visual Elements",
                    "Lesson 2 - Add and Manipulate Text",
                    "Lesson 3 - Manage Selections and Transform Digital Graphics",
                    "Lesson 4 - Reconstruction and Editing Techniques",
                    "Lesson 5 - Effects and Graphic Styles",
                    "Domain 4 Post-Assessment",
                ]
            },
            {
                title: "Domain 5: Publishing Digital Media", items: [
                    "Domain 5 Pre-Assessment",
                    "Lesson 1 - Prepare Images for Export and Save Digital Images",
                    "Domain 5 Post-Assessment",
                ]
            }
        ]
    },
    {
        course: "Adobe InDesign",
        LK:true,
        data: [
            {
                title: "Domain 1: Working in the Animation Industry",
                LK:true,
                items: [
                    "Domain 1 Pre-Assessment"
                    , "Workbook and Support Files"
                    , "Lesson 1 - Purpose, Audience, and Communication; Copyright, Permissions, and Licensing"
                    , "Lesson 2 - Key Terminology and Basic Design Principles"
                    , "Domain 1 Post-Assessment"
                ]
            },
            {
                title: "Domain 2: Project Setup and Interface",
                items: [
                    "Domain 2 Pre-Assessment"
                    , " Lesson 1 - Document Creation and Workspaces"
                    , "Lesson 2 - Non-Printing Tools, Importing Assets, Colors, Swatches, Gradients, and Manage Styles"
                    , "Domain 2 Post-Assessment"
                ]
            },
            {
                title: "Domain 3: Organization of Documents", items: [
                    "Domain 3 Pre-Assessment",
                    "Lesson 1 - Layers",
                    "Lesson 2 - Pages",
                    "Domain 3 Post-Assessment",
                ]
            },
            {
                title: "Domain 4: Create and Modify Multimedia Elements", items: [
                    "Domain 4 Pre-Assessment",
                    "Lesson 1 - Lay Out Visual Elements",
                    "Lesson 2 - Add and Manipulate Text",
                    "Lesson 3 - Manage Selections",
                    "Lesson 4 - Effects and Styles, Interactive and Dynamic Content, Create and Edit Tables",
                    "Domain 4 Post-Assessment",
                ]
            },
            {
                title: "Domain 5: Publishing Digital Media", items: [
                    "Domain 5 Pre-Assessment",
                    "Lesson 1 - Prepare Documents for Publishing",
                    "Domain 5 Post-Assessment",
                ]
            }
        ]
    },
    {
        course: "Adobe Photoshop",
        LK:true,
        data: [
            {
                title: "Domain 1: Working in the Animation Industry",
                LK:true,
                items: [
                    "Domain 1 Pre-Assessment"
                    , "Lesson 1 - Purpose, Audience, and Communication; Copyright, Permissions, and Licensing"
                    , "Lesson 2 - Digital Image Terms and Basic Design Principles"
                    , "Domain 1 Post-Assessment"
                ]
            },
            {
                title: "Domain 2: Project Setup and Interface",
                items: [
                    "Domain 2 Pre-Assessment"
                    , " Lesson 1 - Document Creation and Workspaces"
                    , "Lesson 2 - Non-Printing Tools, Importing Assets, Colors, Swatches, Gradients, and Manage Styles"
                    , "Domain 2 Post-Assessment"
                ]
            },
            {
                title: "Domain 3: Organization of Documents", items: [
                    "Domain 3 Pre-Assessment",
                    "Lesson 1 - Layers",
                    "Lesson 2 - Layer Visibility; Destructive and Nondestructive Editing",
                    "Domain 3 Post-Assessment",
                ]
            },
            {
                title: "Domain 4: Create and Modify Multimedia Elements", items: [
                    "Domain 4 Pre-Assessment",
                    "Lesson 1 - Core Tools, Add and Manipulate Text",
                    "Lesson 2 - Manage Selections",
                    "Lesson 3 - Reconstruction Techniques, Appearance Modification",
                    "Domain 4 Post-Assessment",
                ]
            },
            {
                title: "Domain 5: Publishing Digital Media", items: [
                    "Domain 5 Pre-Assessment",
                    "Lesson 1 - Prepare Images for Export and Save Digital Images",
                    "Domain 5 Post-Assessment",
                ]
            }
        ]
    },
    {
        course: "Adobe Premier Pro",
        LK:true,
        data: [
            {
                title: "Domain 1 - Working in the Video Industry",
                LK:true,
                items: [
                    "Domain 1 Pre-Assessment",
                    "Workbook and Support Files"
                    , "Lesson 1 - Purpose, Audience, and Communication"
                    , "Lesson 2 - Copyright, Permissions, Licensing, and Key Terms"
                    , "Lesson 3 - Design Principles and Best Practices"
                    , "Domain 1 Post-Assessment"
                ]
            },
            {
                title: "Domain 2 - Project Setup and Interface",
                items: [
                    "Domain 2 Pre-Assessment"
                    , "Lesson 1 - Project Settings and Workspaces"
                    , "Lesson 2 - Workspace",
                    "Lesson 3 - Non-Visible Design Tools",
                    "Lesson 4 - Import Assets"
                    , "Domain 2 Post-Assessment"
                ]
            },
            {
                title: "Domain 3: Organization of Documents", items: [
                    "Domain 3 Pre-Assessment",
                    "Lesson 1 - Timeline Panel and Track Visibility",
                    "Domain 3 Post-Assessment",
                ]
            },
            {
                title: "Domain 4: Create and Modify Multimedia Elements", items: [
                    "Domain 4 Pre-Assessment",
                    "Lesson 1 - Core Editing Tools",
                    "Lesson 2 - Titles and Trim Footage",
                    "Lesson 3 - Transform Digital Media and Basic Editing Techniques",
                    "Lesson 4 - Effect Controls and Manage Audio",
                ]
            },
            {
                title: "Domain 5: Publishing Digital Media", items: [
                    "Domain 5 Pre-Assessment",
                    "Lesson 1 - Prepare Video and Audio for Publishing",
                    "Domain 5 Post-Assessment",
                ]
            }
        ]
    },
    {
        course: 'Cloud Computing', data: [
            {
                title: "Module 1: Getting started with Microsoft Azure",
                items: [
                    "What is cloud computing?",
                    "What is Azure?",
                    "Managing Azure",
                    "Subscription management, support, and billing",]
            },
            {
                title: "Module 2: Microsoft Azure management tools",
                items: [
                    "What is Azure PowerShell?",
                    "Azure SDK and Azure CLI",
                    "Using the Azure PowerShell modules",
                    "Using the Azure CLI",
                ]
            },
            {
                title: "Module 3 : Virtual Machines in Microsoft Azure",
                items: [
                    "Creating and configuring VMs",
                    "Configuring disks",
                    "Create a VM from the Azure portal by using an Azure Marketplace image",
                    "Verify the functionality of the VM",
                ]
            },
            {
                title: "Module 4: Web apps and cloud services",
                items: [
                    "Creating and configuring web apps",
                    "Deploying and monitoring web apps",
                    "Creating and deploying PaaS cloud services",
                    "Creating and configuring a WordPress web app",
                ]
            },
            {
                title: "Module 5: Creating and configuring virtual networks",
                items: [
                    "Getting started with virtual networks",
                    "Configuring Azure networking",
                    "Getting started with Azure Load Balancer",
                    "Creating virtual networks",
                ]
            },
            {
                title: "Module 6: Cloud storage",
                items: [
                    "Understanding cloud storage",
                    "Create and manage storage",
                    "Create an Azure Storage account",
                    "Create and manage blobs",
                ]
            },
            {
                title: "Module 7: Creating and managing Azure AD",
                items: [
                    "Overview of Azure AD",
                    "Manage Azure AD authentication",
                    "Create users in Azure AD",
                    "Create a new Azure AD tenant and a custom DNS domain",]
            },
            {
                title: "Modules of programs",
                items: [
                    "Introductory Class, Intro to Cloud Computing",
                    "Virtualization, Installation of VM Wore and Creation of Virtual Machine",
                    "Azure Subscription, Azure Basics",
                    "Azure Portal, Azure Region & amp Data Centre, Azure Services",
                    "Azure VM, VM Creation lamp Connection, Compute Services",
                    "Azure App Service, Server less Computing, Azure Storage",
                    "Storage Exploring, Azure Virtual Networking",
                    "Azure Identity Service, SecurityServices",
                    "Guidance for Mini Project"]
            }
        ]
    },
    {
        course: 'Cybersecurity', data: [
            {
                title: "Module 1: Understanding Security Layers",
                items: [
                    "Core Security Principles",
                    "Social Engineering",
                    "Man-In-The-Middle Attacks",
                    "Microsoft Baseline Security Analyzer",]
            },
            {
                title: "Module 2: Understanding Authentication, Authorization, and Accounting",
                items: [
                    "Overview of Internet Security",
                    "User Authentication",
                    "NTFS Permissions",
                    "Shares And Permissions In Action",
                ]
            },
            {
                title: "Module 3: Understanding Security Policies",
                items: [
                    "Audit Policies",
                    "Create a VM from the Azure portal by using an Azure Marketplace image",
                    "Verify the functionality of the VM",
                ]
            },
            {
                title: "Module 4: Understanding Network Security",
                items: [
                    "Network Access Protection",
                    "A real world example of VLANs and DMZ",
                    "Why Do We Need A Firewall?",
                    "Malware And What You Can Do About It",
                ]
            },
            {
                title: "Module 5: Protecting the Server and Client",
                items: [
                    "Introduction to IPSec",
                    "Names Resolution & DNS concepts",
                    "How to use Wireshark to capture network traffic",
                    "Client Protection",
                ]
            }
        ]
    },
    {
        course: 'Data Science', data: [
            {
                title: "Introduction to R programming language",
                items: [
                    "Data Frame",
                    "Variables",
                    "Reading Data",
                    "Functions",
                    "Histograms",]
            },
            {
                title: "Probability and Visualization using R",
                items: [
                    "Generating Random Numbers",
                    "Density and Cumulative Distributive Functions",
                    "Comparing Distributions",
                ]
            },
            {
                title: "Regression",
                items: [
                    "Linear Regression",
                    "Generalized Linear Models",
                    "Polynomial Regression",
                    "Non-Linear Regression",
                ]
            },
            {
                title: "Statistical Procedures for Advanced Analytics",
                items: [
                    "Introduction to Tree based Models",
                    "Tree Error Rates",
                    "Random Forests",
                ]
            },
            {
                title: "Introduction to Big Data",
                items: [
                    "Introduction to Apache Kafka",
                    "Using RapidMiner",
                    "Hadoop for Big Data",
                ]
            }
        ]
    },
    {
        course: 'Data Structure', data: [
            {
                title: "C++ Basics (with code implementation)"
            },
            {
                title: "Containers (Standard Template Library)"
            },
            {
                title: " Arrays"
            },
            {
                title: "Hashing"
            },
            {
                title: "Recursion"
            },
            {
                title: "Linked Lists"
            },
            {
                title: "Trees"
            },
            {
                title: "Binary Search"
            },
            {
                title: "Stacks and Queues"
            },
            {
                title: "Heaps"
            },
            {
                title: "Greedy Algorithms"
            },
            {
                title: " Bit Manipulation"
            },
            {
                title: "Dynamic Programming"
            },
            {
                title: "Graphs"
            },
            {
                title: "Live Sessions"
            },
            {
                title: "Extra Practice Questions and Solutions"
            }
        ]
    },
    {
        course: 'Deep Learning', data: [
            {
                title: "Deep Learning Using Tensorflow",
                items: [
                    "Created by the Google Brain team, TensorFlow is an open source library for numerical computation and large-scale machine learning. TensorFlow bundles together a slew of machine learning and deep learning (aka neural networking) models and algorithms and makes them useful by way of a common metaphor. TensorFlow is the most famous library used in production for deep learning models.",]
            },
            {
                title: "Introduction and use with normal ml models",
                items: [
                    "Machine learning uses algorithms to parse data, learn from that data, and make informed decisions based on what it has learned. Deep learning structures algorithms in layers to create an “artificial neural network” that can learn and make intelligent decisions on its own.",
                ]
            },
            {
                title: "Artificial Neural Network (Perceptron)",
                items: [
                    "In machine learning, the perceptron is an algorithm for supervised learning of binary classifiers. A binary classifier is a function which can decide whether or not an input, represented by a vector of numbers, belongs to some specific class.",
                ]
            },
            {
                title: "CNN",
                items: [
                    "In deep learning, a convolutional neural network (CNN, or ConvNet) is a class of deep neural networks, most commonly applied to analyzing visual imagery. CNNs use a variation of multilayer perceptrons designed to require minimal pre processing.",
                ]
            },
            {
                title: "RNN",
                items: [
                    "A recurrent neural network (RNN) is a class of artificial neural network where connections between nodes form a directed graph along a sequence. This allows it to exhibit temporal dynamic behavior for a time sequence. Unlike feed forward neural networks, RNNs can use their internal state (memory) to process sequences of inputs.",
                ]
            },
            {
                title: "RBMs",
                items: [
                    "A restricted Boltzmann machine (RBM) is a generative stochastic artificial neural network that can learn a probability distribution over its set of inputs. RBMs were initially invented under the name Harmonium by Paul Smolensky in 1986, and rose to prominence after Geoffrey Hinton and collaborators invented fast learning algorithms for them in the mid-2000.",
                ]
            },
            {
                title: "Autoencoders",
                items: [
                    "An autoencoder is a neural network that has three layers: an input layer, a hidden (encoding) layer, and a decoding layer. The network is trained to reconstruct its inputs, which forces the hidden layer to try to learn good representations of the inputs.",
                ]
            }
        ]
    },
    {
        course: 'Java', data: [

            {
                title: "STRING METHODS, RECURSION & INTRODUCTION TO COLLECTIONS"
            },
            {
                title: "ARRAYLIST, LINKEDLIST & CONVERTING LISTS TO ARRAYS"
            },
            {
                title: "COLLECTIONS METHOD SORT, REVERSE AND COPY & FILL"
            },
            {
                title: "FREQUENCY, DISJOINT, STACKS, PUSH, POP, QUEUE & HASHSET"
            },
            {
                title: "GENERIC METHODS & INTRODUCTION TO APPLETS"
            },
            {
                title: "APPLETS"
            },
            {
                title: "THREADS & BEGINNING NETWORKING"
            },
            {
                title: "NETWORKING APPLET & USER EVENTS"
            },
            {
                title: "APPLET, GUI & LOADCRAP"
            }
        ]
    },
    {
        course: 'Network Fundamentals', data: [

            {
                title: "NETWORK FUNDAMENTALS"
            },
            {
                title: "NETWORK SCOPE AND MODEL"
            },
            {
                title: "NETWORK CABLES"
            },
            {
                title: "NETWORK TOPOLOGIES"
            },
            {
                title: "WIRELESS NETWORK, STANDARDS, SECURITY"
            },
            {
                title: "NIC, SWITCH AND VLAN"
            },
            {
                title: "ISDN, DSL"
            },
            {
                title: "NETWORKING APPLET & USER EVENTS"
            },
            {
                title: "INTERNET PROXY AND NAT"
            },
            {
                title: "NETWORK PROTOCOLS AND TCP/IP"
            },
            {
                title: "IP ADDRESSING"
            },
            {
                title: "IP SUBNETTING"
            },
            {
                title: "GATEWAY AND ROUTING"
            },
            {
                title: "STATIC AND DYNAMIC ROUTING"
            },
            {
                title: "OSI MODEL"
            },
            {
                title: "WAN TECHNOLOGIES AND PROTOCOLS"
            },
            {
                title: "WAN SECURITY"
            },
            {
                title: "WHAT IS INTERNET?"
            },
            {
                title: "BROWSER GOOGLE CHROME"
            },
            {
                title: "EMAIL PROTOCOLS AND CLIENTS"
            },
            {
                title: " DNS AND RESOURCE RECORDS"
            },
            {
                title: "NETWORK COMMAND LINE UTILITIES"
            },
            {
                title: " WINDOWS WORKGROUP AND ICS"
            },
            {
                title: "NETWORK SECURITY"
            },
            {
                title: "CONFIGURING WINDOWS SOFTWARE FIREWALL"
            },
            {
                title: "FILTERING USING LINUX IPTABLES"
            },
            {
                title: "STORAGE AREA NETWORK"
            }
        ]
    },
    {
        course: 'Python', data: [
            {
                title: "Perform data operations using Data Types and Operators"
            },
            {
                title: "Control Flow with Decision and Loops"
            },
            {
                title: "Perform Input and Output Operations"
            },
            {
                title: "Document and Structure Code"
            },
            {
                title: "Perform Troubleshooting and Error Handling"
            },
            {
                title: "Perform Operations using Modules and Tools"
            }
        ]
    },
    {
        course: 'React', data: [
            {
                title: "JavaScript Recap",
                items: [
                    "NPM and node.js",
                    "Why React.js?",
                    "Difference between Angular and React and Vue?",
                ]
            },
            {
                title: "What is React.js?",
                items: [
                    "Setup and Efficient rendering with React",
                    "Intro to JSX and Babel",
                    "Pure React",
                    "Refactoring elements using JSX",
                    "Babel inline transpiling",
                    "Babel static transpiling with babel-cli",
                    "Building with webpack",
                    "Loading JSON with webpack",
                    "Adding CSS to webpack build",
                    "Migrating to webpack 3",
                ]
            },
            {
                title: "React Components",
                items: [
                    "Planning an ActivityCounter",
                    "Creating components with createClass()",
                    "Adding component properties",
                    "Adding component methods",
                    "Creating components with ES6 class syntax",
                    "Creating stateless functional components",
                    "Adding react-icons",
                ]
            },
            {
                title: "React States & Route",
                items: [
                    "Props and State",
                    "Composing components",
                    "Displaying child components",
                    "Default props",
                    "Validating with React.PropTypes",
                    "Custom validation",
                    "Working with state",
                    "Passing state as props",
                    "State with ES6 classes",
                    "Using the React Router",
                    "Incorporating the router",
                    "Setting up routes",
                    "Navigating with the link component",
                    "Using route parameters",
                    "Nesting routes",
                ]
            },
            {
                title: "React Forms",
                items: [
                    "Forms and Refs",
                    "Creating a form component",
                    "Using refs in class components",
                    "Using refs in stateless components",
                    "Two-way function binding",
                    "Adding an auto complete component",
                ]
            },
            {
                title: "React Components Lifecycle",
                items: [
                    "The Component Lifecycle",
                    "Challenge: Building the Member component",
                    "Challenge: Building the MemberList component",
                    "Understanding the mounting lifecycle",
                    "Understanding the updating lifecycle",
                ]
            },
            {
                title: "Miscellaneous",
                items: [
                    "Docker Introduction",
                    "Semantic UI React",
                    "D3 Charts",
                    "React with Redux",
                    "React Tables",
                ]
            }
        ]
    },
    {
        course: 'UI', data: [
            {
                title: "UI Development",
                items: [
                    "KNOWING THE UI OF DREAMWEAVER PART 1",
                    "KNOWING THE UI OF DREAMWEAVER PART 2",
                    "UNDERSTANDING BASIC HTML TAGS AND CODE STRUCTURE",
                    "UNDERSTANDING THE WEBSITE UI DESIGN STRUCTURE",
                    "CSS CASCADING STYLE SHEETS",
                    "THE DIFFERENT TYPES OF CSS",
                    "CSS PROPERTIES USED IN PRODUCTION",
                    "KNOWING THE CSS STYLES",
                    "CSS FORM",
                    "INTRO TO HTML5 AND NEW HTML5 TAGS LIST",
                    "NEW ADDITION OF HTML5 – PART 1",
                    "NEW ADDITION OF HTML5 – PART 2",
                    "MANIFEST FILE FOR CHAPTER 12",
                    "CREATING WEBPAGE",
                    "CSS3",
                    "CSS3 MODULES",
                    "CSS3 MEDIA QUERIES",
                    "RESPONSIVE WEB DESIGN",
                    "JSON",
                    "JAVASCRIPT",
                    "JQUERY",
                    "IDEA OF FUNCTIONS JQUERY",
                    "JQUERY UI",
                    "JQUERY EXAMPLE",
                    "MEASUREMENT UNITS",
                    "FULL WEB PAGE DESIGN",
                ]
            }
        ]
    },
    {
        course: 'Web', data: [
            {
                title: "Intro to HTML5",
                items: [
                    "How websites functions",
                    "HTML Tags",
                    "DOM Structures",
                ]
            },
            {
                title: "Intro to CSS3",
                items: [
                    "Styling with CSS elements",
                    "Advanced Styling — The Box Model",
                    "CSS classes",
                    "Positioning it all together ",
                ]
            },
            {
                title: "Website Hosting",
                items: [
                    "Make your website live",
                ]
            },
            {
                title: "Intro to JavaScript",
                items: [
                    "Fundamental Syntax — DataTypes, Variables, Conditionals, Loops, Functions",
                    "Objects and classes",
                    "Make Your website Dynamic with JavaScript",
                ]
            },
            {
                title: "Front End Web UI Frameworks andTools",
                items: [
                    "BootStrap",
                    "CSS Preprocessors",
                    "Building and deploying web projects — NPM/Grunt/Gulp",
                ]
            },
            {
                title: "Server Side Development",
                items: [
                    "CRUD Basics",
                    "Intro to technologies like Django and NodeJS",
                    "Intro to building REST APIs using Django",
                    "Data Storage with MySQL",
                    "Fetch information on your website through",
                    "REST APIs — Interaction between Frontend and Backendt",
                ]
            },
        ]
    },
    {
        course: 'App Development', data: [
            {
                title: "Android: History and Introduction",
                items: [
                    "Java Concepts",
                    "What is Android",
                    "Android History",
                    "Android Versions",
                    "Platform and Architecture",
                    "Android Market",
                ]
            },
            {
                title: "Android Studio, Gradle and ADB Tools",
                items: [
                    "Development Tools",
                    "Working with Android Studio",
                    "Understanding Gradle",
                    "Project Dependencies",
                    "ADB",
                    "Build and Run your App",
                    "Generate APKs",
                    "Instant Run",
                ]
            },
            {
                title: "Activity , UI basics and Intents",
                items: [
                    "Core Building Blocks",
                    "Activity in Android",
                    "Layouts and ViewGroups",
                    "Layout Attributes",
                    "Resources",
                    "Languages and Local Support",
                    "Custom Views",
                    "Making Apps more Accessible",
                    "Runtime configuration changes",
                    "Events and Listeners",
                    "Intents",
                    "Synthesizing Task Stacks",
                ]
            },
            {
                title: "Advanced UI : Style, Themes, Material UI and Menus",
                items: [
                    "Working with Styles and Themes",
                    "Introduction to Material Design",
                    "Material Themes",
                    "Cards",
                    "Elevation and shadow",
                    "Menus",
                ]
            },
            {
                title: "Android Threading:AsyncTask, Loaders, Handler",
                items: [
                    "Android Threading Model",
                    "Processes and Lifecycle",
                    "AsyncTask",
                    "Loaders",
                    "Handlers",
                ]
            },
            {
                title: "Fragment and Advanced Views",
                items: [
                    "Fragments",
                    "ListView",
                    "RecyclerView",
                    "CoordinatorLayout",
                    "TabLayout",
                    "Snackbar",
                    "More Advanced Views",
                ]
            },
            {
                title: "Service, Notification and Broadcast Receiver",
                items: [
                    "Services",
                    "Intent Service",
                    "Notifications",
                    "Broadcast Receivers",
                    "Pending Intents",
                ]
            },
            {
                title: "Content Provider and Job Scheduler",
                items: [
                    "Content Provider",
                    "Content Observer",
                    "Job Scheduler",
                    "Adding support Libraries",
                ]
            },
            {
                title: "SQlite Database and Networking",
                items: [
                    "Working with Sqlite database",
                    "CRUD Operations",
                    "Cursors",
                    "SQLite Transaction",
                    "Working with Assets",
                    "Networking in Android",
                ]
            },
            {
                title: "Android Storage System",
                items: [
                    "SharedPreferences",
                    "“Listening ” to preference changes",
                    "Internal Storage",
                    "External Storage",
                ]
            },
            {
                title: "Application Widgets and Working with Google Map",
                items: [
                    "Adding widget to user screen",
                    "Designing the widget",
                    "Updating widget",
                    "Working with google Map API",
                    "Markers on Map",
                    "Drawing on Map",
                    "UI Controls and Listeners for Google Map API",
                ]
            },
            {
                title: "Testing, Debugging and Deploying",
                items: [
                    "Testing in Android",
                    "Unit Testing",
                    "Instrumentation Testing",
                    "Libraries for Testing",
                    "Understanding LogCat",
                    "Debugging",
                    "Stacktraces and StrictMode",
                    "Publishing application to Play Store",
                ]
            }
        ]
    },
]

export default data;