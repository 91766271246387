import React from 'react';
import "./About.scss";
function ChoiceCard({image, header, text}) {
  return (
  <div className='about-chose-container-card'>
    <img src={image} alt="Choice Card" className="about-chose-container-card-image"/>
    <div className="about-chose-container-card-header"> {header.toUpperCase()} </div>
    <p className="about-chose-container-card-text"> {text} </p>
  </div>
  );
}

export default ChoiceCard;
