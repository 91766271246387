import React, { useState } from "react";

//Components
import Button from "../../../../../components/botton/button";
import Modal from "../../../../components/Modal/Modal";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { useParams } from "react-router-dom";

// APIs
import { saveExamLink } from "../../../../helpers/adminapicall";

const ExamOverview = ({ bookings, month, year }) => {
  const [examLink, setExamLink] = useState("");
  const { examId } = useParams();

  const [showModal, setShowModal] = useState(false);
  console.log(bookings);
  const handleSave = () => {
    let examDate = `${year}-${month}-25`;
    saveExamLink(examLink, examDate, examId);
  };

  const handleCancleExam = () => {};

  const [reason, setReason] = useState("");
  const [checked, setChecked] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <div className="overview-container">
        {/* Input */}
        <input
          className="overview-search-input"
          value={examLink}
          onChange={(e) => setExamLink(e.target.value)}
          type="text"
          // eslint-disable-next-line react/jsx-no-duplicate-props
          className="overview-search-input"
          placeholder="https://www/google.co.in/"
        />

        <div className="overview-checkbox-container">
          <div className="overview-checkbox-item">
            <span>Exam Link</span>
            <hr className="horizontal-line" />
            <div class="round-checkbox">
              <input type="checkbox" id="checkbox-certificate-link" />
              <label for="checkbox-certificate-link"></label>
            </div>
          </div>
          <div className="overview-checkbox-item">
            <span>Results</span>
            <hr className="horizontal-line" />
            <div class="round-checkbox">
              <input type="checkbox" id="checkbox-lms" />
              <label for="checkbox-lms"></label>
            </div>
          </div>
          <div className="overview-checkbox-item">
            <span>Certificates</span>
            <hr className="horizontal-line" />
            <div class="round-checkbox">
              <input type="checkbox" id="checkbox-certification-page" />
              <label for="checkbox-certification-page"></label>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Button type="secondary" text="SAVE CHANGES" onClick={handleSave} />
          <Button
            type="danger"
            text="CANCEL EXAM"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </div>
      </div>
      <Modal
        title="My Modal"
        onClose={() => setShowModal(false)}
        show={showModal}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            Are you sure you want to cancel{" "}
            <span className="text-accent-2">month_here</span> Exam ?{" "}
          </div>
          <div>
            <Switch
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span className="text-medium">
              Postpone the existing exam bookings to next month
            </span>
          </div>
          <TextField
            id="outlined-multiline-static"
            label="Reason for cancellation"
            multiline
            rows={4}
            style={{ width: "100%" }}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          <span className="text-secondary-2">
            &#9432; The reason entered here will be sent to the user.
          </span>
          <Button
            type="primary"
            onClick={handleCancleExam}
            text="YES, CANCEL"
          />
        </div>
      </Modal>
    </div>
  );
};

export default ExamOverview;
