
import React from 'react'
import Microsoft from "./Microsoft/Microsoft"
import HomePage from './HomePage/HomePage'
import HowDoesItWork from './HowDoesItWork/HowDoesItWork'
import Suitable from './Suitable/Suitable'
import KeyHighlights from './KeyHighlights/KeyHighlights';
import Testimonial from '../../homepage/Testimonial/Testimonial'
import Header from '../../../components/layout/Header/header'
import Footer from '../../../components/layout/Footer/Footer'

import OurNetwork from "../../../components/OurNetwork/OurNetwork";
import Form from './Form/Form'
import Program from "../../Program/Program"

const Course = () => {
    return (
        <>
            <div>
                <Header />
                <HomePage />
                <KeyHighlights />
                <Microsoft />
                <HowDoesItWork />
                <Suitable />
                {/* <Program isIntensive={false} courseId={'C31'} /> */}
                <Testimonial />
                <Form />
                <OurNetwork />
                <Footer />
            </div>
        </>
    )
}
export default Course

