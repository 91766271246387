import React, { useState, useEffect } from "react";
import "./Jobs.scss";
import { ReactComponent as DownArrow } from "../../assests/Graphics/Icons/DownArrow.svg";
// import Button from "../../components/botton/button";
import { DropMenu } from "./DropMenu/DropMenu";
import Header from "../../components/layout/Header/header";
import Footer from "../../components/layout/Footer/Footer";
import { useHistory } from "react-router";
import axios from "axios";
import { BlogCard, BlogCard1, BlogCard2 } from '../Jobs/BlogCard/BlogCard';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Container from "@mui/material/Container"
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import UploadResume from './Stepper/UploadResume'
import AddJob from './Stepper/AddJob'
import ResumeChecker from './Stepper/ResumeChecker'

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

export const ResumePage = () => {

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [selectedFile, setSelectedFile] = useState();
  const [desc, setDesc] = useState('');
  const [result, setResult] = useState([]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setActiveStep(0);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  let finalResult
  const handleSubmission = () => {
    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('jd', desc);

    fetch(
      // 'https://www.back.foxmula.com/parse',
      '/parse',
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        setResult(result)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      })
      .catch((error) => {
        alert('Error:', error);
      });
  };


  const selectPage = (activeStep) => {
    switch (activeStep) {
      case 0: return <UploadResume setActiveStep={setActiveStep} setSelectedFile={setSelectedFile} />
      case 1: return <AddJob desc={desc} setDesc={setDesc} handleSubmission={handleSubmission} result={result} />
      case 2: return <ResumeChecker result={result} />
      default: return <UploadResume setActiveStep={setActiveStep} setSelectedFile={setSelectedFile} />
    }
  }


  return <>
    <Container>
      <Box sx={{ width: '100%', my: 15, }}>
        <center>
          <Stepper
            sx={{ maxWidth: "50vw", minWidth: "300px", m: 5 }} activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}></StepLabel>
                </Step>
              );
            })}
          </Stepper>

        </center>
        <>
          {selectPage(activeStep)}
          {/* {
            activeStep !== steps.length - 1 &&
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          } */}
        </>
      </Box>
    </Container>
  </>

}


const Jobs = () => {

  const locationData = ["All"]
  const history = useHistory();
  const [location, setLocation] = useState(locationData[0]);
  const [jobSearchInput, setjobSearchInput] = useState("");
  const [jobList, setjobList] = useState([]);

  const handleLocation = () => {
    const newLocation = { ...location };
    newLocation.expanded = !newLocation.expanded;
    setLocation(newLocation);
  };

  const handleSelection = (el) => {
    const newLocation = { ...location };
    newLocation.expanded = !newLocation.expanded;
    newLocation.name = el;
    setLocation(newLocation);
  };

  const getJobList = () => {
    axios
      // .get(`https://www.back.foxmula.com/jobs`)
      .get(`/jobs`)
      .then((res) => {
        setjobList(res.data.jobs);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getJobList();
  }, []);

  const handleSearch = () => {
    //console.log("in handleSearch");
    let urlWithParam =
      "/job/search/" + (jobSearchInput ? jobSearchInput : "All") + "/" + location + "/";
    //console.log(urlWithParam);
    history.push(urlWithParam);
  };

  const searchInputHandler = (e) => {
    setjobSearchInput(e.target.value);
  };

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };





  // const isStepOptional = (step) => {
  //   return step === 1;
  // };





  return (
    <div className="jobs">
      <div className="jobs-header">
        <Header />
        <Grid container justifyContent="center" className="jobs-header-contentt">
          <Grid spacing={5} container item justifyContent="center" className="jobs-header-content-inputs">
            <Grid xs={11} item sm={12} md={6} >
              <TextField
                className="jobs-text"
                id="outlined-basic"
                placeholder="Search jobs by title, company or job function"
                label=" Search jobs by title, company or job function  "
                onChange={(event) => searchInputHandler(event)}
              />
            </Grid>
            <Grid item xs={11} sm={12} md={4} container sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Location</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className="jobs-text"
                  label="Location"
                  value={location}
                  disabled
                  helperText="Some important teasdasdxt"
                  onChange={handleChangeLocation}
                >
                  {
                    locationData.map((loc) => (<MenuItem value={loc}>{loc}</MenuItem>))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} />
          </Grid>
          <Grid container justifyContent="center" >
            <Button className="jobs-search-button" variant="contained" size="large" onClick={handleSearch}> Search </Button>
          </Grid>
        </Grid>
      </div>
      <ResumePage />
      <Footer />
    </div >
  );
};

export default Jobs;




// <div className="jobs-cards">
// <BlogCard />
// <BlogCard1 />
// <BlogCard2 />
// </div>