import React, { useEffect, useState } from 'react';
import './SingleJob.scss';
import logo from '../../assests/images/logo.png';
import Button from '../../components/botton/button';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, Link, useParams } from 'react-router-dom'
import Header from "../../components/layout/Header/header";
import Footer from "../../components/layout/Footer/Footer";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    btnContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '&:nth-child()': {
            minWidth: 150,
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            flexDirection: 'row',
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            flexDirection: 'column',
        }
    },

    btnContainerSearch: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btn__width: {
        width: "137px",
        height: "44px",
        marginRight: "20px",
        [theme.breakpoints.down('sm')]: {
            margin: "10px 10px"
        }
    }
}))


export const SingleJob = () => {

    // get data from api given the id and store in in selectedJob
    const { id } = useParams()
    const [selectedJob, setSelectedJob] = useState({})


    useEffect(() => {
        console.log('useEffect:')

        axios
            // .get(`https://www.back.foxmula.com/job/${id}`)
            .get(`/job/${id}`)
            .then((res) => {
                const data = res.data.jobs
                setSelectedJob(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <>
            <Header />
            <div className="singlejob h-100">
                {
                    selectedJob
                        ? <Job info={selectedJob} />
                        : null
                }
            </div>
            <br />
            <Footer />
        </>

    )
}


export const Job = ({ info, search }) => {
    console.log('info:', info)
    const matches = useMediaQuery('(min-width:600px)');
    const classes = useStyles()
    const history = useHistory()
    const { _id, title, type, location, duration, applyBy, payRange, experience, education, preferredEducation, responsibility, skills, industry } = info


    return <>
        {
            info
                ? <Grid container className="job">

                    <Grid container direction="row"
                        justifyContent="space-between"
                        className="job-header">
                        <Grid xs={5} sm={3} md={2} spacing={1} item className="job-header-left">
                            <img src={logo} alt="Company Logo" />
                        </Grid>
                        <Grid xs={7} sm={6} md={10} spacing={1} item className="job-header-right">
                            <Grid direction="column" item className="job-header-right-meta">
                                <Grid item className="title text-bold pointer" >
                                    <Link to={`/singlejob/${_id}`}>{title}</Link> </Grid>
                                <Grid item className="type">{type}</Grid>
                                <Grid item className="location">{location}, India </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        !search
                            ? <Grid container direction={`${matches ? "row" : "column"}`} className="job-header-right-list ul-large">
                                <Grid item xs={2} sm={3}> <li>Duration - {duration} months</li></Grid >
                                <Grid item xs={2} sm={3}> <li>{industry}</li></Grid >
                                <Grid item xs={2} sm={3}> <li>Programming</li></Grid >
                                <Grid item xs={2} sm={3}> <li>{payRange} /mo </li></Grid >
                                <Grid item xs={2} sm={3}> <li>Apply by {new Date(applyBy).toLocaleDateString()} </li></Grid >
                                <Grid item xs={2} sm={3}> <li>Exp - {experience} months </li></Grid>
                            </Grid >
                            : null
                    }


                    {
                        search
                            ?
                            <div className="m-4">
                                <Grid container justifyContent={"center"}>
                                    <Grid item container className={classes.btnContainerSearch}>
                                        <Grid item container justifyContent={"center"} direction={`${!matches ? "row" : "column"}`} className="job-header-right-list ul-large">
                                            <Grid item xs={1} sm={2}> <li>Duration - {duration} months</li></Grid >
                                            <Grid item xs={1} sm={2}> <li>{industry}</li></Grid >
                                            <Grid item xs={1} sm={2}> <li>Programming</li></Grid >
                                            <Grid item xs={1} sm={2}> <li>{payRange} /mo </li></Grid >
                                            <Grid item xs={1} sm={2}> <li>Apply by {new Date(applyBy).toLocaleDateString()} </li></Grid >
                                            <Grid item xs={1} sm={2}> <li>Exp - {experience} months </li></Grid>
                                        </Grid >
                                        {/* <Button className={classes.btn__width} type="secondary" text="SAVE" /> */}
                                        <Button className={classes.btn__width} type="primary" text="APPLY" />
                                    </Grid>
                                </Grid>
                            </div>
                            : null
                    }

                    <Grid container className="job-desc" >
                        <Grid direction="column" container className="job-desc-section m-0">
                            <Grid item className="job-desc-section-title text-bold text-shade-black">
                                Education
                            </Grid>
                            <Grid item className="job-desc-section-text">
                                <Grid> {education} <br />
                                    ( {preferredEducation?.map((item) => (`${item} `))}  will be prefered)
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="column" className="job-desc-section">
                            <Grid item className="job-desc-section-title text-bold text-shade-black">
                                Job Description
                            </Grid>
                            <Grid item className="job-desc-section-text">
                                <div className='mb-1'>Your responsibilities</div>
                                {
                                    responsibility?.map((things) => (
                                        <Grid spacing={1} item ><li>{things}</li></Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                        <Grid container md={7} sm={12} xs={12} direction="column" className="job-desc-section">
                            <Grid item className="job-desc-section-title text-bold text-shade-black">
                                Skills
                            </Grid>
                            <Grid xs={4} sm={3} md={8} item direction="row" className="job-desc-section-tags">
                                {
                                    skills?.map((skill) => (
                                        <Tag text={skill} type="blue" />
                                    ))
                                }
                            </Grid>
                        </Grid>
                        {
                            !search
                                ? <>
                                    <Grid container className="job-desc-section">
                                        <Grid item className="job-desc-section-title text-bold text-shade-black">
                                            About the company
                                        </Grid>
                                        <Grid item className="job-desc-section-text">
                                            <p>
                                                "With Foxmula we aim to make a community where users feel at home, safe, satisfied and happy. Growing depression, learning breakdowns, lesser industry exposures among students that we see today should never have been the side outcomes of the learning and education process. We believe things can be changed for the better good, be it for students, graduates, professionals, and even corporations if learning, education, and workforce are perceived and approached a little differently."
                                            </p>
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.btnContainer}>
                                        {/* <Button className={classes.btn__width} type="secondary" text="SAVE" /> */}
                                        <Button className={classes.btn__width} type="primary" text="APPLY" />
                                    </Grid>
                                </>
                                : null
                        }
                    </Grid >
                </Grid >
                : (<div container className="job">
                    <center>Your selected job description goes here</center>
                </div>)
        }
    </>


}


const Tag = ({ text, type }) => {
    return (
        <div className={`tag ${type}-tag`}>{text}</div>
    )
}








