import React, { useState, useEffect } from "react";
import "./CertificateSection.scss";

//Components
import Button from "../../../../../components/botton/button";
import UploadMethodButton from "../../../../components/Utilities/UploadMethodButton/UploadMethodButton";

//Components for Upload Certificate Section
import SearchBar from "../../../../components/SearchBar/SearchBar";
import ListSelect from "../../../../../components/ListSelect/ListSelect";
import { Link } from "react-router-dom";
//Icons
import { ReactComponent as BackIcon } from "../../../../assets/icons/back-icon.svg";

const CertificateSection = ({ bookings, setBookings }) => {
  return (
    <div>
      <UploadSection />
      <UploadSuccess name="Certificate" />
      <UploadResultSection />
    </div>
  );
};

const UploadSection = () => {
  const [uploadMethod, setUploadMethod] = useState(null);

  // const toggleUploadMethod = () => {
  //   if (uploadMethod === "csv") {
  //     setUploadMethod("manual");
  //   } else if (uploadMethod === "manual") {
  //     setUploadMethod("csv");
  //   }
  // };

  // const handleUploadCSV = (e) => {
  //   e.preventDefault();
  //   if (uploadMethod === "csv") {
  //     //
  //   }
  // };
  // const handleManualCSV = (e) => {
  //   e.preventDefault();
  //   if (uploadMethod === "manual") {
  //     //
  //   }
  // };
  const handleProceed = (e) => {
    e.preventDefault();
  };

  return (
    <div
      style={{
        padding: "5rem 0",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span className="text-secondary-2">
        &#9432; The certificates have{" "}
        <span className="text-accent-2 text-bold">not</span> been uploaded
      </span>

      {/* Upload Buttons */}
      <div className="flex-align-start" style={{ gap: "2rem" }}>
        <UploadMethodButton
          setUploadMethod={setUploadMethod}
          uploadMethod={uploadMethod}
          type="manual"
        />
        <div className="flex-align-center flex-column" style={{ gap: "1rem" }}>
          <UploadMethodButton
            setUploadMethod={setUploadMethod}
            uploadMethod={uploadMethod}
            type="csv"
          />
          <div className="download-text">DOWNLOAD SAMPLE</div>
        </div>
      </div>
      <Button
        type={uploadMethod ? "primary" : "disabled"}
        text="PROCEED"
        onClick={handleProceed}
      />
    </div>
  );
};

const UploadSuccess = ({ name, data }) => {
  const handleView = (e) => {
    e.preventDefault();
  };

  return (
    <div
      style={{
        padding: "5rem 0",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span className="text-secondary-2">
        &#9432; {name} details uploaded !
      </span>
      <Button type="primary" text="VIEW / EDIT RESULTS" onClick={handleView} />
    </div>
  );
};

// Display Certificates

const UploadResultSection = ({ data = [] }) => {
  const [search, setSearch] = useState("");
  const [filterTries, setFilterTries] = useState("");
  const filterTriesOptions = ["1st", "2nd", "3rd"];

  data = [
    {
      name: "Kathryn Murphy",
      email: "randomemailid@gmail.com",
      phone: "9875543210",
      attempt: "1st",
      status: "passed",
      certificateNo: "4425QE",
    },
    {
      name: "Ram",
      email: "ram123@gmail.com",
      phone: "987614412",
      attempt: "3rd",
      status: "failed",
      certificateNo: "",
    },
    {
      name: "Shyam",
      email: "randomemailid@gmail.com",
      phone: "7546543210",
      attempt: "2nd",
      status: "absent",
      certificateNo: "fsd5h",
    },
    {
      name: "Raman",
      email: "randomemailid@gmail.com",
      phone: "98762343210",
      attempt: "1st",
      status: "",
      certificateNo: "",
    },
    {
      name: "Raghav",
      email: "randomemailid@gmail.com",
      phone: "98765432752",
      attempt: "2nd",
      status: "passed",
      certificateNo: "",
    },
    {
      name: "Rohan",
      email: "randomemailid@gmail.com",
      phone: "2346543210",
      attempt: "1st",
      status: "passed",
      certificateNo: "234gfeeee",
    },
    {
      name: "Laxaman",
      email: "randomemailid@gmail.com",
      phone: "9876556810",
      attempt: "3rd",
      status: "",
      certificateNo: "",
    },
    {
      name: "Zakhir",
      email: "randomemailid@gmail.com",
      phone: "9834543210",
      attempt: "1st",
      status: "",
      certificateNo: "rfge4",
    },
    {
      name: "Vijay",
      email: "randomemailid@gmail.com",
      phone: "9876587910",
      attempt: "2nd",
      status: "passed",
      certificateNo: "",
    },
    {
      name: "Ajay",
      email: "randomemailid@gmail.com",
      phone: "98764446210",
      attempt: "3rd",
      status: "passed",
      certificateNo: "",
    },
    {
      name: "Shivam",
      email: "randomemailid@gmail.com",
      phone: "9808943210",
      attempt: "1st",
      status: "passed",
      certificateNo: "",
    },
    {
      name: "Lucky",
      email: "randomemailid@gmail.com",
      phone: "9878643210",
      attempt: "2nd",
      status: "failed",
      certificateNo: "",
    },
  ];

  const [certificates, setCertificates] = useState([...data]);
  const [searchCertificates, setSearchCertificates] = useState([]);

  // Sort data on search
  useEffect(() => {
    if (search) {
      let filteredData = certificates.filter((item) => {
        return (
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase()) ||
          item.phone.toLowerCase().includes(search.toLowerCase()) ||
          item.attempt.toLowerCase().includes(search.toLowerCase()) ||
          item.status.toLowerCase().includes(search.toLowerCase()) ||
          item.certificateNo.toLowerCase().includes(search.toLowerCase())
        );
      });
      setSearchCertificates([...filteredData]);
    } else if (filterTries) {
      let filteredData = certificates.filter((item) => {
        return item.attempt.toLowerCase() === filterTries.toLowerCase();
      });
      setSearchCertificates([...filteredData]);
    } else {
      setSearchCertificates("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filterTries]);

  const updateCertificate = (index, value) => {
    let newCertificates = [...certificates];
    newCertificates[index].certificateNo = value;
    setCertificates([...newCertificates]);
  };

  return (
    <div className="results-container">
      <nav
        style={{
          display: "flex",
          alignItems: "center",
          padding: "1rem",
          boxShadow: "0px 9px 22px rgba(0, 0, 0, 0.05)",
          // border: "1px solid red",
        }}
      >
        <Link to="/admin/dashboard">
          <BackIcon />
        </Link>
        <span
          className="text-accent-2 text-bold"
          style={{ flexGrow: 1, textAlign: "center" }}
        >
          CERTIFICATE RESULTS
        </span>
      </nav>

      <div className="flex-all-center" style={{ gap: "1rem" }}>
        <SearchBar
          value={search}
          setValue={setSearch}
          text="Search name, email ID or phone number"
        />
        <ListSelect
          label="No. of tries"
          value={filterTries}
          setValue={setFilterTries}
          options={filterTriesOptions}
        />
      </div>

      {/* Search Result Found */}
      {searchCertificates.length > 0 && (
        <>
          <div>
            <span className="text-primary">{searchCertificates.length}</span>{" "}
            results found
          </div>
          <div className="results-card-container">
            {searchCertificates.map((certificate, index) => {
              return (
                <CertificateCard
                  key={index}
                  index={index}
                  certificate={certificate}
                  updateCertificate={updateCertificate}
                />
              );
            })}
            <span className="floating-btn-container">
              <Button type="primary" text="CONFIRM & UPDATE" />
            </span>
          </div>
        </>
      )}

      {/* Data */}
      {!filterTries && !search && (
        <div className="results-card-container">
          {certificates.map((certificate, index) => {
            return (
              <CertificateCard
                key={index}
                index={index}
                certificate={certificate}
                updateCertificate={updateCertificate}
              />
            );
          })}
          <span className="floating-btn-container">
            <Button type="primary" text="CONFIRM & UPDATE" />
          </span>
        </div>
      )}
    </div>
  );
};

const CertificateCard = ({ index, certificate, updateCertificate }) => {
  // update a element of State array
  const handleChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    updateCertificate(index, value);
  };

  return (
    <div className="result-card">
      <span className="result-card-item text-bold">{certificate.name}</span>
      <span className="result-card-item">{certificate.email}</span>
      <span className="result-card-item">{certificate.phone}</span>
      <span
        className={`result-card-item text-bold ${
          certificate.attempt === "3rd" && "text-accent-1"
        }`}
      >
        {certificate.attempt} TRY
      </span>
      <span className="status-container result-card-item">
        <input
          className="overview-search-input"
          style={{ padding: "0.5rem", margin: "0.5rem" }}
          type="text"
          value={certificate.certificateNo}
          onChange={handleChange}
        />
      </span>
    </div>
  );
};

export default CertificateSection;
