/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import Button from "../../../../components/botton/button";
import Modal from "../../../components/Modal/Modal";
import "./IssueCard.scss";
import ListSelect from "../../../../components/ListSelect/ListSelect";
import { updateIssueStatus } from "../../../helpers/adminapicall";

const IssueCard = ({ issue }) => {
  // const { issueNo, issueElement, issueType, issueStatus } = issue;
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState();
  const statusOptions = ["PENDING", "RESOLVED", "CLOSED"];

  useEffect(() => {
    if (issue.status) {
      setStatus(issue.status);
    }
  }, []);

  const updateStatus = (status) => {
    updateIssueStatus(issue.issueId, status).then((res) => {
      if (res.issue) {
        setStatus(status);
      }
    });
  };

  return (
    <div className="program-card">
      <div className="card-main">
        <div className="card-stats">
          <span className="menu">#123</span>
          <span>{issue.element} Element</span>
          <span>{issue.type} Type</span>
          <ListSelect
            label="Status"
            value={status}
            setValue={updateStatus}
            options={statusOptions}
          />
        </div>
      </div>
      <div className="card-view">
        <Button
          onClick={() => setShowModal(true)}
          type="secondary view-btn"
          text="View"
        />
      </div>
      <Modal
        title="My Modal"
        onClose={() => setShowModal(false)}
        show={showModal}
      >
        <div className="modal-header">
          <h3>
            ISSUE <span className="text-accent-2 text-bold">1325</span>
          </h3>{" "}
          {issue.createdAt}
        </div>
        <div className="modal-row">
          <span className="text-accent-2 text-bold modal-col ">ELEMENT</span>{" "}
          <span className="modal-col-2">{issue.element}</span>
        </div>
        <div className="modal-row">
          <span className="text-accent-2 text-bold modal-col">TYPE</span>{" "}
          <span className="modal-col-2">{issue.type}</span>
        </div>
        <div className="modal-row">
          <span className="text-accent-2 text-bold modal-col">ISSUE</span>{" "}
          <span className="modal-col-2">{issue.issue}</span>
        </div>
        <div className="modal-row">
          <span className="text-accent-2 text-bold modal-col">STATUS</span>{" "}
          <span className="modal-col-2">
            <ListSelect
              label="Status"
              value={status}
              setValue={updateStatus}
              options={statusOptions}
            />
          </span>
        </div>
        {status === "RESOLVED" && (
          <div className="modal-row">
            <span className="text-accent-2 text-bold modal-col">
              RESOLVED DATE
            </span>{" "}
            <span className="modal-col-2">
              {/* FIXME: when api is ready make this dynamic */}
              26 th Aug, 2021 by{" "}
              <span className="text-accent-2">Aurvind Raj</span>
            </span>
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Button type="secondary" text="Go to Element" />
          <Button type="primary" text="Close" />
        </div>
      </Modal>
    </div>
  );
};
export default IssueCard;
