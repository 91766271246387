module.exports = [
    { name: 'Adobe After Effects', value: [1, 2] },
    { name: 'Adobe After Effects', value: [1, 2] },
    { name: 'Adobe InDesign', value: [1, 2] },
    { name: 'Adobe Premier Pro', value: [1, 2] },
    { name: 'Adobe Photoshop', value: [1, 2] },
    { name: 'Adobe Animate', value: [1, 2] },
    { name: 'Adobe Dream Waiver', value: [1, 2] },
    { name: 'Adobe Illustrator', value: [1, 2] },
    { name: 'Application Development', value: [1, 2] },
    { name: 'AutoDesk Autocad', value: [1, 2] },
    { name: 'AutoDesk 3DS Max', value: [1, 2] },
    { name: 'AutoDesk Revit', value: [1, 2] },
    { name: 'AutoDesk Maya', value: [1, 2] },
    { name: 'AutoDesk Inventor', value: [1, 2] },
    { name: 'AutoDesk Fusion', value: [1, 2] },
    { name: 'Cloud Computing', value: [1] },
    { name: 'Comptia Network', value: [1, 2] },
    { name: 'Comptia Project', value: [1, 2] },
    { name: 'Comptia Security', value: [1] },
    { name: 'Cyber Security', value: [1] },
    { name: 'Data Science', value: [1] },
    { name: 'Data Structure', value: [1] },
    { name: 'Deep learning', value: [1] },
    { name: 'Java Fundamentals', value: [1] },
    { name: 'Machine Learning Using Python', value: [1] },
    { name: 'Microsoft Office', value: [1] },
    { name: 'React', value: [1] },
    { name: 'Python', value: [1] },
    { name: 'Network Fundamentals', value: [1] },
    { name: 'UI Development', value: [1] },
    { name: 'Unity Programmer', value: [1, 2] },
    { name: 'Unity VR Developer', value: [1] },
    { name: 'Web Development', value: [1] },
    { name: 'React Native', value: [3] },
    { name: 'React Native', value: [3] }

]