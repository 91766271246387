import React from 'react'
import Footer from '../components/layout/Footer/Footer'
import Header from '../components/layout/Header/header'
import BusinessPage from './NewBusiness/Business'

function Business() {
  return (
    <div>
        <Header />
        <BusinessPage />
        <Footer />
    </div>
  )
}

export default Business