import React, {useState} from 'react';
import './Suitable.scss';
import suitable from '../../../../assests/images/suitable.png';
import Plus from '../../../../assests/Graphics/Icons/Plus.svg';
import Minus from '../../../../assests/Graphics/Icons/Minus.svg';
import Table from '../../../../components/table/table';
import adobe from '../../../../assests/images/adobe.png'
import remax from '../../../../assests/images/remax.png'
import teleperformance from '../../../../assests/images/teleperformance.png'

const Suitable = () => {
    const [tables, settables] = useState([
        {
          id:1,
          title: "Will I be able to do this",
          expanded: true,
          items:["At Foxmula we do not only provide you with the certification to grace your Resume but also the necessary training to back it up. Apart from the ease of self-paced courses, we will also provide you with live interactive sessions with our experienced trainers. They will provide you with practical knowledge that will significantly improve your skill-set and train you for the job market. "]
        },
        {
          id:2,
          title: "Which careers can I choose after doing this",
          expanded: false,
          items:["- Animator",
          "- Concept artist",
          "- Graphic designer",
          "- Illustrator",
          "- Video editor",
          "- Creative director","- Top companies:"]
          ,company:true
        },
        {
          id:3,
          title: "INTERNSHIP",
          expanded: false,
          items:["We offer our learners project-based internships that will help them gain real-life experience of practically working on the concepts they learnt during their certification. These internships not only add more weightage to your experience but also act as an entry point into the industry. It is a real-life experience like no other which will equip you well for whatever you decide to do next!"]

        }
      ]);

    ///////////////////////////
    //handleClick
    const handleClick = (id) =>{
        console.log("clicked");
        let newtables = [...tables];
        newtables[id-1].expanded = !newtables[id-1].expanded;
        settables(newtables);
    }

    return (
        <div className="Suitable color-secondary-1">
            <div className="Suitable-header">
                <div className="Suitable-header-title text-bold">Is it suitable for you</div>
                <div className="Suitable-header-subtitle">How do we help students to land thier dream job </div>
            </div>
            <div className="Suitable-container">
                <div className="Suitable-container-left">
                    <img src={suitable} alt="" />
                </div>
                <div className="Suitable-container-right">
                {
                    tables.map(table => <Table key={table.id} table={table} handleClick={handleClick} />)
                }
                </div>
            </div>
            
        </div>
    )
}

export default Suitable;

const TableCpy = ({table, handleClick}) =>{
  return(
    <div className="worktable">
      <div className="worktable-header text-bold" onClick={() => handleClick(table.id)}>
        <span>{table.title}</span>
        {
          table.expanded ? 
          <img  className={table.expanded ? "vector" : "rotated vector"} src={Minus} alt="" /> :
          <img  className={table.expanded ? "vector" : "rotated vector"} src={Plus} alt="" />
        }
      </div>
      <div className={table.expanded ? "worktable-desc" : "hidden worktable-desc"}>
        {
          table.items.map((item, idx) => <p key={idx} className="worktable-desc-item">{item}</p>
          ) 
        }
        {table?.company?<div><img src={adobe} style={{width:'25%', paddingRight:"25px", marginTop:0}} alt=''></img><img src={remax} style={{width:'20%', paddingRight:"25px"}} alt=''></img><img src={teleperformance} style={{width:'20%', paddingRight:"25px"}} alt=''></img></div>: null}
        {table?.company?<div style={{textAlign:"left", marginTop:"15px"}}>Source: Indeed</div>:null}
      </div>
    </div>
  )
}
