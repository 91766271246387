import React from "react";
import LinkedInIcon from "../../../../assests/Graphics/Icons/SocialIconsLightSmall/LinkedInIconLight.png";
import InstagramIcon from "../../../../assests/Graphics/Icons/SocialIconsLightSmall/InstagramIconLight.png";
import YoutubeIcon from "../../../../assests/Graphics/Icons/SocialIconsLightSmall/YoutubeIconLight.png";
import TwitterIcon from "../../../../assests/Graphics/Icons/SocialIconsLightSmall/TwitterIconLight.png";
import FacebookIcon from "../../../../assests/Graphics/Icons/SocialIconsLightSmall/FacebookIconLight.png";
import FoxmulaLogoFooter from "../../../../assests/Graphics/Logos/Logo/FoxmulaLogoFooter.png"

function Address() {
  return (
    <div className="footerCard">
      <img src={FoxmulaLogoFooter} alt="FoxmulaLogo" className="foxmulaLogoFooter"></img>
      <p style={{ color: "#ffffff" }}>
        91SpringBoard, MG road, Bangalore-560025, Karnataka, IN
      </p>
      {/* <br /> */}
      {/* <br /> */}
      <h5 className="footer-heading mb-3">SOCIAL MEDIA </h5>
      <div >
        <img src={LinkedInIcon} onClick={()=>window.open('https://www.linkedin.com/company/foxmula-official/')} alt="icon" className="social-logos"></img>
        <img src={InstagramIcon} onClick={()=>window.open('https://www.instagram.com/foxmula/')} alt="icon" className="social-logos"></img>
        <img src={YoutubeIcon} onClick={()=>window.open('https://www.youtube.com/channel/UCYvOx8CBd2j2lI9p089ZLLw')} alt="icon" className="social-logos"></img>
        <img src={TwitterIcon} onClick={()=>window.open('https://twitter.com/foxmulaOfficial')} alt="icon" className="social-logos"></img>
        <img src={FacebookIcon} onClick={()=>window.open('https://www.facebook.com/foxmulaOfficial')} alt="icon" className="social-logos"></img>
      </div>
    </div>
  );
}
export default Address;
