import React from "react";
import { ReactComponent as PeopleIcon } from "../../../assets/icons/people-icon.svg";
import { ReactComponent as WarningIcon } from "../../../assets/icons/warning-icon.svg";
import Button from "../../../../components/botton/button";
import "./ProgramCard.scss";
import { updateProgramStatus } from "../../../helpers/adminapicall";
// import { useCookies } from "react-cookie";

import Popper from "../../../components/Popper/Popper";
import { Link } from "react-router-dom";

const ProgramCard = ({ program, updateProgramList }) => {
  // const [cookies, setCookie] = useCookies(["refresh", "accessToken"]);

  console.log(program);

  const handleDisable = () => {
    updateProgramStatus(program.programId, "INACTIVE");
    updateProgramList();
  };

  const handleEnable = () => {
    updateProgramStatus(program.programId, "ACTIVE");
    updateProgramList();
  };

  const handleArchive = () => {
    console.log("archived");
  };

  return (
    <div className="program-view-card">
      <div className="card-details">
        <span className="hover-visible">
          <Popper>
            {program.programStatus === "INACTIVE" ? (
              <div
                className="add-popover-content text-success text-bold"
                onClick={handleEnable}
              >
                Enable
              </div>
            ) : (
              <div
                className="add-popover-content text-danger text-bold"
                onClick={handleDisable}
              >
                Disable
              </div>
            )}
            <div className="add-popover-content" onClick={handleArchive}>
              Move to Archive
            </div>
          </Popper>
        </span>
        <span
          className="text-bold"
          style={
            program.programStatus === "INACTIVE" ? { color: "#c4c4c4" } : null
          }
        >
          {program.programName}
        </span>
      </div>
      <div className="card-view-stats">
        <Button
          type="secondary stats-btn"
          text={program.enrolled}
          Icon={PeopleIcon}
        />
        {/* <span className="stats-btn flex-align-center">
            <WarningIcon className="icon" />
            100
          </span> */}
        <Link to={`issues/${program.programId}`}>
          <Button
            type="secondary stats-btn"
            text={program.issues}
            Icon={WarningIcon}
          />
        </Link>
      </div>
      <div className="card-view">
        <Link to={`manage-certificate/${program.programId}`}>
          <Button type="secondary view-btn" text="View" />
        </Link>
      </div>
    </div>
  );
};
export default ProgramCard;
