import About from '../homepage/About/About';
import SubLandingPage from './WhyStudentsAndCompanise/SubLandingPage';
import Testimonial from './Testimonial/Testimonial';
import Business from '../homepage/Business/HomeBusiness';
import LandingPage from '../homepage/LandingPage/LandingPage';
import OurNetwork from '../../components/OurNetwork/OurNetwork';
import Careers from '../homepage/Careers/Careers';
import Essentials from './Essentials/Essentials';
import Intensive from './Intensive/Intensive';
import Header from '../../components/layout/Header/header';
import Footer from '../../components/layout/Footer/Footer';
import { useEffect, useState } from 'react';
import axios from 'axios';
import PhoneVerify from './PhoneVerify';

const HomePage = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isVerified, setIsVerified] = useState(false);
	const [userDetails, setUserDetails] = useState(null);

	useEffect(() => {
		if (window.localStorage.getItem('uid')) {
			axios
				.get(`/getUserDetails/${window.localStorage.getItem('uid')}`)
				.then((res) => {
					setIsLoggedIn(true);
					setIsVerified(res?.data?.user?.isPhoneVerified);
					setUserDetails(res?.data?.user);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, []);

	if (isLoggedIn && !isVerified && userDetails) {
		return <PhoneVerify userDetails={userDetails} />;
	}

	console.log(parseInt(getComputedStyle(document.documentElement).fontSize));
	return (
		<>
			<Header />
			<LandingPage />
			<OurNetwork />
			<SubLandingPage />
			<Essentials />
			<Intensive />
			<Careers />
			<Testimonial />
			<Business />
			<About />
			<Footer />
		</>
	);
};

export default HomePage;
