import Button from '@mui/material/Button'
import "../Jobs.scss";
import { Container } from '@mui/material';
import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FileSave from "../../../assests/images/fileSave.png"
import LinearProgress from '@mui/material/LinearProgress';


const AddJob = ({ handleSubmission, desc, setDesc, result }) => {
    console.log('result:', result)
    const [load, setLoad] = useState(false)

    const submitData = () => {
        setLoad(true)
        handleSubmission()
    }

    const handleChange = (event) => {
        setDesc(event.target.value);
    };

    const jobDesc = ["Big Data Engineer", "Assistant Professor", "Operations Research Analyst", "Computer Engineer", "CAD Designer", "Research Assistant", "Sales Applications Engineer", "Product Designer", "Test Automation Engineer", ".NET Developer", "Web Developer", "Cyber Security", "Java Full Stack",]
    return <> <Container>
        <div className="resume-header my-5" gutterBottom >
            AddJob
        </div>
        <div className="resume-content my-5" gutterBottom >
            Add the job descrition you would like to apply to with this resume and get the score of your resume
        </div>
        <center className="my-5">
            <Box sx={{ maxWidth: 400 }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Job Description</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={desc}
                        label="Job Description"
                        onChange={handleChange}
                    >
                        {
                            jobDesc.map((job, index) =>
                                <MenuItem key={index} value={job}>{job}</MenuItem>

                            )
                        }
                    </Select>
                </FormControl>
                <div>
                    <img src={FileSave} alt="file image" className='my-5' />
                </div>
                <Button className="resume-button m-2" onClick={submitData} >
                    GET SCORE
                </Button>
                {
                    load && <Box sx={{ width: '100%' }}>
                        <LinearProgress color="success" />
                    </Box>
                }
            </Box>
        </center>
    </Container>


    </>
}


export default AddJob
