/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from "react";
import "./OverviewSection.scss";

import Button from "../../../../components/botton/button";

const OverviewSection = ({ certificateData, setCertificateData }) => {
  const [certificateLink, setCertificateLink] = useState("");
  return (
    <div className="overview-container">
      {/* Input */}
      <input
        className="overview-search-input"
        value={certificateLink}
        onChange={(e) => {
          e.preventDefault();
          setCertificateLink(e.target.value);
        }}
        type="text"
        className="overview-search-input"
        placeholder="Enter the certificate link here"
      />

      <div className="overview-checkbox-container">
        <div className="overview-checkbox-item">
          <span>Certificate Link</span>
          <hr className="horizontal-line" />
          <div class="round-checkbox">
            <input type="checkbox" id="checkbox-certificate-link" />
            <label for="checkbox-certificate-link"></label>
          </div>
        </div>
        <div className="overview-checkbox-item">
          <span>LMS ( Content )</span>
          <hr className="horizontal-line" />
          <div class="round-checkbox">
            <input type="checkbox" id="checkbox-lms" />
            <label for="checkbox-lms"></label>
          </div>
        </div>
        <div className="overview-checkbox-item">
          <span>Certificate Page</span>
          <hr className="horizontal-line" />
          <div class="round-checkbox">
            <input type="checkbox" id="checkbox-certification-page" />
            <label for="checkbox-certification-page"></label>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Button type="secondary" text="SAVE CHANGES" />
        <Button type="primary" text="PUBLISH" />
      </div>
    </div>
  );
};

export default OverviewSection;
