/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Button1 from "../../components/botton/button"
import "./Business.scss"
import HiringImage from "../../assests/images/grey_rectangle.png";
import almug from '../../assests/images/almug.png'
import assetmonk from '../../assests/images/assetmonk.jpg'
import expertrons from '../../assests/images/expertrons.png'
import finlabs from '../../assests/images/finlabs.jpeg'
import neosoft from '../../assests/images/neosoft.png'
import paperplane from '../../assests/images/paperplane.jpg'
import truetech from '../../assests/images/truetech.png'
import wiseowl from '../../assests/images/wiseowl.png'
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import businessFooter from "../../assests/images/business_footer.png"
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
// import "../../../assests/scss/globals.scss;"

const Business = () => {
  const hire = [1, 2, 3, 4, 5, 6, 7, 8]
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [services, setServices] = useState("");
  const [contact, setContact] = useState("");
 
  const handleChange = (event) => {
    setServices(event.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ email, firstName, lastName, contact, organization, services, tags: ['Website'] })
    const tags = ['Website', services]
    axios
      // .post("https://www.back.foxmula.com/business/getInTouch", {
      .post("/business/getInTouch", {
        email, firstName, lastName, contact, organization, services, tags
      })
      .then((res) => { 
        console.log(res)
        alert("Request Sent! You'll hear from us soon.")
        setOpen(false)
        setEmail("")
        setContact("")
        setFirstName("")
        setLastName("")
        setServices("")
        setOrganization("")
      })
      .catch((err)=>{
        console.log(err.response.data.message)
        alert(err.response.data.message)
        setOpen(false)
        setEmail("")
        setContact("")
        setFirstName("")
        setLastName("")
        setServices("")
        setOrganization("")
      })
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  // get screen width
  let isPcScreen = window.innerWidth > 960;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    isPcScreen = width > 960;
    console.log(isPcScreen);
  }, [width]);
  return (
    <div className="business-page">
    <div className="businessAlone-container">
      <div className="businessAlone-top">
        <h1 className="businessAlone-top-header">Tailor Trained Workforce.</h1>
        <p className="businessAlone-top-text">From hiring fresh talent to training your busy bees, we make the hive work as smooth and efficient as it can be. We hand pick the cream of the crop and train them to exceed all company expectations. Offshoring, outsourcing, direct and indirect hiring. We do it all for you. </p>
        <Button1 text="Hire from us" className="businessAlone-top-button" onClick={() => setOpen(!open)} />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className="modal-header">
            <Fade in={open}>
              <Box className="card" sx={style}>
    
                <Typography
                  className="card-header"
                  id="transition-modal-title"
                  variant="h4"
                  component="h2"
                  sx={{ textAlign: "center", margin: "0 auto", marginBottom: "10px" }}

                >
                  Collaboration
                  <CancelSharpIcon sx={{position:'absolute', right:0, marginRight:'20px', cursor:'pointer'}} onClick={handleClose} />
                 
                </Typography>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  sx={{ margin: "0 auto", marginTop: "10px", my: "10px" }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <TextField
                  id="standard-adornment-password"
                  label="First Name"
                  variant="outlined"
                  sx={{ margin: "0 auto", my: "10px" }}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
                <TextField
                  id="standard-adornment-password"
                  label="Last Name"
                  variant="outlined"
                  sx={{ margin: "0 auto", my: "10px" }}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
                <TextField
                  id="standard-adornment-password"
                  label="Contact"
                  variant="outlined"
                  sx={{ margin: "0 auto", my: "10px" }}
                  onChange={(e) => {
                    setContact(e.target.value);
                  }}
                />
                <TextField
                  id="standard-adornment-password"
                  label="Organization Name"
                  variant="outlined"
                  sx={{ margin: "0 auto", my: "10px", marginBottom: "20px" }}
                  onChange={(e) => {
                    setOrganization(e.target.value);
                  }}
                />
                <FormControl sx={{ my: "50px", minWidth: 210, margin: "0 auto" }}>
                  <InputLabel id="demo-simple-select-autowidth-label">Service</InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={services}
                    onChange={handleChange}
                    autoWidth
                    label="Service"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"Direct Hiring"}>Direct Hiring</MenuItem>
                    <MenuItem value={"Remote Hiring"}>Remote Hiring</MenuItem>
                    <MenuItem value={"Offshoring/Outsourcing"}>Offshoring/Outsourcing</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  onClick={(e) => handleSubmit(e)}
                  sx={{
                    width: "50%",
                    marginLeft: "25%",
                    marginRight: "25%",
                    marginTop: "10%"
                  }} variant="contained">Get in Touch</Button>
              </Box>
            </Fade>
          </div>
        </Modal>
      </div>
      {/* Hiring */}
      <div className="businessAlone-hiring">
        <h1 className="businessAlone-hiring-header">Our Hiring Network</h1>
        <h3 className="businessAlone-hiring-subheader">See where our 13000+ alumini work at and the companies we partnered with to get you the best..</h3>
        <div className="businessAlone-hiring-container">
          <div className="businessAlone-hiring-container-card">
            <img src={expertrons} alt="business-hiring-container-image" className="businessAlone-hiring-container-image" />
          </div>
          <div className="businessAlone-hiring-container-card">
            <img src={finlabs} alt="business-hiring-container-image" className="businessAlone-hiring-container-image" />
          </div>
          <div className="businessAlone-hiring-container-card">
            <img src={wiseowl} alt="business-hiring-container-image" className="businessAlone-hiring-container-image" />
          </div>
          <div className="businessAlone-hiring-container-card businessAlone-hiring-a">
            <img src={assetmonk} alt="business-hiring-container-image" className="businessAlone-hiring-container-image" />
          </div>
          <div className="businessAlone-hiring-container-card">
            <img src={almug} alt="business-hiring-container-image" className="businessAlone-hiring-container-image" />
          </div>
          <div className="businessAlone-hiring-container-card">
            <img src={neosoft} alt="business-hiring-container-image" className="businessAlone-hiring-container-image" />
          </div>
          <div className="businessAlone-hiring-container-card">
            <img src={paperplane} alt="business-hiring-container-image" className="businessAlone-hiring-container-image" />
          </div>
          <div className="businessAlone-hiring-container-card">
            <img src={truetech} alt="business-hiring-container-image" className="businessAlone-hiring-container-image" />
          </div>
        </div>
      </div>
      {/* Options */}
      <div className="businessAlone-options">
          <h1 className="businessAlone-options-header">Hire. Direct & Remote</h1>
          <p className="businessAlone-options-text">Have our trained talent working at your office location, hassle free. Or have your employees adding value to your organisation from their home. <br />
            <br />
            Remote hiring is now the new future, with decreased costs, greater diversity and productivity along with added convenience. Hire employees from across the globe. We smoothen the process while hand picking the perfect fit for you.
          </p>
          <Button1 text="Get in touch" className="businessAlone-top-button" onClick={() => setOpen(!open)} />
        </div>

        {/* Out */}
        <div className="businessAlone-out">
          <h1 className="businessAlone-out-header">Offshoring & Outsourcing</h1>
          <p className="businessAlone-out-text">
            Relocate a part of your business to a different country with a dedicated team waiting to take over and work to perfection at a fraction of the cost. With us by your side, we ensure the work is carried out meticulously to your specifications. We accept nothing less than the best.
            <br /> <br />
            Contract your work to our talented and driven team that will handle your business with efficiency and detail, so you can focus your talent on what matters. Reduce your costs and increase your margin, while having complete control over your projects. We bring low costs and high service quality to the table, you won’t regret it.
          </p>
          {/* <Button1 text="Get in touch" className="businessAlone-out-button" /> */}
        </div>

        {/* Footer For Mobile */}
        {window.innerWidth <=480 &&  <h1 className="businessAlone-out-header" style={{marginTop : '50px'}}>How we Intervene</h1>}

      </div>

      {/* Footer */}
      {isPcScreen && <div className="businessAlone-footer" style={isPcScreen ? {visibility:'visible'} : {visibility:'hidden'}}>
          <img 
            src={businessFooter} alt="business-footer-image" className="businessAlone-footer-image-1"
            style={isPcScreen ? {} : {visibility:'hidden'}}
          />
        </div>}
        
</div>
  );
};

export default Business;
