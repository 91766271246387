import React, {useState} from 'react';
import './Suitable.scss';
import suitable from '../../../../assests/images/suitable.png';
import Plus from '../../../../assests/Graphics/Icons/Plus.svg';
import Minus from '../../../../assests/Graphics/Icons/Minus.svg';
import Table from '../../../../components/table/table';


const Suitable = () => {
    const [tables, settables] = useState([
        {
          id:1,
          title: "Will I be able to do this",
          expanded: true,
          items:["At Foxmula we do not only provide you with the certification to grace your Resume but also the necessary training to back it up. Apart from the ease of self-paced courses, we will also provide you with live interactive sessions with our experienced trainers. They will provide you with practical knowledge that will significantly improve your skill-set and train you for the job market. "]
        },
        {
          id:2,
          title: "Which careers can I choose after doing this",
          expanded: false,
          items:["- Machine Learning Researchers.  national average salary ₹12,77,412.", "- AI Engineer. entry-level annual average AI engineer salary in India is around 8 lakhs", "- Data Mining and Analysis. Entry level average 4,83,000 per annum", "- Machine Learning Engineer. National average 7.5 to 8 lakh per annum", "- Data Scientist.  ₹10,50,050", "Companies hiring","Amazon · Tata Consultancy Services · Accenture · Quantiphi · First Student · Wipro · Sprinklr · Intel "]

        },
        
      ]);

    ///////////////////////////
    //handleClick
    const handleClick = (id) =>{
        console.log("clicked");
        let newtables = [...tables];
        newtables[id-1].expanded = !newtables[id-1].expanded;
        settables(newtables);
    }

    return (
        <div className="Suitable color-secondary-1">
            <div className="Suitable-header">
                <div className="Suitable-header-title text-bold">Is it suitable for you</div>
                <div className="Suitable-header-subtitle">How do we help students to land thier dream job </div>
            </div>
            <div className="Suitable-container">
                <div className="Suitable-container-left">
                    <img src={suitable} alt="" />
                </div>
                <div className="Suitable-container-right">
                {
                    tables.map(table => <Table key={table.id} table={table} handleClick={handleClick} />)
                }
                </div>
            </div>
            
        </div>
    )
}

export default Suitable;

const TableCpy = ({table, handleClick}) =>{
    return(
      <div className="worktable">
        <div className="worktable-header text-bold">
          <span>{table.title}</span>
          {
            table.expanded ? 
            <img onClick={() => handleClick(table.id)} className={table.expanded ? "vector" : "rotated vector"} src={Minus} alt="" /> :
            <img onClick={() => handleClick(table.id)} className={table.expanded ? "vector" : "rotated vector"} src={Plus} alt="" />
          }
        </div>
        <div className={table.expanded ? "worktable-desc" : "hidden worktable-desc"}>
          {
            table.items.map((item, idx) => <p key={idx} className="worktable-desc-item">{item}</p>) 
          }
          {/* <Button type="accentsecondary" text="VIEW CURRICULUM" /> */}
        </div>
      </div>
    )
}
