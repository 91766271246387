import React, { useState } from "react";
import Button1 from "../../components/botton/button.jsx";
import Button from '@mui/material/Button';
import HiringImage from "../../assests/images/grey_rectangle.png";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import data from '../homepage/data'
import "./About.scss";
import HeroHeaderDivider from "../../assests/images/Hero-top-header-divider.png";
import JobreadyImage from "../../assests/images/jobready.png";
import LearnBest from "../../assests/images/learnbest.png";
import Certificate from "../../assests/images/certificate.png";
import StrongWave from "../../assests/images/strong.png";
import ChoiceCard from "./ChoiceCard.jsx";
import IITKGP from "../../assests/images/IITKGP.png";
import IITK from "../../assests/images/IITK.png";
import IITBHU from "../../assests/images/IITBHU.png";
import UPESD from "../../assests/images/UPESD.png";
import NITJ from "../../assests/images/NITJ.png";
import VIT from "../../assests/images/VIT.png";
import CU from "../../assests/images/CU.png";
import RIT from "../../assests/images/RIT.png";
import IITR from "../../assests/images/IITR.png";
import TIET from "../../assests/images/TIET.png";
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import AllPartners from '../../assests/images/community partners_320.png';
function About() {
  const Institutions = [IITBHU, IITK, IITKGP, IITR, NITJ, RIT, UPESD, VIT, CU, TIET];
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [clicked, setClicked] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [contact, setContact] = useState("");
  const [programs, setPrograms] = useState(null)
  const [courseCopy, setCourseCopy] = useState(null)
  const [course, setCourses] = useState(data)
  const [selectedCourse, setSelectedCourse] = useState("")



  const handleChange = (event) => {
    console.log(event.target.value)
    console.log(course)
    setPrograms(event.target.value);
    const newCourse = course.filter((i) => i.value.includes(event.target.value))
    setCourseCopy(newCourse)
  };

  const handleCourse = (event) => {
    setSelectedCourse(event.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ email, firstName, lastName, contact, organization, selectedCourse, tags: ['Website'] })
    const tags = ['Website']
    axios
      // .post("https://www.back.foxmula.com/zendesk/sell", {
      .post("/zendesk/sell", {
        email, firstName, lastName, contact, organization, selectedCourse, tags
      })
      .then((res) => {
        console.log(res)
        alert("Request Sent! You'll hear from us soon.")
        setOpen(false)
        setEmail("")
        setContact("")
        setFirstName("")
        setLastName("")
        setOrganization("")
        setSelectedCourse("")
      })
      .catch((err) => {
        console.log(err.response.data.message)
        alert(err.response.data.message)
        setOpen(false)
        setEmail("")
        setContact("")
        setFirstName("")
        setLastName("")
        setOrganization("")
        setSelectedCourse("")
      })
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  let isMobileScreen = window.innerWidth < 600;
  return (
    <div style={{ backgroundColor: 'white' }}>
      <div className="about-container">
        {/* About Top  screen  */}
        <div className="about-top">
          <h1 className="about-top-header"> About </h1>
          <img src={HeroHeaderDivider} alt="Hero Header Divider" className="about-top-divider" />
          <p className="about-top-text">
            {
              isMobileScreen ?
                'Foxmula is an ed-tech platform that strives to empower students and young professionals with job-ready skills. We provide industry-oriented training and global certifications in collaboration with leading industry experts and organisations. '
                : 'Foxmula is an ed-tech platform that strives to empower students and young professionals with job-ready skills. We provide industry-oriented training and global certifications in collaboration with leading industry experts and organisations. Our aim is to empower students and get them ready for the job market to put them on a path to success.'
            }
          </p>
          <Button1 text="Contact Us" className="about-top-button" onClick={() => setOpen(!open)} />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className="modal-header">
              <Fade in={open}>
                <Box className="card" sx={style}>

                  <Typography
                    className="card-header"
                    id="transition-modal-title"
                    variant="h4"
                    component="h2"
                    sx={{ textAlign: "center", margin: "0 auto", marginBottom: "10px", font: "Bold" }}

                  >
                    Contact Us
                    <CancelSharpIcon sx={{ position: 'absolute', right: 0, marginRight: '20px', cursor: 'pointer' }} onClick={handleClose} />
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    sx={{ margin: "0 auto", marginTop: "10px", my: "10px" }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <TextField
                    id="standard-adornment-password"
                    label="First Name"
                    variant="outlined"
                    sx={{ margin: "0 auto", my: "10px" }}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                  <TextField
                    id="standard-adornment-password"
                    label="Last Name"
                    variant="outlined"
                    sx={{ margin: "0 auto", my: "10px" }}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                  <TextField
                    id="standard-adornment-password"
                    label="Contact"
                    variant="outlined"
                    sx={{ margin: "0 auto", my: "10px" }}
                    onChange={(e) => {
                      setContact(e.target.value);
                    }}
                  />
                  <TextField
                    id="standard-adornment-password"
                    label="College Name"
                    variant="outlined"
                    sx={{ margin: "0 auto", my: "10px", marginBottom: "20px" }}
                    onChange={(e) => {
                      setOrganization(e.target.value);
                    }}
                  />
                  <FormControl sx={{ my: "10px", minWidth: 210, margin: "0 auto", marginBottom: "30px" }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Domain of Interest</InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={programs}
                      onChange={handleChange}
                      autoWidth
                      label="Domain of Interest"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={1}>Learners</MenuItem>
                      <MenuItem value={2}>Programmer</MenuItem>
                      <MenuItem value={3}>Career</MenuItem>
                    </Select>
                  </FormControl>
                  {programs && (
                    <FormControl sx={{ margin: "0 auto", minWidth: 210, my: "20px", marginBottom: "20px" }}>
                      <InputLabel id="demo-simple-select-autowidth-label">Select Course</InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={selectedCourse}
                        onChange={handleCourse}
                        autoWidth
                        label="Programs"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {courseCopy?.map((details, index) => {
                          return (
                            <MenuItem value={details.name}>{details.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>)}
                  <Button
                    onClick={(e) => handleSubmit(e)}
                    sx={{
                      width: "50%",
                      marginLeft: "25%",
                      marginRight: "25%",
                      marginTop: "10%"
                    }} variant="contained">Get in Touch</Button>
                </Box>
              </Fade>
            </div>
          </Modal>
        </div>

        {/* Origin screen */}
        <div className="about-origin">
          <div className="about-origin-header">
            <h1 className="about-origin-header-black" > Our </h1>
            <h1 className="about-origin-header-blue"> Origin </h1>
          </div>
          <img src={HeroHeaderDivider} alt="Hero Header Divider" className="about-origin-divider" />
          <p className="about-origin-text">
            {
              isMobileScreen ?
                'Foxmula was created with the idea of imparting education and skills to students and young professionals. We are also focused on providing companies with a skilled workforce, which is knowledgeable and efficient. Our program is built with an idea to be mutually beneficial for both the students and the companies.'
                :
                'Foxmula was created with the idea of imparting education and skills to students and young professionals. We are also focused on providing companies with a skilled workforce, which is knowledgeable and efficient. Our program is built with an idea to be mutually beneficial for both the students and the companies. We offer various services like Offshoring, Outsourcing, Direct Hiring and Remote Hiring for the benefit of companies and potential employees.'
            }
          </p>
        </div>

        {/* Vision screen */}
        <div className="about-vision">
          <div className="about-vision-header">
            <h1 className="about-vision-header-black"> Our </h1>
            <h1 className="about-vision-header-blue"> Vision </h1>
          </div>
          <img src={HeroHeaderDivider} alt="Hero Header Divider" className="about-vision-divider" />
          <p className="about-vision-text">
            {
              isMobileScreen ?
                'Foxmula’s vision is to revolutionize the current teaching methods to make education more fun and personal. We aim to provide students with a personalised experience where they are taught based on their learning style and not by the rudimentary standards..'
                :
                'Foxmula’s vision is to revolutionize the current teaching methods to make education more fun and personal. We aim to provide students with a personalised experience where they are taught based on their learning style and not by the rudimentary standards. We seek to empower young professionals with the knowledge and be a part of their journey to success.'
            }
          </p>
        </div>

        {/*  Mission Screen */}
        <div className="about-mission">
          <div className="about-mission-header">
            <h1 className="about-mission-header-black"> Our </h1>
            <h1 className="about-mission-header-blue"> Mission </h1>
          </div>
          <img src={HeroHeaderDivider} alt="Hero Header Divider" className="about-mission-divider" />
          <p className="about-mission-text">
            {
              isMobileScreen ?
                "Our mission is to contribute to students' development in a way that solves the problems of unemployability in India, by partnering with companies and providing them with an efficient workforce."
                :
                "Our mission is to contribute to students' development in a way that solves the problems of unemployability in India, by partnering with companies and providing them with an efficient workforce. We aim to provide students with exclusive skills and opportunities to gain exposure and experience. We also aim to provide companies with skilled resources for optimal results and young professionals with a chance to showcase their skills and knowledge."
            }
          </p>
        </div>


        {/* Why chose us screen */}
        <div className="about-chose">
          <h1 className="about-chose-header">
            Why Students and Companies choose us
          </h1>
          <h2 className="about-chose-subheader">
            We partner up with established companies to get you at your best
          </h2>
          <div className="about-chose-container">
            <ChoiceCard
              image={JobreadyImage}
              header='GET JOB READY'
              text='We get you job ready with application oreinted knowledge in fields such as AI, web dev and much more.'
            />
            <ChoiceCard
              image={LearnBest}
              header='Learn from the best'
              text='Our trainers have 12 to 15 years of experience working in  the field they teach. They know exactly what you need.'
            />
            <ChoiceCard
              image={Certificate}
              header='Get certified'
              text={`We get you MNC certifications like Microsoft's along with our partner network companies backed Foxmula certification.`}
            />
            <ChoiceCard
              image={StrongWave}
              header='Project-based Internships'
              text='At the end of every course we provide you an intenship so you can get a taste of what work is like in that field.'
            />
          </div>
        </div>

      </div>
      {/* Our Community Partner */}
      <div className="about-community">
        <h1 className="about-community-header">
          Our Community Partners
        </h1>
        <h2 className="about-community-subheader">
          Our ever expanding active presence at
        </h2>
        <div className="about-community-container">
          {
            window.innerWidth > 320 ?
              Institutions.map((institution, index) => {
                return (
                  <img src={institution} alt="Institution" className="about-community-container-image" key={index} />
                )
              })
              : <img src={AllPartners} alt="All Partners" className="about-community-container-image" />
          }
        </div>
      </div>

    </div>
  )
}

export default About
