import { useEffect, React, useState } from "react";
import "./HomePage.scss";
import { useHistory } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button1 from '@mui/material/Button';
import Button from "../../../../components/botton/button"
import CancelSharpIcon from '@mui/icons-material/CancelSharp';

import $ from 'jquery';

const HomePage = () => {



  const scrollToTop = () => {
    $("html, body").animate({ scrollTop: $("#enroll-now").offset().top - 150 }, "slow");
  };
  const history = useHistory()

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [contact, setContact] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ email, firstName, lastName, contact, organization, tags: ['Website'] })
    const tags = ['Website']
    axios
      // .post("https://www.back.foxmula.com/zendesk/sell", {
      .post("/zendesk/sell", {
        email, firstName, lastName, contact, organization, courseName: 'Adobe InDesign', tags
      })
      .then((res) => {
        console.log(res)
        alert("Request Sent! You'll hear from us soon.")
        setOpen(false)
        setEmail("")
        setContact("")
        setFirstName("")
        setLastName("")
        setOrganization("")
      })
      .catch((err) => {
        console.log(err.response.data.message)
        alert(err.response.data.message)
        setOpen(false)
        setEmail("")
        setContact("")
        setFirstName("")
        setLastName("")
        setOrganization("")
      })
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="courseInD">
      <div className="courseInD-back">
        <div className="courseInD-back-trans"></div>
      </div>
      <div className="courseInD-left">
        <div className="courseInD-left-heading text-bold">
          Adobe InDesign
        </div>
        <div className="courseInD-left-subheading">
          Show your understanding and ability to create print and digital media publications using Adobe InDesign, the industry-leading layout and page design application.
        </div>
        <div className="courseInD-left-prices">
          <div className="crossed">₹ 7000 + GST</div>
          <div className="not-crossed">₹ 5500 + GST</div>
        </div>
        <div className="courseInD-left-buttons">
        <Button type="primeprimary" text="ENROLL NOW" onClick={() => history.push('/checkout/C25/E')} />
          <Button type="primesecondary" onClick={() => setOpen(!open)} text="GET COUNSELLING" />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className="modal-header">
              <Fade in={open}>
                <Box className="card" sx={style}>
                  <Typography
                    className="card-header"
                    id="transition-modal-title"
                    variant="h4"
                    component="h2"
                    sx={{ textAlign: "center", margin: "0 auto", marginBottom: "10px" }}

                  >
                    COUNSELLING
                    <CancelSharpIcon sx={{ position: 'absolute', right: 0, marginRight: '20px', cursor: 'pointer' }} onClick={handleClose} />
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    sx={{ margin: "0 auto", marginTop: "10px", my: "10px" }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <TextField
                    id="standard-adornment-password"
                    label="First Name"
                    variant="outlined"
                    sx={{ margin: "0 auto", my: "10px" }}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                  <TextField
                    id="standard-adornment-password"
                    label="Last Name"
                    variant="outlined"
                    sx={{ margin: "0 auto", my: "10px" }}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                  <TextField
                    id="standard-adornment-password"
                    label="Contact"
                    variant="outlined"
                    sx={{ margin: "0 auto", my: "10px" }}
                    onChange={(e) => {
                      setContact(e.target.value);
                    }}
                  />
                  <TextField
                    id="standard-adornment-password"
                    label="College Name"
                    variant="outlined"
                    sx={{ margin: "0 auto", my: "10px", marginBottom: "20px" }}
                    onChange={(e) => {
                      setOrganization(e.target.value);
                    }}
                  />
                  <FormControl sx={{ my: "50px", minWidth: 210, margin: "0 auto" }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Course</InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={'Web Development'}
                      autoWidth
                      label="Course"
                      disabled
                    >
                      <MenuItem value="Web Development">
                        <em>Adobe InDesign</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Button1
                    onClick={(e) => handleSubmit(e)}
                    sx={{
                      width: "50%",
                      marginLeft: "25%",
                      marginRight: "25%",
                      marginTop: "10%"
                    }} variant="contained">Get COUNSELLING</Button1>
                </Box>
              </Fade>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
