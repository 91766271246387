import React from 'react';
import StartupIndiaLogoFooter from '../../../../assests/Graphics/Logos/Logo/StartupIndiaLogoFooter.png';
import AWS from '../../../../assests/Graphics/Logos/Logo/AWS.png';

function GetinTouch() {
	return (
		<div className='footerCard'>
			<h5 className='footer-heading'>GET IN TOUCH</h5>
			<a className='footer-link margin-neg' href='tel:+91 1234567890'>
				+91 8828859059
			</a>
			<br />
			<a
				className='footer-link margin-neg'
				href='mailto:support@expertrons.com'
			>
				support@foxmula.com
			</a>
      <br />
			<a className='footer-link margin-neg' href='/'>
        12pm to 7pm
			</a>
			<br />
			<img
				src={StartupIndiaLogoFooter}
				alt='Startup India Logo'
				className='startUpImage'
			/>
			<img src={AWS} alt='Startup India Logo' className='startUpImage' />
		</div>
	);
}
export default GetinTouch;
