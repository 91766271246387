import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddressForm from './AddressForm';
import Review from './Review';
import Footer from '../../components/layout/Footer/Footer';
import Header from '../../components/layout/Header/header';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import validator from 'validator';

const steps = ['Details', 'Payment'];

// function getStepContent(
//   step,
//   details,
//   state,
//   setAmt,
//   setCouponApplied,
//   setCert,
//   setHalfPay,
//   setState,
//   getOtp,
//   resendOtp,
//   verified,
//   error,
//   setError,
//   customErr,
//   otpSent,
//   userOtp,
//   setUserOtp,
//   resend,
//   setResend,
//   setResendDisable,
//   setDetails,
//   phone,
//   setPhone
// ) {
//   console.log(setPhone);
//   switch (step) {
//     case 0:
//       return (
//         <AddressForm
//           state={state}
//           setState={setState}
//           getOtp={getOtp}
//           resendOtp={resendOtp}
//           verified={verified}
//           error={error}
//           setError={setError}
//           customErr={customErr}
//           otpSent={otpSent}
//           userOtp={userOtp}
//           setUserOtp={setUserOtp}
//           resend={resend}
//           setResend={setResend}
//           setResendDisable={setResendDisable}
//           details={details}
//           setDetails={setDetails}
//           phone={phone}
//           setPhone={setPhone}
//         />
//       );
//     case 1:
//       return <Review details={details} setAmnt={setAmt} setCouponApplied={setCouponApplied} setCert={setCert} setHalfPay={setHalfPay} />;
//     default:
//       throw new Error('Unknown step');
//   }
// }

// function loadScript(src) {
// 	return new Promise((resolve) => {
// 		const script = document.createElement('script');
// 		script.src = src;
// 		script.onload = () => {
// 			resolve(true);
// 		};
// 		script.onerror = () => {
// 			resolve(false);
// 		};
// 		document.body.appendChild(script);
// 	});
// }

const theme = createTheme();

export default function Checkout() {
	const params = useParams();
	const [activeStep, setActiveStep] = React.useState(0);
	const [data, setData] = React.useState(null);
	const [details, setDetails] = React.useState(null);
	const [amt, setAmt] = React.useState(null);
	const [cert, setCert] = React.useState(null);
	const [halfPay, setHalfPay] = React.useState(false);
	const [couponApplied, setCouponApplied] = React.useState(null);
	const [state, setState] = React.useState(null);
	const [error, setError] = React.useState({
		name: false,
		email: false,
		phone: false,
		otp: false,
		state: false,
	});
	const [userDetails, setUserDetails] = React.useState(null);
	const [loading, setLoading] = React.useState(true);
	const [email, setEmail] = React.useState('');
	const [newUser, setNewUser] = React.useState(false);
	const [showEmail, setShowEmail] = React.useState(false);
	const [success, setSuccess] = React.useState(false);

	// const validateEmail = (email) => {
	// 	return String(email)
	// 		.toLowerCase()
	// 		.match(
	// 			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	// 		);
	// };

	function getStepContent(
		step,
		details,
		state,
		setAmt,
		setCouponApplied,
		setCert,
		setHalfPay,
		setState,
		error,
		setError,
		userDetails,
		email,
		setEmail,
		newUser,
		setNewUser,
		showEmail,
		setShowEmail,
	) {
		switch (step) {
			case 0:
				return (
					<AddressForm
						state={state}
						setState={setState}
						error={error}
						setError={setError}
						userDetails={userDetails}
						email={email}
						setEmail={setEmail}
						newUser={newUser}
						setNewUser={setNewUser}
						showEmail={showEmail}
						setShowEmail={setShowEmail}
					/>
				);
			case 1:
				return (
					<Review
						details={details}
						setAmnt={setAmt}
						setCouponApplied={setCouponApplied}
						setCert={setCert}
						setHalfPay={setHalfPay}
						state={state}
					/>
				);
			default:
				throw new Error('Unknown step');
		}
	}

	React.useEffect(() => {
		console.log(params.id);
		if (typeof params.orderId !== "undefined") {
			axios
				.get('/cashfree/order-status/' + params.orderId)
				.then((res) => {
					console.log(res);
					if (res?.data?.data?.order_status === 'PAID') {
						setSuccess(true);
						setActiveStep(activeStep + 1);
					}

					if (res?.data?.data?.order_status === 'ACTIVE') {
						// setLink(res?.data?.data?.payment_link);
						setSuccess(false);
					}

					if (res?.data?.data?.order_status === 'EXPIRED') {
						if (res?.data?.user?.cid) {
							// setLink('/checkout/' + res?.data?.user?.cid);
						} else {
							// setLink('/');
						}
						setSuccess(false);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
		if (window.localStorage.getItem('uid')) {
			console.log('loggedin');
			axios
				// .get(`http://localhost:8889/getUserDetails/${window.localStorage.getItem('uid')}`)
				.get(`/getUserDetails/${window.localStorage.getItem('uid')}`)
				.then((res) => {
					console.log(res?.data?.user);
					setUserDetails(res?.data?.user);
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			setLoading(false);
		}
		axios
			// .get(`https://www.back.foxmula.com/getCourse/${params.id}`)
			// .get(`http://localhost:8889/getCourse/${params.id}`)
			.get(`/getCourse/${params.id}`)
			.then((result) => {
				// console.log(result)
				setDetails(result?.data?.course);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	console.log(couponApplied);
	// async function displayRazorpay() {
	// 	const res = await loadScript(
	// 		'https://checkout.razorpay.com/v1/checkout.js'
	// 	);

	// 	if (!res) {
	// 		alert('Razorpay SDK failed to load. Are you online?');
	// 		return;
	// 	}

	// 	// creating a new order

	// 	if (!window.localStorage.getItem('uid')) {
	// 		alert('Login to enroll!');
	// 		// window.location = 'https://www.back.foxmula.com/login'
	// 		window.location = '/login';
	// 		return;
	// 	}
	// 	let result;
	// 	try {
	// 		// result = await axios.post("https://www.back.foxmula.com/enroll/course",
	// 		// result = await axios.post("http://localhost:8889/enroll/course", {
	// 		result = await axios.post('/enroll/course', {
	// 			amount: amt,
	// 			coupon: couponApplied,
	// 			halfPay,
	// 			certificate: cert,
	// 			cid: params.id,
	// 			uid: window.localStorage.getItem('uid'),
	// 			state,
	// 		});
	// 	} catch (error) {
	// 		console.log(error.response.data);
	// 		alert("Error: ", JSON.stringify(error.response.data));
	// 		return;
	// 	}

	// 	if (!result) {
	// 		alert('Server error. Are you online?');
	// 		return;
	// 	}

	// 	const { amount, id: order_id, currency, user } = result.data;

	// 	const options = {
	// 		key: process.env.RAZORPAY_KEY,
	// 		amount: amount.toString(),
	// 		currency: currency,
	// 		name: 'Foxmula',
	// 		description: details?.cname,
	// 		order_id: order_id,
	// 		handler: async function (response) {
	// 			console.log(response);
	// 			const data = {
	// 				orderCreationId: order_id,
	// 				razorpayPaymentId: response.razorpay_payment_id,
	// 				razorpayOrderId: response.razorpay_order_id,
	// 				razorpaySignature: response.razorpay_signature,
	// 			};
	// 			data.uid = window.localStorage.getItem('uid');
	// 			// const result = await axios.post("https://www.back.foxmula.com/payment/success", data);
	// 			// const result = await axios.post('http://localhost:8889/payment/success', data);
	// 			const result = await axios.post('/payment/success', data);
	// 			console.log(result);
	// 			setData(result.data);
	// 		},
	// 		prefill: {
	// 			name: user?.name,
	// 			email: user?.email,
	// 			contact: user?.phone,
	// 		},
	// 		theme: {
	// 			color: '#61dafb',
	// 		},
	// 	};

	// 	const paymentObject = new window.Razorpay(options);
	// 	paymentObject.open();
	// }

	const handlePayment = async function displayRazorpay() {
		if (!window.localStorage.getItem('uid')) {
			alert('Login to enroll!');
			window.location = '/login';
			return;
		}
		axios
			.post('/enroll/course/cashfree', {
				amount: amt,
				coupon: couponApplied,
				halfPay,
				certificate: cert,
				cid: params.id,
				uid: window.localStorage.getItem('uid'),
				state,
				cstate: params.state,
			})
			.then((res) => {
				window.location = `${res?.data?.cashfree?.payment_link}`;
			})
			.catch((error) => {
				console.log(error?.response?.data);
				alert("Error: ", JSON.stringify(error?.response?.data));
			});
	};

	const handleNext = () => {
		if (!window.localStorage.getItem("uid")) {
			alert("Not able to login. Check OTP")
		} else if (!state) {
			alert("Please fill all the fields");
		} else {
			// updateEmail(email, window.localStorage.getItem("uid"));
			if (!email && (newUser || showEmail)) {
				alert('Please fill your email');
			} else if (newUser || showEmail) {
				console.log(email);
				// if (validateEmail(email)) {
				if (validator.isEmail(email)) {
					axios
						.post('/updateEmail', { email, uid: window.localStorage.getItem("uid") })
						.then((res) => {
							console.log(res);
							setActiveStep(activeStep + 1);
						})
						.catch((err) => {
							console.log(err);
							console.log(err.message);
							alert("This email already exists!!");
						});
				} else {
					setError({ ...error, phone: true });
					alert('Please enter correct email!!');
				}
			} else {
				setActiveStep(activeStep + 1);
			}
		}
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div>
			<Header />
			<ThemeProvider theme={theme}>
				<Container
					component='main'
					maxWidth='sm'
					sx={{ mb: 4, mt: 20 }}
				>
					<Paper
						variant='outlined'
						sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
					>
						<Typography component='h1' variant='h4' align='center'>
							Enroll
						</Typography>
						<Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))}
						</Stepper>
						<React.Fragment>
							{success || data?.msg === 'success' ? (
								<React.Fragment>
									<Typography variant='h5' gutterBottom>
										You are enrolled!
									</Typography>
									<Typography variant='subtitle1'>
										{/* Your paymentId is {data?.paymentId}. */}
										Your paymentId is {params?.orderId}.
										Please check your registered mail to
										view the reciept!
									</Typography>
									<div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '5px' }}>
										<Button
											variant='contained'
											onClick={() => {
												window.location = '/dashboard'
											}}
											>
											Go To Dashboard
										</Button>
									</div>
								</React.Fragment>
							) : (
								<React.Fragment>
									{getStepContent(
										activeStep,
										details,
										state,
										setAmt,
										setCouponApplied,
										setCert,
										setHalfPay,
										setState,
										error,
										setError,
										userDetails,
										email,
										setEmail,
										newUser,
										setNewUser,
										showEmail,
										setShowEmail,
									)}
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'flex-end',
										}}
									>
										{activeStep !== 0 && (
											<Button
												onClick={handleBack}
												sx={{ mt: 3, ml: 1 }}
											>
												Back
											</Button>
										)}

										<Button
											variant='contained'
											onClick={
												activeStep === steps.length - 1
													// ? displayRazorpay
													? handlePayment
													: handleNext
											}
											sx={{ mt: 3, ml: 1 }}
										>
											{activeStep === steps.length - 1 ? (
												<div className='App-link'>
													Pay
												</div>
											) : (
												'Next'
											)}
										</Button>
									</Box>
								</React.Fragment>
							)}
						</React.Fragment>
					</Paper>
				</Container>
			</ThemeProvider>
			<Footer />
		</div>
	);
}
