import React from 'react';
import './SubLandingPage.scss';
import {ReactComponent as JobReady} from '../../../assests/Graphics/Icons/JobReady.svg';
import {ReactComponent as LearnBest} from '../../../assests/Graphics/Icons/LearnBest.svg';
import {ReactComponent as GetCertified} from '../../../assests/Graphics/Icons/GetCertified.svg';
import {ReactComponent as WindyStrong} from '../../../assests/Graphics/Icons/WindyStrong.svg';

const SubLandingPage = () => {
    return (
        <div className="sublanding color-accent-1">
            <div className="sublanding-header">
                <div className="sublanding-header-title text-bold">Why Students and Companies choose us</div>
                <div className="sublanding-header-subtitle">We partner up with established companies to get you at your best</div>
            </div>
            <div className="sublanding-cards">
                
                <div className="sublanding-cards-card">
                    <div className="sublanding-cards-card-img">
                        <JobReady />
                    </div>
                    <div className="sublanding-cards-card-title text-bold">
                        Get Job Ready
                    </div>
                    <div className="sublanding-cards-card-desc">
                        We get you job ready with application oreinted knowledge in fields such as AI, web dev and much more.
                    </div>
                </div>
                <div className="sublanding-cards-card">
                    <div className="sublanding-cards-card-img">
                        <LearnBest />
                    </div>
                    <div className="sublanding-cards-card-title text-bold">
                        Learn from the best
                    </div>
                    <div className="sublanding-cards-card-desc">
                        Our trainers have 12 to 15 years of experience working in  the field they teach. They know exactly what you need.
                    </div>
                </div>
                <div className="sublanding-cards-card">
                    <div className="sublanding-cards-card-img">
                        <GetCertified />
                    </div>
                    <div className="sublanding-cards-card-title text-bold">
                        Get certified
                    </div>
                    <div className="sublanding-cards-card-desc">
                        We get you MNC certifications like Microsoft’s along with our partner network companies backed Foxmula certification.
                    </div>
                </div>
                <div className="sublanding-cards-card">
                    <div className="sublanding-cards-card-img">
                        <WindyStrong />
                    </div>
                    <div className="sublanding-cards-card-title text-bold">
                        Project-based Internships 
                    </div>
                    <div className="sublanding-cards-card-desc">
                        At the end of every course we provide you an intenship so you can get a taste of what work is like in that field.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubLandingPage
