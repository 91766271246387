import React from "react";
import "./AdminDashboard.scss";
import NavBar from "./components/NavBar/NavBar";
import Sidebar from "./components/Sidebar/Sidebar";
import ProgramPage from "./pages/programPage/ProgramPage";
import IssuesPage from "./pages/issues/IssuesPage";
import ManageCertificate from "./pages/manageCertificate/ManageCertificate";
import ManageExam from "./pages/ExamsPage/ManageExam/ManageExam";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ProjectPage from "./pages/projectPage/ProjectPage";
import ExamsPage from "./pages/ExamsPage/ExamsPage";
import ReadingPage from "./pages/readingPage/ReadingPage";
import QuizPage from "./pages/quizPage/QuizPage";

const AdminDashboard = () => {
  const { path } = useRouteMatch();
  return (
    <div className="admin-dashboard-container">
      <Sidebar />
      <div style={{ width: "100%", height: "100%" }}>
        <NavBar />
        <div className="dashboard-main-container">
          <Switch>
            {/* Certificate */}
            <Route path={`${path}/programs`}>
              <ProgramPage />
            </Route>
            <Route path={`${path}/issues/:certificateID`}>
              <IssuesPage />
            </Route>
            <Route path={`${path}/manage-certificate/:certificateID`}>
              <ManageCertificate create={false} />
            </Route>
            <Route path={`${path}/reading/:certificateID`}>
              <ReadingPage />
            </Route>
            <Route path={`${path}/quiz/:certificateID`}>
              <QuizPage />
            </Route>
            <Route exact path={`${path}/manage-certificate/create"`}>
              <ManageCertificate create={true} />
            </Route>
            {/* Exams */}
            <Route path={`${path}/exams`}>
              <ExamsPage />
            </Route>
            <Route path={`${path}/manage-exam/:examId`}>
              <ManageExam />
            </Route>
            <Route path={`${path}/projects`}>
              <ProjectPage />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};
export default AdminDashboard;
