import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'
import "../Jobs.scss";
import { Container, Grid } from '@mui/material';
import React, { useState, useEffect } from "react";
import Wrong from '@mui/icons-material/Close';
import Correct from '@mui/icons-material/Done';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Pie from './Pie';

const ResumeChecker = (props) => {
    // console.log('paramFields:', paramFields)
    const { data, resumePercentage, success, email, name, phone } = props.result
    const matches = useMediaQuery('(min-width:600px)');
    const matchesMedium = useMediaQuery('(min-width:900px)');
    const [open, setOpen] = useState(false);
    const [nameN, setNameN] = useState(false);
    const [emailN, setEmailN] = useState(false);
    const [phoneN, setPhoneN] = useState(false);

    const handleOpen = () => {
        setRandom({
            percentage: 0,
            colour: "hsl(0, 0%, 0%)"
        });
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false)
        generateRandomValues()
    };
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    useEffect(() => {
        if (!success) {
            handleOpen()
            setNameN(name)
            setEmailN(email)
            setPhoneN(phone)
        }
    }, [success]);

    // useEffect(() => {
    //     console.log('useEffect:')
    //     const timer = setInterval(() => (
    //         generateRandomValues()
    //     ), 800);
    // }, []);

    const [random, setRandom] = useState({
        percentage: 0,
        colour: "hsl(0, 0%, 0%)"
    });

    const generateRandomValues = () => {
        const rand = (n) => Math.random() * n;
        setRandom({
            percentage: resumePercentage,
            colour: `hsl(${rand(360)}, ${rand(50) + 50}%, ${rand(30) + 20}%)`
        });
    };


    const SecondColumn = () => {
        return <>
            <Grid container item
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={3}
            >
                {["Parameters", "Resume", "Job Description"]?.map((field, index) =>
                    <Grid item className="resumeChecker-heading" key={index} md={3} xs={4} >
                        {field}
                    </Grid>

                )}
                {data?.map(({ param, resume, jd, index }) =>
                    <Grid
                        key={index}
                        container item
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        rowSpacing={5}
                        columnSpacing={10}
                    >
                        <Grid item className="resumeChecker-fields" md={3} xs={4}>
                            {param}
                        </Grid>
                        <Grid item className="resumeChecker-fields" md={3} xs={4}>
                            {
                                resume ? <Correct sx={{ color: "green", fontSize: "2rem" }} /> : <>
                                    <Wrong sx={{ color: "red", fontSize: "2rem" }} />
                                    {/* <div className='resumeChecker-reason'>(Reason for the score)</div> */}
                                </>
                            }
                        </Grid>
                        <Grid item className="resumeChecker-fields" md={3} xs={4}>
                            {jd ? <Correct sx={{ color: "green", fontSize: "2rem" }} /> : <Wrong sx={{ color: "red", fontSize: "2rem" }} />}
                        </Grid>
                    </Grid>

                )}
            </Grid>
        </>

    }

    const submitData = () => {
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        const result = pattern.test(emailN);
        if (!nameN) {
            alert("Please enter Name")
            return;
        }
        if (!result) {
            alert("Please enter a valid email")
            return;
        }
        const formData = new FormData();
        formData.append('name', nameN);
        formData.append('email', emailN);
        formData.append('phone', phoneN);
        fetch(
            // 'https://www.back.foxmula.com/register/new/parser',
            '/register/new/parser',
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                console.log('Success:', result);
                handleClose()
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }
    return <>
        <Container>

            <Grid
                container
                direction={matches ? "row" : "column"}
                justifyContent="space-between"
                alignItems="center"
                className="my-5"
            >
                <Grid item container md={3}>
                    <Grid container item
                        className="mb-5"
                        direction="column"
                        justifyContent="space-evenly"
                        alignItems={matchesMedium ? "left" : "center"}
                        spacing={3}
                    >
                        <Grid item className="resumeChecker-heading" sx={12}>
                            Your Score
                        </Grid>
                        {/* <button onClick={generateRandomValues}>Randomise</button> */}
                        <Pie percentage={random.percentage} colour={random.colour} />

                        <Grid item >
                            <Typography className="web-text">{nameN ? nameN : "Hello, User"}</Typography>
                        </Grid>
                        <Grid item>
                            <h3 className="web-text">Web Developer</h3>
                        </Grid>
                        <Grid item >
                            <Typography className="web-text">Improve your resume score with our help</Typography>
                            <Button variant="outlined" color="primary" className="my-3">Get Consulting</Button>
                            <div class="score-box">
                                Mistakes on your resume show you don't pay attention to detail. Think about it from the employer’s viewpoint. Your resume should showcase your best work. If it's full of mistakes, you're sending the wrong message. Hint… have at least two people proofread your resume.
                            </div>
                        </Grid>
                    </Grid >
                    {/* <FirstColumn /> */}
                </Grid>
                <Grid item container md={9}>
                    <SecondColumn />
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '40ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Looks like you have not signed in
                        </Typography>
                        <TextField value={nameN ? nameN : ''} onChange={(e) => setNameN(e.target.value)} id="outlined-basic" label="Name" variant="outlined" />
                        <TextField value={emailN ? emailN : ''} onChange={(e) => setEmailN(e.target.value)} id="outlined-basic" label="Email" variant="outlined" />
                        <TextField value={phoneN ? phoneN : ''} onChange={(e) => setPhoneN(e.target.value)} id="outlined-basic" label="Phone" variant="outlined" />

                        <Button variant="contained" onClick={submitData} >Submit</Button>
                    </Box>
                </Box>
            </Modal>
        </Container>

    </>

}





export default ResumeChecker