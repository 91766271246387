import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Timer } from './Timer';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// import { TextField } from '@mui/material';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import PhoneInput, {
	getCountryCallingCode,
	isPossiblePhoneNumber,
	parsePhoneNumber,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './LoginInput.css';

const PhoneVerify = ({ userDetails }) => {
	console.log(userDetails.phone);
	const [phone, setPhone] = useState(
		userDetails.phone.length === 10
			? '+91' + userDetails.phone
			: userDetails.phone
	);
	const [otpDisable, setOtpDisable] = useState(false);
	const [resendDisable, setResendDisable] = useState(false);
	const [userOtp, setUserOtp] = useState(['', '', '', '']);
	const [otpSent, setOtpSent] = useState(false);
	const [verified, setVerified] = useState(false);
	const [resend, setResend] = useState(false);
	const [otp, setOTP] = useState('');
	const [error, setError] = useState({
		name: false,
		email: false,
		phone: false,
		otp: false,
		state: false,
	});
	const history = useHistory();
	console.log('phone:', phone);

	const getOtp = () => {
		if (!otpDisable) {
			setOtpDisable(true);
			console.log(phone);
			// if (phone?.length === 10) {
			if (isPossiblePhoneNumber(phone)) {
				axios
					// .post("http://localhost:8889/user/get-otp", {
					.post('/user/get-otp', {
						phone: phone,
						countryCode: getCountryCallingCode(
							parsePhoneNumber(phone).country
						),
					})
					.then((res) => {
						console.log(res);
						setOTP(res?.data?.Otp);
						setOtpSent(true);
						setOtpDisable(false);
					})
					.catch((err) => {
						console.log(err);
						setOtpDisable(false);
					});
			} else {
				setError({ ...error, phone: true });
				setOtpDisable(false);
				alert('Please enter correct phone number!!');
			}
		}
	};

	const resendOtp = () => {
		// if (dphone?.length === 10 && resend && !resendDisable) {
		if (isPossiblePhoneNumber(phone) && resend && !resendDisable) {
			setResendDisable(true);
			setResend(false);
			axios
				.post('/user/get-otp', {
					phone: phone,
					countryCode: getCountryCallingCode(
						parsePhoneNumber(phone).country
					),
				})
				.then((res) => {
					setOTP(res?.data?.Otp);
					setOtpSent(true);
				})
				.catch((err) => {
					setResendDisable(false);
					setResend(true);
				});
			// } else if (phone?.length !== 10) {
		} else if (!isPossiblePhoneNumber(phone)) {
			setError({ ...error, phone: true });
			alert('Please enter correct phone number!!');
		} else if (resendDisable) {
			alert('Wait for timer!!');
		}
	};

	const handleClick = () => {
		if (verified) {
			axios
				.post('/signInViaOtp', {
					phone: phone,
					countryCode: getCountryCallingCode(
						parsePhoneNumber(phone).country
					),
				})
				.then((res) => {
					console.log(res);
					window.localStorage.setItem('token', res?.data?.token);
					window.localStorage.setItem('uid', res?.data?.user?.uid);
					// history.go(0);
					window.location.reload();
				})
				.catch((err) => {
					console.log('error:', err);
				});
		} else {
			alert('OTP not verified!!');
		}
	};

	useEffect(() => {
		console.log('this is user otp', userOtp);
		if (
			userOtp?.length === 4 &&
			!isNaN(userOtp[0]) &&
			!isNaN(userOtp[1]) &&
			!isNaN(userOtp[2]) &&
			!isNaN(userOtp[3])
		) {
			if (
				otp ===
				Number(`${userOtp[0]}${userOtp[1]}${userOtp[2]}${userOtp[3]}`)
			) {
				setVerified(true);
				setError({ ...error, otp: false });
			} else {
				setVerified(false);
				setError({ ...error, otp: true });
			}
		}
	}, [userOtp, otp]);

	return (
		<React.Fragment>
			<div className='utility-page-wrap'>
				<div className='form-block-2 w-form'>
					<a
						// href='https://foxmula.com'
						href='/'
						style={{ textDecoration: 'none' }}
					>
						<div className='text-block-12'>Verify Phone Number</div>
					</a>
					<Grid
						container
						spacing={3}
						style={{
							width: '25vw',
							minWidth: '250px',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Grid item xs={'auto'}>
							<PhoneInput
								placeholder='Phone Number'
								value={phone}
								onChange={setPhone}
								defaultCountry='IN'
								style={{
									marginBottom: '10px',
									marginTop: '24px',
									position: 'relative',
								}}
							/>
							<div
								className={`d-flex justify-space-between gap-2 align-items-center mb-1`}
							>
								{!otpSent && !verified && (
									<Button
										onClick={() => getOtp()}
										variant={'outlined'}
									>
										Get Otp
									</Button>
								)}

								{/* This is a timer for otp */}
								{otpSent && !verified && (
									<>
										<Button
											onClick={() => resendOtp()}
											variant={'outlined'}
										>
											Resend
										</Button>
										<div
											// className={`${styles.otp_btn}`}
											style={{
												border: 'none',
												fontSize: '16px',
												marginLeft: '6px',
											}}
										>
											{!resend && (
												<Timer
													minute={1}
													second={0}
													setResend={setResend}
													setResendDisable={
														setResendDisable
													}
												/>
											)}
										</div>
									</>
								)}
							</div>
						</Grid>
						<Grid item xs={'auto'}>
							<div
								className={`d-flex justify-space-between gap-1 align-items-center`}
								style={{ marginBottom: verified ? '6px' : '' }}
							>
								<label
									htmlFor='name'
									style={{
										marginRight: '6px',
										marginBottom: '0',
									}}
								>
									Verify OTP{' '}
								</label>
								{verified && (
									<Chip
										icon={<DoneIcon />}
										label='Verified'
										color='success'
										// variant='outlined'
										size='small'
									/>
								)}
							</div>
							<Grid container spacing={1}>
								<Grid item xs={3} sm={3}>
									<input
										// type='number'
										type='tele'
										maxLength='1'
										style={{
											maxWidth: '50px',
											borderRadius: '28px',
											minHeight: '40px',
											border: (verified || !otpSent) ? '2px solid #1976d2' : '2px solid rgb(244, 67, 54)',
											backgroundColor: '#f5f5f7',
											color: '#36335d',
											textAlign: 'center',
										}}
										name='otp-digit1'
										data-name='otp'
										id='otp1'
										required
										value={userOtp[0]}
										disabled={verified}
										onChange={(e) => {
											let u = [...userOtp];
											u[0] = e.target.value;
											setUserOtp(u);
											document
												.getElementById('otp2')
												.focus();
										}}
									/>
								</Grid>
								<Grid item xs={3} sm={3}>
									<input
										// type='number'
										type='tele'
										maxLength='1'
										style={{
											maxWidth: '50px',
											borderRadius: '28px',
											minHeight: '40px',
											border: (verified || !otpSent) ? '2px solid #1976d2' : '2px solid rgb(244, 67, 54)',
											backgroundColor: '#f5f5f7',
											color: '#36335d',
											textAlign: 'center',
										}}
										name='otp-digit2'
										data-name='otp'
										id='otp2'
										required
										disabled={verified}
										value={userOtp[1]}
										onChange={(e) => {
											let u = [...userOtp];
											u[1] = e.target.value;
											setUserOtp(u);
											document
												.getElementById('otp3')
												.focus();
										}}
									/>
								</Grid>
								<Grid item xs={3} sm={3}>
									<input
										// type='number'
										type='tele'
										maxLength='1'
										style={{
											maxWidth: '50px',
											borderRadius: '28px',
											minHeight: '40px',
											border: (verified || !otpSent) ? '2px solid #1976d2' : '2px solid rgb(244, 67, 54)',
											backgroundColor: '#f5f5f7',
											color: '#36335d',
											textAlign: 'center',
										}}
										name='otp-digit3'
										data-name='otp'
										id='otp3'
										disabled={verified}
										required
										value={userOtp[2]}
										onChange={(e) => {
											let u = [...userOtp];
											u[2] = e.target.value;
											setUserOtp(u);
											document
												.getElementById('otp4')
												.focus();
										}}
									/>
								</Grid>
								<Grid item xs={3} sm={3}>
									<input
										// type='number'
										type='tele'
										maxLength='1'
										style={{
											maxWidth: '50px',
											borderRadius: '28px',
											minHeight: '40px',
											border: (verified || !otpSent) ? '2px solid #1976d2' : '2px solid rgb(244, 67, 54)',
											backgroundColor: '#f5f5f7',
											color: '#36335d',
											textAlign: 'center',
										}}
										name='otp-digit4'
										data-name='otp'
										id='otp4'
										disabled={verified}
										required
										value={userOtp[3]}
										onChange={(e) => {
											let u = [...userOtp];
											u[3] = e.target.value;
											setUserOtp(u);
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<center className='button__blue'>
						<button
							disabled={!verified}
							data-wait='Please wait...'
							type='submit'
							className='button hero login-button w-button'
							style={{ cursor: 'pointer' }}
							onClick={handleClick}
						>
							Submit
						</button>
					</center>
					{/* <div className='text-block-13'>OR</div>
					<div className='text-block-14'>Login with</div>
					<div className='fb-and-google'>
						<a href='/auth/google'>
							<img
								src='/images/login-google.svg'
								loading='lazy'
								alt='Google'
							/>
						</a>
					</div>
					<div className='text-block-15'>
						New to foxmula?
						<strong className='bold-text-3'>
							<a href='/signup'> Sign Up </a>
						</strong>
					</div> */}
				</div>
			</div>
		</React.Fragment>
	);
};

export default PhoneVerify;
