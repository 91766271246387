import React from "react";
import Button from "../../../components/botton/button";
import IntensiveImage from "../../../assests/images/IntensiveImage.png";
import "./Intensive.scss";

const Intensive = () => {
  return (
    <div className="Intensive">
      <div className="Intensive-seperated"><strong>Intensive</strong> study you want.</div>
      <div className="Intensive-container">
        <div className="Intensive-container-left">
          <div className="Intensive-container-left-title">
            Expert lead classes, diving deep into demanding fields
          </div>
          <div className="Intensive-container-left-desc">
            There's no end to learning but there are endless beginnings. We bring you an exciting hamper of online technology courses and internships to unravel at the push of a button. Dive deep into your domain, change your field and get a guaranteed job or learn a new skill, the possibilities are endless. Grab the pen and write your story. The time is now.
          </div>
          {/* <Button customStyle={{ margin: '0 auto' }} type="primary" text="VIEW OUR PROFESSIONAL COURSES " onClick={() => window.location = "/courses"} /> */}
          <Button customStyle={{ margin: '0 auto' }} type="primary" text="VIEW OUR PROFESSIONAL COURSES " onClick={() => window.location.hash = "/courses"} />
        </div>
        <div className="Intensive-container-right">
          <img src={IntensiveImage} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Intensive;
