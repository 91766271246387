/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useState } from "react";
import AllParticipants from "./AllParticipants/AllParticipants";
import AllProjects from "./AllProjects/AllProjects";
import ProjectReports from "./ProjectReports/ProjectReports";

const ProjectPage = () => {
  const [currentWindow, setCurrentWindow] = useState("projects");
  return (
    <>
      {/* Title/Breadcrumbs */}
      <div className="CertificatePageHeader">
        <span className="active-breadcrumbs"> PROJECTS</span>
      </div>
      {/* HeaderTabs */}
      <ManageProjectHeader
        currentWindow={currentWindow}
        setCurrentWindow={setCurrentWindow}
      />
      {/* Container */}
      {currentWindow === "projects" && <AllProjects />}
      {currentWindow === "all participants" && <AllParticipants />}
      {currentWindow === "reports" && <ProjectReports />}
    </>
  );
};

const ManageProjectHeader = ({ currentWindow, setCurrentWindow }) => {
  let headers = ["projects", "all participants", "reports"];
  return (
    <div className="component-header">
      {headers.map((header) => (
        <a
          className={`component-header-item component-header-item${
            currentWindow === header ? "-selected" : ""
          }`}
          onClick={() => setCurrentWindow(header)}
        >
          {header.toUpperCase()}
        </a>
      ))}
    </div>
  );
};

export default ProjectPage;
