import "./Form.scss";

const Form = () => {
  return (
    <div className="my-5">
      <script data-b24-form="inline/40/w2r1ne" data-skip-moving="true">
        {(function (w, d, u) {
          var s = d.createElement("script");
          s.async = true;
          s.src = u + "?" + ((Date.now() / 180000) | 0);
          var h = d.getElementsByTagName("script")[0];
          h.parentNode.insertBefore(s, h);
        })(
          window,
          document,
          "https://cdn.bitrix24.com/b17740227/crm/form/loader_40.js"
        )}
      </script>
    </div>
  );
};
export default Form;
