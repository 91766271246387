import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import axios from 'axios';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import { useLocation, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import successImg from '../../assests/images/success.png';
import failureImg from '../../assests/images/failure.png';
import Header from '../../components/layout/Header/header';
import Footer from '../../components/layout/Footer/Footer';

// function useQuery() {
// 	const { search } = useLocation();
// 	console.log(search);
// 	return React.useMemo(() => new URLSearchParams(search), [search]);
// }

function SuccessContent() {
	const params = useParams();
	const [success, setSuccess] = React.useState(false);
	const [details, setDetails] = React.useState({});
	const [link, setLink] = React.useState('');
	const [user, setUser] = React.useState({});
	const [course, setCourse] = React.useState({});
	// let query = useQuery();

	console.log(params);

	// const handleCallback = () => {
	// 	try {
	// 		const username = query.get('username');
	// 		const password = query.get('password');
	// 		axios
	// 			.post(
	// 				// "https://www.back.foxmula.com/login",
	// 				// "http://localhost:8889/login",
	// 				'https://foxmula.com/login',
	// 				JSON.stringify({ username: username, password: password }),
	// 				{ headers: { 'content-type': 'application/json' } }
	// 			)
	// 			.then((res) => {
	// 				console.log(res);
	// 			});
	// 	} catch (error) {
	// 		console.log(error);
  //     alert("Error: ", error);
	// 		// window.location = "https://foxmula.com/login";
	// 	}
	// };

	React.useEffect(() => {
		axios
			// .get("https://www.back.foxmula.com/cashfree/order-status/" + params.id)
			// .get("http://localhost:8889/cashfree/order-status/" + params.id)
			// .get('https://foxmula.com/cashfree/order-status/' + params.id)
			.get('/cashfree/order-status/' + params.id)
			.then((res) => {
				console.log(res);
				if (res?.data?.data?.order_status === 'PAID') {
					setSuccess(true);
				}

				if (res?.data?.data?.order_status === 'ACTIVE') {
					setLink(res?.data?.data?.payment_link);
				}

				if (res?.data?.data?.order_status === 'EXPIRED') {
					if (res?.data?.user?.cid) {
						setLink('/checkout/' + res?.data?.user?.cid);
					} else {
						setLink('/');
					}
				}
				setDetails(res?.data?.data);
				setUser(res?.data?.user);
				setCourse(res?.data?.course);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<React.Fragment>
      <Header />
			<GlobalStyles
				styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}
			/>
			<CssBaseline />
			{/* <AppBar
				position='static'
				color='default'
				elevation={0}
				sx={{
					borderBottom: (theme) =>
						`1px solid ${theme.palette.divider}`,
				}}
			>
				<Toolbar sx={{ flexWrap: 'wrap' }}>
					<Typography
						href='/'
						variant='h6'
						color='inherit'
						noWrap
						sx={{ flexGrow: 1 }}
					>
						Foxmula
					</Typography>
				</Toolbar>
			</AppBar> */}
			{/* Hero unit */}
			{success ? (
				<Container
					disableGutters
					maxWidth='sm'
					component='main'
					sx={{ pt: 18, pb: 18 }}
				>
					<Typography
						variant='h4'
						align='center'
						color='text.secondary'
						sx={{ pb: 6 }}
						component='p'
					>
						<b>{course?.cname}</b>
					</Typography>
					<img
						src={successImg}
						style={{
							width: '20%',
							display: 'block',
							margin: '0 auto',
						}}
						alt='Success'
					></img>
					<Typography
						component='h1'
						variant='h2'
						align='center'
						color='text.primary'
						gutterBottom
					>
						Success
					</Typography>
					<Typography
						variant='h8'
						align='center'
						color='text.secondary'
						component='p'
					>
						Order Id/ Transaction Id: <b>{details?.cf_order_id}</b>{' '}
						<br />
						Amount Recieved: <b>{details?.order_amount}</b> <br />
						Date/Time: <b>{user?.dateCreated}</b> <br />
					</Typography>
					<Box textAlign='center'>
						<Button
							href="/dashboard"
							// href="https://www.back.foxmula.com/login"
							variant='contained'
							// onClick={handleCallback}
							sx={{
								alignContent: 'center',
								marginTop: '13px',
								size: '20px',
							}}
						>
							Go to Dashboard
						</Button>
					</Box>
				</Container>
			) : (
				<Container
					disableGutters
					maxWidth='sm'
					component='main'
					sx={{ pt: 15, pb: 15 }}
				>
					<img
						src={failureImg}
						style={{
							width: '50%',
							display: 'block',
							margin: '0 auto',
						}}
						alt='Success'
					></img>
					<Typography
						component='h1'
						variant='h2'
						align='center'
						color='text.primary'
						gutterBottom
					>
						Failed
					</Typography>
					<Typography
						variant='h5'
						align='center'
						color='text.secondary'
						component='p'
					>
						Due to some error, your payment has not been successful.
						Please try again.
					</Typography>
					<Box textAlign='center'>
						<Button
							href={link}
							variant='contained'
							sx={{
								alignContent: 'center',
								marginTop: '13px',
								size: '20px',
							}}
						>
							Retry
						</Button>
					</Box>
				</Container>
			)}
      <Footer />
		</React.Fragment>
	);
}

export default function Success() {
	return <SuccessContent />;
}
