import React from 'react';
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CircleIcon from '@mui/icons-material/Circle';
import ListItemText from '@mui/material/ListItemText';
import LK from '../../assests/images/LK.png'
import CancelSharpIcon from '@mui/icons-material/CancelSharp';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'transparent',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



const CirriculumModal = ({ open, setOpen, data, cert }) => {



    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };


    const handleClose = () => setOpen(false);
    const style = {
        transform: "translate(-50%, -50%)",
        width: "max-content",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        zIndex: 10,
        position: 'absolute',
        top: '50%',
        left: '50%',
        height: '100%',
        p: 4,
        display: 'block',
        overflow: 'auto'
    };
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}

            >
                <div className="modal-header">
                    <Fade in={open}>
                        <Box className="card" sx={style}>
                          
                           
                              <Box container justify="flex-end"  >
                              <Typography
                                className="card-header"
                                id="transition-modal-title"
                                variant="h4"
                                component="h2"
                                sx={{ textAlign: "center", margin: "0 auto", marginBottom: "10px" }}

                            >
                                CURRICULUM
                                <CancelSharpIcon sx={{position:'absolute', right:0, marginRight:'20px', cursor:'pointer'}} onClick={handleClose} />
                            </Typography>
                                
                            </Box>
                            <Box >
                                {data.map((item, index) => (
                                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === index} onChange={handleChange(index)}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                            <Typography>{item.title}</Typography>
                                        </AccordionSummary>
                                        {item?.items?.length > 0 && (
                                            <AccordionDetails style={{ textAlign: 'left' }}>
                                                <ul>
                                                    {item?.items.map((i, index) => (<ListItemButton>
                                                        <ListItemIcon>
                                                            {/* <ArrowRightIcon /> */}
                                                            <CircleIcon fontSize='small' style={{ width: '10px' }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={i} />
                                                    </ListItemButton>))}
                                                </ul>

                                            </AccordionDetails>
                                        )}

                                    </Accordion>
                                ))}
                            </Box>
                           
                            {data[0]?.LK && (
                                <div style={{marginTop:'15px'}}>
                                <ListItemText sx={{textAlign:"center"}} primary="*Content powered by LearnKey" />
                                <ListItemIcon>
                                <img style={{width:'25%', margin:'0 auto'}} src={LK} alt="" />
                            </ListItemIcon>
                            </div>)}

                        </Box>
                    </Fade>
                </div>
            </Modal>
        </>
    )
}

export default CirriculumModal;




// eslint-disable-next-line no-lone-blocks
{/* <Box sx={{ width: '100%', textAlign: "center", alignContent: "center", margin: "0 auto" }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs centered value={value} onChange={handleChange} >
                                        <Tab sx={{ fontSize: "15px" }} label={cert[0]} {...a11yProps(0)} />
                                        {cert?.length === 2 ? <Tab label="Item Two" {...a11yProps(1)} /> : null}
                                        {cert?.length === 3 ? <Tab label="Item Three" {...a11yProps(2)} /> : null}
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    {data[0]?.one?.map((item, index) => (<span>{item}<br /></span>))}
                                </TabPanel>
                                {cert?.length === 2 ? <TabPanel value={value} index={1}>
                                {data[0]?.one?.map((item, index) => (<span>{item}<br /></span>))}
                                </TabPanel> : null}
                                {cert?.length === 3 ? <TabPanel value={value} index={2}>
                                {data[0]?.one?.map((item, index) => (<span>{item}<br /></span>))}
                                </TabPanel> : null}

                            </Box> */}