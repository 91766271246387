import React from "react";
import Button from "../../../components/botton/button";
import EssentialsImage from "../../../assests/images/EssentialsImage.png";
import "./Essentials.scss";

const Essentials = () => {
  return (
    <div className="Essentials color-secondary-1">
      <div className="Essentials-seperated"><strong>Essentials</strong> you need</div>
      <div className="Essentials-container">
        <div className="Essentials-container-left">
          <div className="Essentials-container-left-title">
            Basics of the most popular technologies
          </div>
          <div className="Essentials-container-left-desc">
            There's no end to learning but there are endless beginnings. We bring you an exciting hamper of online technology courses and internships to unravel at the push of a button. Dive deep into your domain, change your field and get a guaranteed job or learn a new skill, the possibilities are endless. Grab the pen and write your story. The time is now.
          </div>
          {/* <Button customStyle={{margin:'0 auto'}} type="primary" text="VIEW OUR LEARNER COURSES " onClick={()=>window.location ="/courses" } /> */}
          <Button customStyle={{margin:'0 auto'}} type="primary" text="VIEW OUR LEARNER COURSES " onClick={()=>window.location.hash ="/courses" } />
        </div>
        <div className="Essentials-container-right">
          <img src={EssentialsImage} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Essentials;
