/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./ResultSection.scss";

//Commponents
import UploadMethodButton from "../../../../components/Utilities/UploadMethodButton/UploadMethodButton";
import Button from "../../../../../components/botton/button";

//Components for Upload Result Section
import SearchBar from "../../../../components/SearchBar/SearchBar";
import ListSelect from "../../../../../components/ListSelect/ListSelect";
import { Link } from "react-router-dom";
//Icons
import { ReactComponent as BackIcon } from "../../../../assets/icons/back-icon.svg";

const ResultSection = ({ bookings, setBookings }) => {
  return (
    <>
      <UploadSection />
      <UploadSuccess name="Results" />
      <UploadResultSection />
    </>
  );
};

const UploadSection = () => {
  const [uploadMethod, setUploadMethod] = useState(null);

  // const toggleUploadMethod = () => {
  //   if (uploadMethod === "csv") {
  //     setUploadMethod("manual");
  //   } else if (uploadMethod === "manual") {
  //     setUploadMethod("csv");
  //   }
  // };

  // const handleUploadCSV = (e) => {
  //   e.preventDefault();
  //   if (uploadMethod === "csv") {
  //     //
  //   }
  // };
  // const handleManualCSV = (e) => {
  //   e.preventDefault();
  //   if (uploadMethod === "manual") {
  //     //
  //   }
  // };
  const handleProceed = (e) => {
    e.preventDefault();
  };

  return (
    <div
      style={{
        padding: "5rem 0",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span className="text-secondary-2">
        &#9432; The results have{" "}
        <span className="text-accent-2 text-bold">not</span> been uploaded
      </span>

      {/* Upload Buttons */}
      <div className="flex-align-start" style={{ gap: "2rem" }}>
        <UploadMethodButton
          setUploadMethod={setUploadMethod}
          uploadMethod={uploadMethod}
          type="manual"
        />
        <div className="flex-align-center flex-column" style={{ gap: "1rem" }}>
          <UploadMethodButton
            setUploadMethod={setUploadMethod}
            uploadMethod={uploadMethod}
            type="csv"
          />
          <div className="download-text">DOWNLOAD SAMPLE</div>
        </div>
      </div>
      <Button
        type={uploadMethod ? "primary" : "disabled"}
        text="PROCEED"
        onClick={handleProceed}
      />
    </div>
  );
};

const UploadSuccess = ({ name, data }) => {
  const handleView = (e) => {
    e.preventDefault();
  };

  return (
    <div
      style={{
        padding: "5rem 0",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span className="text-secondary-2">
        &#9432; {name} details uploaded !
      </span>
      <Button type="primary" text="VIEW / EDIT RESULTS" onClick={handleView} />
    </div>
  );
};

const UploadResultSection = ({ data = [] }) => {
  const [search, setSearch] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterTries, setFilterTries] = useState("");
  const filterStatusOptions = ["All", "1st", "2nd", "3rd"];
  const filterTriesOptions = ["All", "absent", "failed", "passed"];

  data = [
    {
      name: "Kathryn Murphy",
      email: "randomemailid@gmail.com",
      phone: "9875543210",
      attempt: "1st",
      status: "passed",
      certificateNo: "4425QE",
    },
    {
      name: "Ram",
      email: "ram123@gmail.com",
      phone: "987614412",
      attempt: "3rd",
      status: "failed",
      certificateNo: "",
    },
    {
      name: "Shyam",
      email: "randomemailid@gmail.com",
      phone: "7546543210",
      attempt: "2nd",
      status: "absent",
      certificateNo: "fsd5h",
    },
    {
      name: "Raman",
      email: "randomemailid@gmail.com",
      phone: "98762343210",
      attempt: "1st",
      status: "",
      certificateNo: "",
    },
    {
      name: "Raghav",
      email: "randomemailid@gmail.com",
      phone: "98765432752",
      attempt: "2nd",
      status: "passed",
      certificateNo: "",
    },
    {
      name: "Rohan",
      email: "randomemailid@gmail.com",
      phone: "2346543210",
      attempt: "1st",
      status: "passed",
      certificateNo: "234gfeeee",
    },
    {
      name: "Laxaman",
      email: "randomemailid@gmail.com",
      phone: "9876556810",
      attempt: "3rd",
      status: "",
      certificateNo: "",
    },
    {
      name: "Zakhir",
      email: "randomemailid@gmail.com",
      phone: "9834543210",
      attempt: "1st",
      status: "",
      certificateNo: "rfge4",
    },
    {
      name: "Vijay",
      email: "randomemailid@gmail.com",
      phone: "9876587910",
      attempt: "2nd",
      status: "passed",
      certificateNo: "",
    },
    {
      name: "Ajay",
      email: "randomemailid@gmail.com",
      phone: "98764446210",
      attempt: "3rd",
      status: "passed",
      certificateNo: "",
    },
    {
      name: "Shivam",
      email: "randomemailid@gmail.com",
      phone: "9808943210",
      attempt: "1st",
      status: "passed",
      certificateNo: "",
    },
    {
      name: "Lucky",
      email: "randomemailid@gmail.com",
      phone: "9878643210",
      attempt: "2nd",
      status: "failed",
      certificateNo: "",
    },
  ];

  const [results, setResults] = useState([...data]);
  const [searchResults, setSearchResults] = useState([]);

  return (
    <div className="results-container">
      <nav
        style={{
          display: "flex",
          alignItems: "center",
          padding: "1rem",
          boxShadow: "0px 9px 22px rgba(0, 0, 0, 0.05)",
          // border: "1px solid red",
        }}
      >
        <Link to="/admin/dashboard">
          <BackIcon />
        </Link>
        <span
          className="text-accent-2 text-bold"
          style={{ flexGrow: 1, textAlign: "center" }}
        >
          UPLOAD RESULTS
        </span>
      </nav>
      <div className="flex-all-center" style={{ gap: "1rem" }}>
        <SearchBar
          value={search}
          setValue={setSearch}
          text="Search name, email ID or phone number"
        />
        <ListSelect
          label="No. of tries"
          value={filterTries}
          setValue={setFilterTries}
          options={filterTriesOptions}
        />
        <ListSelect
          label="Status"
          value={filterStatus}
          setValue={setFilterStatus}
          options={filterStatusOptions}
        />
      </div>
      {searchResults.length > 0 && (
        <div>
          <span className="text-primary">{searchResults.length}</span> results
          found
        </div>
      )}
      <div className="results-card-container">
        {results.map((result, index) => {
          return <ResultsCard key={index} result={result} />;
        })}
        <span className="floating-btn-container">
          <Button type="primary" text="CONFIRM & UPDATE" />
        </span>
      </div>
    </div>
  );
};

const ResultsCard = ({ result }) => (
  <div className="result-card">
    <span className="result-card-item text-bold">{result.name}</span>
    <span className="result-card-item">{result.email}</span>
    <span className="result-card-item">{result.phone}</span>
    <span
      className={`result-card-item text-bold ${
        result.attempt === "3rd" && "text-accent-1"
      }`}
    >
      {result.attempt} TRY
    </span>
    <span className="status-container result-card-item">
      <span
        className={`status-item ${result.status === "absent" ? "absent" : ""}`}
      >
        Absent
      </span>
      <span
        className={`status-item ${result.status === "failed" ? "failed" : ""}`}
      >
        Failed
      </span>
      <span
        className={`status-item ${result.status === "passed" ? "passed" : ""}`}
      >
        Passed
      </span>
    </span>
  </div>
);

export default ResultSection;
