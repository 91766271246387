import React, { useState, useEffect } from "react";
import "./header.scss";
import { Link, useLocation } from "react-router-dom";
import Button from "../../botton/button";
import logo from "../../../assests/images/logo.png";
import whitelogo from "../../../assests/images/whitelogo.png";
import { ReactComponent as Burger } from "../../../assests/Graphics/Icons/Vector.svg";
import { ReactComponent as WhiteBurger } from "../../../assests/Graphics/Icons/whiteVector.svg";
import { ReactComponent as Cross } from "../../../assests/Graphics/Icons/Cross.svg";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
// import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
//axios.defaults.withCredentials = true;
const Header = ({ color }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };


  const [inversed, setInversed] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();



  useEffect(() => {
    axios
      // .get('https://www.back.foxmula.com/login/verify', { headers: { authorization: window.localStorage.getItem('token') } })
      .get('/login/verify', { headers: { authorization: window.localStorage.getItem('token') } })
      .then((res) => {
        console.log("verified")
        window.localStorage.setItem('uid', res.data.user.id)
        console.log(res.data)
        setLoggedIn(true)
      })
      .catch((err) => {
        console.log(err)
        setLoggedIn(false)
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('uid')
      })
  }, [])

  const handleClose = () => setOpen(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY !== 0) {
      setInversed(true);
    } else {
      setInversed(false);
    }
  });

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(email, password);
    getAuth(email, password);
  };
  const getAuth = (email, password) => {
    console.log("ENv: ", process.env.REACT_APP_BACKEND_URL);
    axios
      .post(`https://foxmula.herokuapp.com/user/signIn`, {
        email: email,
        password: password,
      })
      .then((res) => {
        // console.log(res);
        // setCookie("refresh", res.data.refreshToken, { path: "/" });
        // setCookie("accessToken", res.data.authToken, { path: "/" });
        history.push("/admin/dashboard");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className={inversed ? `header inversed` : `header`}>
      {clicked ? <Mobnav handleClick={handleClick} isLoggedIn={isLoggedIn} /> : ""}
      <div className="header-logo">
        {inversed ? (
          <img src={whitelogo} alt="Foxmula Logo" />
        ) : (
          <img src={logo} alt="Foxmula Logo" />
        )}
      </div>

      <div className="header-links text-medium">
        <Link style={useLocation().pathname === '/' ? (!inversed ? { color: 'black' } : { color: 'white' }) : null} to="/">Home</Link>
        <Link style={useLocation().pathname === '/courses' ? (!inversed ? { color: 'black' } : { color: 'white' }) : null} to="/courses">Courses</Link>
        {/* {isLoggedIn ? (<div><a href='https://www.back.foxmula.com/dashboard'>Dashboard</a></div>) : null} */}
        {isLoggedIn ? (<div><a href='/dashboard'>Dashboard</a></div>) : null}
        <Link style={useLocation().pathname === '/jobs' ? (!inversed ? { color: 'black' } : { color: 'white' }) : null} to="/jobs">Jobs</Link>
        <Link style={useLocation().pathname === '/business' ? (!inversed ? { color: 'black' } : { color: 'white' }) : null} to="/business">Business</Link>
        <Link style={useLocation().pathname === '/about' ? (!inversed ? { color: 'black' } : { color: 'white' }) : null} to="/about">About Us</Link>
      </div>
      {inversed ? (
        isLoggedIn ? (
          <div className="header-buttons">
            <Button type="whitesecondary" onClick={() => {
              window.localStorage.removeItem('token');
              window.localStorage.removeItem('uid');
              // window.location = 'https://www.back.foxmula.com/logout'
              window.location = '/logout'
            }} text="LOG OUT" />
          </div>) : (
          <div className="header-buttons">
            {/* <Button type="whitesecondary" onClick={() => { window.location = 'https://www.back.foxmula.com/login' }} text="LOG IN" />
            <Button type="whiteprimary" onClick={() => { window.location = 'https://www.back.foxmula.com/signup' }} text="SIGN UP" /> */}
            <Button type="whitesecondary" onClick={() => { window.location = '/login' }} text="LOG IN" />
            <Button type="whiteprimary" onClick={() => { window.location = '/signup' }} text="SIGN UP" />
          </div>
        )
      ) : (

        isLoggedIn ? (
          <div className="header-buttons">
            <Button type="secondary" text="LOG OUT" onClick={() => {
              window.localStorage.removeItem('token');
              window.localStorage.removeItem('uid');
              // window.location = 'https://www.back.foxmula.com/logout'
              window.location = '/logout'
            }} />
          </div>
        ) :
          (<div className="header-buttons">
            {/* <Button type="secondary" text="LOG IN" onClick={() => { window.location = 'https://www.back.foxmula.com/login' }} />
            <Button type="primary" text="SIGN UP" onClick={() => { window.location = 'https://www.back.foxmula.com/signup' }} /> */}
            <Button type="secondary" text="LOG IN" onClick={() => { window.location = '/login' }} />
            <Button type="primary" text="SIGN UP" onClick={() => { window.location = '/signup' }} />
          </div>)


      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="modal-header">
          <Fade in={open}>
            <Box className="card" sx={style}>
              <Typography
                className="card-header"
                id="transition-modal-title"
                variant="h4"
                component="h2"
              >
                Login
              </Typography>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                sx={{ my: "20px", mx: "20px" }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <TextField
                id="standard-adornment-password"
                label="Password"
                variant="outlined"
                sx={{ my: "20px", mx: "20px" }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <Button
                type="primary"
                text="LOGIN"
                style={{ margin: "20px" }}
                onClick={(e) => handleSubmit(e)}
              />
            </Box>
          </Fade>
        </div>
      </Modal>

      <div className="header-phone">
        {inversed ? (
          <WhiteBurger onClick={handleClick} className="burger" />
        ) : (
          <Burger onClick={handleClick} className="burger" />
        )}
      </div>
    </div>
  );
};

export default Header;

const Mobnav = ({ handleClick, isLoggedIn }) => {
  return (
    <div className="mobnav">
      <div className="mobnav-cross">
        <Cross className="navcross" onClick={handleClick} />
      </div>
      <div className="mobnav-links">
        {/* {isLoggedIn ? (<Link onClick={() => window.location = 'https://www.back.foxmula.com/logout'}>Logout</Link>) : (<Link onClick={() => window.location = 'https://www.back.foxmula.com/login'}>Login</Link>)} */}
        {isLoggedIn ? (<Link onClick={() => window.location = '/logout'}>Logout</Link>) : (<Link onClick={() => window.location = '/login'}>Login</Link>)}

        <Link to="/">Home</Link>
        <Link to="/courses">Courses</Link>
        {/* {isLoggedIn ? (<div><Link onClick={() => { window.location = 'https://www.back.foxmula.com/dashboard' }}>Dashboard</Link></div>) : null} */}
        {isLoggedIn ? (<div><Link onClick={() => { window.location = '/dashboard' }}>Dashboard</Link></div>) : null}
        <Link to="/jobs">Jobs</Link>
        <Link to="/business">Business</Link>
        <Link to="/about">About Us</Link>
      </div>
    </div>
  );
};
