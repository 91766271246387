import React, { useEffect, useState } from "react";
import "./Sidebar.scss";

import { Link, useLocation } from "react-router-dom";

import logo from "../../../assests/images/whitelogo.png";
import { ReactComponent as CertificateIcon } from "../../assets/icons/certificate-icon.svg";
import { ReactComponent as ExamIcon } from "../../assets/icons/exam-icon.svg";
import { ReactComponent as ProjectIcon } from "../../assets/icons/project-icon.svg";

const Sidebar = () => {
  const { pathname } = useLocation();

  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (
      pathname.includes("certificate") ||
      pathname.includes("programs") ||
      pathname.includes("issues")
    ) {
      setSelected("certificate");
    } else if (pathname.includes("exam")) {
      setSelected("exam");
    } else if (pathname.includes("projects")) {
      setSelected("project");
    }
  }, [pathname]);

  return (
    <div className="sidebar-container">
      <div className="logo-container">
        <img src={logo} alt="Foxmula Logo" />
      </div>
      <div className="sidebar-list">
        <Link to="/admin/dashboard/programs">
          <div
            className={`sidebar-list-item ${
              selected === "certificate" && "sidebar-list-item-selected"
            }`}
          >
            <span style={{ paddingLeft: "2px" }} className="flex-center">
              <CertificateIcon />
            </span>
            <span>Certificate programs</span>
          </div>
        </Link>
        {/* TODO: Create a AllExam Page and show that insted of manage-exam */}
        <Link to="/admin/dashboard/exams">
          <div
            className={`sidebar-list-item ${
              selected === "exam" && "sidebar-list-item-selected"
            }`}
          >
            <span className="flex-center">
              <ExamIcon />
            </span>
            <span>Exams</span>
          </div>
        </Link>
        <Link to="/admin/dashboard/projects">
          <div
            className={`sidebar-list-item ${
              selected === "project" && "sidebar-list-item-selected"
            }`}
          >
            <span className="flex-center">
              <ProjectIcon />
            </span>
            <span>Projects</span>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default Sidebar;
