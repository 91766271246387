import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// import ListItemIcon from '@mui/material/ListItemIcon';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
// import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import axios from 'axios';

export default function Review({
	details,
	setAmnt,
	setCouponApplied,
	setCert,
	setHalfPay,
	state,
}) {
	const [check, setCheck] = React.useState(false);
	const [coupons, setCoupons] = React.useState([]);
	const [amount, setAmount] = React.useState(0);
	const [offerDiscount, setOfferDiscount] = React.useState(0);
	const [couponDiscount, setCouponDiscount] = React.useState(0);
	const [certificates, setCertificates] = React.useState([]);
	const [half, setHalf] = React.useState(false);
	const [couponValue, setCouponValue] = React.useState('');

	const filterCert = () => {
		if (certState?.state === 'I') {
			return details?.certificates.filter((cert) => cert.isIntensive);
		} else {
			return details?.certificates.filter((cert) => !cert.isIntensive);
		}
	};

	const certState = useParams();
	const certDetail = filterCert();
	const [selected, setSelected] = React.useState(certDetail[0]?.name);

	React.useEffect(() => {
		setAmount(certDetail[0].hasOwnProperty("amount") ? certDetail[0]?.amount : certDetail[0]?.value);
		setAmnt(details?.certificates[0].hasOwnProperty("amount") ? details?.certificates[0]?.amount : details?.certificates[0]?.value);
		setCert(details?.certificates[0]?.name);
	}, []);

	const products = [
		{
			name: selected,
			desc: `${selected} certificate`,
			price: amount,
		},
	];

	const handleChange = (event) => {
		if (event.target.value === 'both') {
			setSelected('both');
			setCert([certificates[0]?.name, certificates[1]?.name]);
			const price = certificates[0]?.amount + certificates[1]?.amount;
			setAmount(price);
			setOfferDiscount(Math.floor((price * 20) / 100));
			if (check) {
				if (coupons.length > 0) {
					const idx = coupons.findIndex((i) => i.id === couponValue);
					if (idx !== -1) {
						const couponPrcnt = coupons[idx].value;
						setCouponDiscount(
							Math.floor((couponPrcnt * price) / 100)
						);
						setCouponApplied(coupons[idx]);
					} else {
						setCouponApplied(null);
					}
				}
			}
		} else {
			setSelected(event.target.value);
			setCert([event.target.value]);
			const price =
				certificates[
					certificates.findIndex((i) => i.name === event.target.value)
				].amount;
			setAmount(price);
			setOfferDiscount(0);
			if (check) {
				if (coupons.length > 0) {
					const idx = coupons.findIndex((i) => i.id === couponValue);
					if (idx !== -1) {
						const couponPrcnt = coupons[idx].value;
						setCouponDiscount(
							Math.floor((couponPrcnt * price) / 100)
						);
						setCouponApplied(coupons[idx]);
					} else {
						setCouponApplied(null);
					}
				}
			}
		}
	};

	React.useEffect(() => {
		if (!half) {
			setHalfPay(half);
			setAmnt(amount - offerDiscount - couponDiscount);
		} else {
			setHalfPay(half);
			setAmnt(Math.ceil((amount - offerDiscount - couponDiscount) / 2));
		}
		console.log(amount);
	}, [offerDiscount, couponDiscount, amount]);

	React.useEffect(() => {
		let certDetails = filterCert();
		console.log('certDetails:', certDetails);
		setCertificates(certDetails);
	}, []);

	React.useEffect(() => {
		if (!check) {
			setCouponDiscount(0);
			setCouponValue('');
			setCouponApplied(null);
		}
	}, [check]);

	const handleCoupon = (e) => {
		if (couponValue.length === 0) {
			alert('Please enter a coupon code');
			setCouponDiscount(0);
			setCouponApplied(null);
		} else {
			axios
				.get(`/getOffers/${couponValue}`)
				.then((res) => {
					console.log(res?.data?.offer);
					const offer = res?.data?.offer;
					const couponAmount = offer.amount;
					if (offer.percentOffer) {
						setCouponDiscount(
							Math.floor((couponAmount * amount) / 100)
						);
					} else {
						setCouponDiscount(couponAmount);
					}
					setCouponApplied(offer);
				})
				.catch((err) => {
					console.log(err);
					alert('Invalid Coupon Code');
					setCouponApplied(null);
				});
			// if (coupons.length > 0) {
			//   const idx = coupons.findIndex((i) => i.id === e.target.value)
			//   if (idx !== -1) {
			//     const couponPrcnt = coupons[idx].value
			//     setCouponDiscount(Math.floor((couponPrcnt * amount) / 100))
			//     setCouponApplied(coupons[idx])
			//   }
			//   else {
			//     setCouponApplied(null)
			//   }
			// }
		}
	};

	return (
		<React.Fragment>
			{certificates?.length > 0 ? (
				<FormControl>
					<FormLabel id='demo-row-radio-buttons-group-label'>
						Select Certificate
					</FormLabel>

					<RadioGroup
						row
						aria-labelledby='demo-row-radio-buttons-group-label'
						name='row-radio-buttons-group'
						value={selected}
						onChange={handleChange}
					>
						<FormControlLabel
							value={certificates[0]?.name}
							control={<Radio />}
							label={certificates[0]?.name}
						/>
						{certificates?.length > 1 && (
							<>
								<FormControlLabel
									value={certificates[1]?.name}
									control={<Radio />}
									label={certificates[1]?.name}
								/>
								<FormControlLabel
									value='both'
									control={<Radio />}
									label='Both'
								/>
							</>
						)}
					</RadioGroup>
				</FormControl>
			) : null}

			<Typography variant='h6' gutterBottom>
				Order summary
			</Typography>
			<List disablePadding>
				<ListItem key={`100`} sx={{ py: 1, px: 0 }}>
					<ListItemText
						primary={details?.cname || details?.name}
						secondary={details?.cid}
					/>
					<Typography variant='body2'>₹0</Typography>
				</ListItem>
				{selected === 'both' ? (
					<ListItem key={`100`} sx={{ py: 1, px: 0 }}>
						<ListItemText
							primary={`${certificates[0]?.name} + ${certificates[1]?.name}`}
							secondary={'Certificates'}
						/>
						<Typography variant='body2'>₹{amount}</Typography>
					</ListItem>
				) : (
					products.map((product) => (
						<ListItem key={product.name} sx={{ py: 1, px: 0 }}>
							<ListItemText
								primary={product.name}
								secondary={product.desc}
							/>
							<Typography variant='body2'>
								₹{product.price}
							</Typography>
						</ListItem>
					))
				)}
				{selected === 'both' && (
					<ListItem sx={{ py: 1, px: 0 }}>
						<ListItemText primary='20% Off Offer Applied!' />
						<Typography
							variant='subtitle1'
							sx={{ fontWeight: 700 }}
						>
							- ₹{offerDiscount} OFF
						</Typography>
					</ListItem>
				)}
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								color='secondary'
								name='saveCard'
								value={check}
								onChange={() => setCheck(!check)}
							/>
						}
						label='Have any offercode?'
					/>
				</Grid>
				{check && (
					<>
						<Grid
							item
							xs={12}
							md={6}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<TextField
								required
								id='cardName'
								label='Enter here'
								// fullWidth
								autoComplete='cc-name'
								variant='standard'
								// onChange={(e) => handleCoupon(e)}
								onChange={(e) => setCouponValue(e.target.value)}
								style={{ width: '75%' }}
							/>
							<Button
								variant='contained'
								onClick={(e) => handleCoupon(e)}
							>
								Apply
							</Button>
						</Grid>
						{couponDiscount !== 0 && (
							<ListItem sx={{ py: 1, px: 0 }}>
								<ListItemText primary='Offer Applied!' />
								<Typography
									variant='subtitle1'
									sx={{ fontWeight: 700 }}
								>
									- ₹{couponDiscount} OFF
								</Typography>
							</ListItem>
						)}
					</>
				)}
				<Grid item xs={12}>
					<ListItem key={`100`} sx={{ py: 0, px: 0 }}>
						<FormControlLabel
							control={
								<Checkbox
									color='secondary'
									name='saveCard'
									value={check}
									onChange={() => {
										setHalf(!half);
										if (!half)
											setAmnt(
												Math.ceil(
													(amount -
														couponDiscount -
														offerDiscount) /
														2
												)
											);
										else
											setAmnt(
												amount -
													couponDiscount -
													offerDiscount
											);
									}}
								/>
							}
							label='Half Payment?'
						/>
						<Tooltip title='Remaining payment within 10 days'>
							<IconButton>
								<InfoIcon fontSize='small' />
							</IconButton>
						</Tooltip>
					</ListItem>
				</Grid>
				{state === 'Karnataka' ? (
					<React.Fragment>
						<ListItem key='SGST' sx={{ py: 1, px: 0 }}>
							<ListItemText primary={'SGST (9%)'} />
							<Typography variant='body2'>
								{half
									? `₹${
											(((amount -
												couponDiscount -
												offerDiscount) /
												2) *
												9) /
											100
									  }`
									: `₹${
											((amount -
												couponDiscount -
												offerDiscount) *
												9) /
											100
									  }`}
								{/* ₹{(amount * 18) / 100} */}
							</Typography>
						</ListItem>
						<ListItem key='CGST' sx={{ py: 1, px: 0 }}>
							<ListItemText primary={'CGST (9%)'} />
							<Typography variant='body2'>
								{half
									? `₹${
											(((amount -
												couponDiscount -
												offerDiscount) /
												2) *
												9) /
											100
									  }`
									: `₹${
											((amount -
												couponDiscount -
												offerDiscount) *
												9) /
											100
									  }`}
								{/* ₹{(amount * 18) / 100} */}
							</Typography>
						</ListItem>
					</React.Fragment>
				) : (
					<ListItem key='IGST' sx={{ py: 1, px: 0 }}>
						<ListItemText primary={'IGST (18%)'} />
						<Typography variant='body2'>
							{half
								? `₹${
										(((amount -
											couponDiscount -
											offerDiscount) /
											2) *
											18) /
										100
								  }`
								: `₹${
										((amount -
											couponDiscount -
											offerDiscount) *
											18) /
										100
								  }`}
							{/* ₹{(amount * 18) / 100} */}
						</Typography>
					</ListItem>
				)}
				<ListItem sx={{ py: 1, px: 0 }}>
					<ListItemText primary='Total' />
					<Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
						₹
						{half
							? Math.ceil(
									(amount - couponDiscount - offerDiscount) /
										2 +
										(((amount -
											couponDiscount -
											offerDiscount) /
											2) *
											18) /
											100
							  )
							: amount -
							  couponDiscount -
							  offerDiscount +
							  ((amount - couponDiscount - offerDiscount) * 18) /
									100}
					</Typography>
				</ListItem>
			</List>
		</React.Fragment>
	);
}
