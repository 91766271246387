import React from "react";
import Button from "../../../components/botton/button";
import BusinessImage from "../../../assests/images/BusinessImage.png";
import "./HomeBusiness.scss"
import { useHistory } from 'react-router-dom';

function HomeBusiness() {
  const history=useHistory()
  return (
    <div className="business-container ">
      <div className="business-text-container">
        <p className="business-before-heading">Foxmula For</p>

        <h2 className="business-heading"><b>Business.</b></h2>
        <h2 className="business-subheading">Upskill, Outsource, Hire & more</h2>

        <p className="business-description">
          From hiring fresh talent to training your busy bees, we make the hive
          work as smooth and efficient as it can be. We hand pick the cream of
          the crop and train them to exceed all company expectations.
          Offshoring, outsourcing, direct and indirect hiring. We do it all for
          you.
        </p>
        <Button customStyle={{margin:'0 auto', marginBottom:'20px'}}  type="secondary " text="GET IN TOUCH" onClick={()=>history.push("/business") } className="business-button"/> 
      </div>
      <div>
          <img className="business-image" src={BusinessImage} alt="Business"></img>
      </div>
    </div>
  )
}

export default HomeBusiness