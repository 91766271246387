import { ReactComponent as Redirectto } from "../../../assests/Graphics/Icons/Redirect.svg";
import blog from "../../../assests/images/blog1.jpg";
import blog1 from "../../../assests/images/blog2.jpg";
import blog2 from "../../../assests/images/blog3.jpg";
export const BlogCard = ({
  job = { title: "Hanging on to the clouds – A necessity called Cloud Computing", description: "Cloud computing, in a pictorial definition, could be the monstrous stainless steel French door refrigerator that goes virtual. You keep your supplies safe and access them from anywhere. The settings keep them fresh and readily available throughout the seasons and through all…", skills: ["AI & ML"] },
}) => {
  return (
    <div className="blogcard">
      <div className="blogcard-left">
        <img src={blog} alt="" />
      </div>
      <div className="blogcard-right">
        <div className="blogcard-right-title text-bold">{job.title}</div>
        <div className="blogcard-right-para">
          <p> {job.description} </p>
          <div className="blogcard-right-blur"></div>
        </div>
        <div className="blogcard-right-rfa" style={{cursor:'pointer'}} onClick={()=>{window.open('https://blog.foxmula.com/2022/02/23/hanging-on-to-the-clouds-a-necessity-called-cloud-computing/')}}>
          Read full artice{" "}
          <Redirectto />
            {" "}
        </div>
        <div className="blogcard-right-tags">
          {job.skills.map((skill, idx) => {
            return (
              <div key={idx} className="blogcard-right-tags-tag color-accent-2">
                {skill}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const BlogCard1 = ({
  job = { title: "How To Train Your Machine: The art and science of Machine Learning", description: "Our lives today are maneuvered by Smart assistants. We clear doubts with Alexa, share our woes with Woebot, ride in peace in self-drive cars, and let our recreational choices be made by Amazon and Netflix. We have happily reconciled to a life…", skills: ["AI & ML"] },
}) => {
  return (
    <div className="blogcard">
      <div className="blogcard-left">
        <img src={blog1} alt="" />
      </div>
      <div className="blogcard-right">
        <div className="blogcard-right-title text-bold">{job.title}</div>
        <div className="blogcard-right-para">
          <p> {job.description} </p>
          <div className="blogcard-right-blur"></div>
        </div>
        <div className="blogcard-right-rfa" style={{cursor:'pointer'}} onClick={()=>{window.open('https://blog.foxmula.com/2022/02/20/machine-learning/')}}>
          Read full artice{" "}
          <Redirectto />
            {" "}
        </div>
        <div className="blogcard-right-tags">
          {job.skills.map((skill, idx) => {
            return (
              <div key={idx} className="blogcard-right-tags-tag color-accent-2">
                {skill}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const BlogCard2 = ({
  job = { title: "AI and ML: The Technologies to Look Out For in 2022", description: "In recent years the metrics of AI and ML (Artificial Intelligence and Machine Learning) have seen an upward graph in the job market, with a reassuringly transformative future. Both these amazing streams of digital technology are now interwoven into every…", skills: ["AI & ML"] },
}) => {
  return (
    <div className="blogcard">
      <div className="blogcard-left">
        <img src={blog2} alt="" />
      </div>
      <div className="blogcard-right">
        <div className="blogcard-right-title text-bold">{job.title}</div>
        <div className="blogcard-right-para">
          <p> {job.description} </p>
          <div className="blogcard-right-blur"></div>
        </div>
        <div className="blogcard-right-rfa" style={{cursor:'pointer'}} onClick={()=>{window.open('https://blog.foxmula.com/2022/01/07/ai-and-ml-technology-2022/')}}>
          Read full artice{" "}
          <Redirectto />
            {" "}
        </div>
        <div className="blogcard-right-tags">
          {job.skills.map((skill, idx) => {
            return (
              <div key={idx} className="blogcard-right-tags-tag color-accent-2">
                {skill}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};