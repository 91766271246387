/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { ReactComponent as AddIcon } from "../../assets/icons/add-icon.svg";
import ProgramCard from "./ProgramCard/ProgramCard";
import Button from "../../../components/botton/button";
import "./ProgramPage.scss";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";

import { getAllPrograms } from "../../helpers/adminapicall";

const ProgramPage = () => {
  const history = useHistory();
  const [ProgramsList, setProgramsList] = useState([]);
  const [cookies, setCookie] = useCookies(["refresh", "accessToken"]);

  const updateProgramList = () => {
    //FIXME: After refresh token is fixed, uncomment this and change cookies.accessToken to res.data.newAuthToken
    // axios
    //   .get(`https://foxmula.herokuapp.com/user/refreshToken`, {
    //     withCredentials: true,
    //   })
    //   .then((res) => {
    //     console.log("Refresh Token Received", res.data);
    //     setCookie("accessToken", res.data.newAuthToken, { path: "/" });

    getAllPrograms(cookies.accessToken)
      .then((res) => {
        setProgramsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // });
  };

  const handleAdd = () => {
    history.push("/admin/dashboard/manage-certificate/create");
  };

  useEffect(() => {
    updateProgramList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="CertificatePageHeader">
        {/* BreadCrumbs */}
        <span className="text-accent-2 text-bold">Certificate Programs</span>
        {/* Add Program Button */}
        <Button
          type="primary"
          text="Add Program"
          Icon={AddIcon}
          onClick={() => {
            handleAdd();
          }}
        />
      </div>

      <div className="certificate-main">
        {ProgramsList.map((program, idx) => {
          return (
            <ProgramCard
              key={idx}
              program={program}
              updateProgramList={updateProgramList}
            />
          );
        })}
      </div>
    </>
  );
};
export default ProgramPage;
