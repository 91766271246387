import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ListSelect = ({
  label = "label",
  value = "",
  setValue = (e) => e,
  options = [],
  customStyle = {},
}) => {
  let labelID = label.replaceAll(" ", "-").toLowerCase;
  const handleValue = (e) => {
    setValue(e.target.value);
  };
  return (
    <FormControl
      sx={{ m: 1, minWidth: 120 }}
      style={customStyle ? customStyle : null}
    >
      <InputLabel id={`select-label-${labelID}`}>{label}</InputLabel>
      <Select
        labelId={`select-label-${labelID}`}
        id={`simple-select-${labelID}`}
        value={value}
        onChange={handleValue}
        fullWidth
        label={label}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ListSelect;
