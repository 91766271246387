import React from "react";
import "./TestimonialCard.scss";
import userProfile from "../../../assests/images/user-profile.png";
const TestimonialCard = ({text, name}) => {
  return (
    <>
      <div className="card">
        <p className="content text-left">
          {text}
        </p>
        <div className="row user-details text-left">
          <div className="col mt-5 ">
            <p className="text-left user-name mb-0 mt-3 pl-2">{name}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialCard;
