import React from 'react';
import Header from '../components/layout/Header/header';
import Footer from '../components/layout/Footer/Footer';
import About from '../pages/About/About';
function AboutPage() {
  return <div>
      <Header />
      <About />
      <Footer />
  </div>;
}

export default AboutPage;
