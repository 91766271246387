/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import OverviewSection from "./OverviewSection/OverviewSection";
import "./ManageCertificate.scss";

import { useLocation, useParams } from "react-router-dom";
// import { useCookies } from "react-cookie";
import { getProgramById } from "../../helpers/adminapicall";

import LMSSection from "./LMSSection/LMSSection";
import CertificateSection from "./CertificateSection/CertificateSection";
const ManageCertificate = ({ create }) => {
  // const [cookies, setCookie] = useCookies(["refresh", "accessToken"]);
  const [currentWindow, setCurrentWindow] = useState({
    overview: true,
    lms: false,
    certificate: false,
  });

  const [certificateData, setCertificateData] = useState(null);

  const { pathname } = useLocation();
  const { certificateID } = useParams();

  useEffect(() => {
    if (pathname.includes("/create")) {
      //TODO: Initialize a new certificate
      setCurrentWindow({
        overview: false,
        lms: false,
        certificate: true,
      });
    } else {
      //TODO: Fetch the certificate data from the server
      if (certificateID) {
        getProgramById(certificateID)
          .then((res) => {
            console.log(res.data);
            setCertificateData(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="CertificatePageHeader">
        {/* BreadCrumbs */}
        <span>
          CERTIFICATE PROGRAMS{" "}
          <span className="active-breadcrumbs">
            /{" "}
            {certificateData &&
              certificateData.certificate.certificateName.toUpperCase()}
          </span>
        </span>
      </div>
      <ManageCertificateHeader
        currentWindow={currentWindow}
        setCurrentWindow={setCurrentWindow}
      />
      <div>
        {currentWindow.overview && (
          <OverviewSection
            certificateData={certificateData}
            setCertificateData={setCertificateData}
          />
        )}
        {currentWindow.lms && (
          <LMSSection
            certificateData={certificateData}
            setCertificateData={setCertificateData}
          />
        )}
        {currentWindow.certificate && (
          <CertificateSection
            certificateData={certificateData}
            setCertificateData={setCertificateData}
          />
        )}
      </div>
    </>
  );
};

const ManageCertificateHeader = ({ currentWindow, setCurrentWindow }) => {
  // const [certificateData, setCertificateData] = useState({
  //   certificate_name: "Dell",
  //   certificate_description:
  //     "AI powered by Foxmula. Subscribe now to get this certificate free.",
  //   key_highlights: [
  //     {
  //       name: "Heading 1",
  //       description: "AI powered by Foxmula. Subscribe now",
  //     },
  //     {
  //       name: "Heading 2",
  //       description: "AI powered by Foxmula. Subscribe now",
  //     },
  //   ],
  //   skills: [
  //     {
  //       name: "Python",
  //       description: "Learn Python",
  //     },
  //     {
  //       name: "Java",
  //       description: "Learn Java",
  //     },
  //   ],
  //   companies: ["Microfoft", "IBM", "Nvidia"],
  //   jobs: ["Sql Developer", "Mean Stack Developer"],
  // });

  // const [lmsData, setLmsData] = useState({
  //   weeks: [
  //     {
  //       week: 1,
  //       routines: {
  //         sessions: [
  //           {
  //             name: "Session 1",
  //             link: "link",
  //           },
  //         ],
  //         reading: [
  //           {
  //             title: "Reading 1",
  //             description: "description",
  //           },
  //         ],
  //         sample_paper: [
  //           {
  //             title: "sample title",
  //             elements: [
  //               {
  //                 name: "Reading 1",
  //                 link: "description",
  //               },
  //               {
  //                 name: "Reading 2",
  //                 link: "description2",
  //               },
  //             ],
  //           },
  //         ],
  //         quiz: [
  //           {
  //             title: "Quiz 1",
  //             questions: [
  //               {
  //                 name: "Question 1",
  //                 orrect_answer: "correct answer1",
  //                 other_options: ["option1", "option2", "option3"],
  //                 explanation: "explanation",
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     },
  //   ],
  // });

  return (
    <div className="component-header">
      <a
        className={`component-header-item component-header-item${
          currentWindow.overview ? "-selected" : ""
        }`}
        onClick={() =>
          setCurrentWindow({
            overview: true,
            lms: false,
            certificate: false,
          })
        }
      >
        OVERVIEW
      </a>
      <a
        className={`component-header-item component-header-item${
          currentWindow.lms ? "-selected" : ""
        }`}
        onClick={() =>
          setCurrentWindow({
            overview: false,
            lms: true,
            certificate: false,
          })
        }
      >
        LMS
      </a>
      <a
        className={`component-header-item component-header-item${
          currentWindow.certificate ? "-selected" : ""
        }`}
        onClick={() =>
          setCurrentWindow({
            overview: false,
            lms: false,
            certificate: true,
          })
        }
      >
        CERTIFICATE PAGE
      </a>
    </div>
  );
};

export default ManageCertificate;
