import React from "react";
import "./AllProjects.scss";
import { ReactComponent as AddIcon } from "../../../assets/icons/add-icon.svg";
import { ReactComponent as WarningIcon } from "../../../assets/icons/warning-icon.svg";

import Button from "../../../../components/botton/button";

const AllProjects = () => {
  const handleAddProject = () => {
    console.log("add project clicked");
  };

  return (
    <div>
      <div>
        <Button
          type="primary"
          Icon={AddIcon}
          onClick={handleAddProject}
          text="ADD PROJECT"
        />
      </div>
      <div className="projects-container">
        {/* TODO: Show Project data after fetching from axios */}
        <ProjectCard />
        <ProjectCard />
        <ProjectCard />
      </div>
    </div>
  );
};

const ProjectCard = () => {
  return (
    <div className="project-card">
      {/* Project Name */}
      <div>
        <div className="text-bold">
          Adaptive and responsive POS system using python and SQL
        </div>
        <div>CompanyIcon Google</div>
        <div>
          <span className="text-shade-grey-2">
            Last Updated <span>32 weeks</span> ago
          </span>
        </div>
      </div>
      <div>
        <span className="text-shade-grey-2">Total candidates</span>{" "}
        <span className="text-shade-black">15486</span>
        <span className="text-shade-grey-2">Current candidates</span>{" "}
        <span className="text-accent-2">15486</span>
        <div>
          <span className="text-bold">
            <WarningIcon /> 500
          </span>
        </div>
        <div className="card-view">
          <Button type="secondary view-btn" text="View" />
        </div>
      </div>
    </div>
  );
};

export default AllProjects;
