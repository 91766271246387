import React, { useState } from "react";

// Icons
import { ReactComponent as QuizIcon } from "../../../../assets/icons/quiz-icon.svg";
import { ReactComponent as QuizEditIcon } from "../../../../assets/icons/quiz-edit-icon.svg";

// Components
import Popper from "../../../../components/Popper/Popper";

function QuizCard({ data, name, title, handleEdit, handleRemove }) {
  const [isMouseOver, setisMouseOver] = useState(false);
  const handleMouseOver = () => {
    setisMouseOver(true);
  };
  const handleMouseOut = () => {
    setisMouseOver(false);
  };
  return (
    <div
      className="weekCard-single-list-quiz"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
        }}
      >
        <div className="weekCard-single-list-quiz-left">
          <QuizIcon />
        </div>
        <div className="weekCard-single-list-quiz-middle">
          <div className="weekCard-single-list-quiz-middle-header">{name}</div>
          <div className="weekCard-single-list-quiz-middle-content">
            {title}
          </div>
        </div>
      </div>
      <div
        className="weekCard-single-list-quiz-right"
        style={isMouseOver ? { visibility: "visible" } : null}
      >
        <QuizEditIcon onClick={handleEdit} style={{ cursor: "pointer" }} />
        <Popper>
          <div
            className="add-popover-content text-danger text-bold"
            onClick={handleRemove}
          >
            Remove
          </div>
        </Popper>
      </div>
    </div>
  );
}

export default QuizCard;
