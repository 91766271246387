import thunk from 'redux-thunk';
import {createStore,applyMiddleware, combineReducers} from 'redux';
import changeNameReducer from './reducers/changeNameReducer'
import userDetailReducer from "./reducers/userDetailReducer"
import authReducer from './reducers/authReducer';

const reducer = combineReducers({
    changeName: changeNameReducer,
    userDetail: userDetailReducer,
    auth: authReducer,
})
const store = createStore(reducer,applyMiddleware(thunk))


export default store;