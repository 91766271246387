/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import "./ReadingPage.scss";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload-icon.svg";

import ProfileInfo from "../../components/ProfileInfo/ProfileInfo";
import Button from "../../../components/botton/button";
import TextEditor from "../../components/TextEditor/TextEditor";
import { getProgramById, updateProgram } from "../../helpers/adminapicall";

const ReadingPage = () => {
  const [title, setTitle] = useState("");
  const [editorData, setEditorData] = useState();
  const { certificateID } = useParams();
  const queryParams = new URLSearchParams(useLocation().search);
  const week = queryParams.get("week") > 0 ? queryParams.get("week") - 1 : null;
  const updateIndex =
    queryParams.get("update") > 0 ? queryParams.get("update") - 1 : null;
  let history = useHistory();

  const [isValid, setIsValid] = useState(false);

  const [programObj, setProgramObj] = useState(null);

  useEffect(() => {
    getProgramById(certificateID).then((res) => {
      setProgramObj({
        certificate: res.data.certificate,
        lms: res.data.lms,
      });
    });
  }, []);

  const checkIsValid = () => {
    programObj.lms.weeks[week]
      ? programObj.lms.weeks[week].routines.reading[updateIndex]
        ? setIsValid(true)
        : setIsValid(false)
      : setIsValid(false);
  };

  useEffect(() => {
    if (updateIndex >= 0 && week >= 0 && programObj) {
      checkIsValid();
      if (isValid) {
        setTitle(
          programObj.lms.weeks[week].routines.reading[updateIndex].title
        );
        setEditorData(
          programObj.lms.weeks[week].routines.reading[updateIndex].description
        );
        console.log(programObj.lms.weeks[week]);
      }
    }
  }, [programObj]);

  // useEffect(() => {
  //   console.log("Editor Data:", editorData);
  // }, [editorData]);

  const handleSave = () => {
    let updatedLMS = programObj.lms;

    if (isValid) {
      //  Update old reading
      updatedLMS.weeks[week].routines.reading[updateIndex].title = title;
      updatedLMS.weeks[week].routines.reading[updateIndex].description =
        editorData;
    } else {
      //Push new reading
      //TODO: Add a check for week is present or not in the url
      if (week) {
        updatedLMS.weeks[week].routines.reading.push({
          title,
          description: editorData,
        });
      }
    }

    // Finally update the program
    let newProgramObj = {
      certificates: [
        {
          certificate_name: programObj.certificate.certificateName,
          certificate_description:
            programObj.certificate.certificateDescription,
          certificate_image: programObj.certificate.certificateImage,
          certificate_link: programObj.certificate.certificateLink,
          template_image: programObj.certificate.templateImage,
          key_highlights: programObj.certificate.key_highlights,
          skills: programObj.certificate.skills,
          jobs: programObj.certificate.jobs,
          exams: programObj.certificate.exams,
          certificateFeatures: programObj.certificate.certificateFeatures,
        },
      ],
      lms: [updatedLMS],
    };
    updateProgram(certificateID, newProgramObj).then((res) => {
      console.log("Certificate Updated");
      history.push(`/admin/dashboard/manage-certificate/${certificateID}/lms`);
    });
  };
  return (
    <>
      <div className="quiz-nav quiz-container">
        <Link to={`/admin/dashboard/manage-certificate/${certificateID}/lms`}>
          <BackIcon />
        </Link>
        <ProfileInfo />
      </div>

      <div
        className="quiz-container quiz-body"
        style={{ backgroundColor: "#E8E8E8" }}
      >
        <div
          className="flex-align-center"
          style={{
            justifyContent: "space-between",
            minHeight: "5.1rem",
            padding: "1rem 0",
          }}
        >
          <span className="text-accent-2">Reading - 1</span>
          {true && (
            <Button type="primary" text="SAVE CHANGES" onClick={handleSave} />
          )}
        </div>
        <div
          className="quiz-container quiz-card-conatiner flex-all-center flex-column"
          style={{ gap: "2rem" }}
        >
          <div
            className="flex-align-center"
            style={{
              gap: "1rem",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="input-field"
              placeholder="Enter the reading name here"
              style={{ width: "100%", maxWidth: "50rem" }}
            />
            <UploadButton text="Files (Optional)" />
          </div>
          <TextEditor setEditorData={setEditorData} />
        </div>
      </div>
    </>
  );
};

const UploadButton = ({ text = null }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [error, setError] = useState(null);

  const allowedTypes = ["image/png", "image/jpeg"];

  const handleUpload = (e) => {
    let selected = e.target.files[0];

    if (selected && allowedTypes.includes(selected.type)) {
      setSelectedImage(selected);
      setImageURL("");
      setError("");
    } else {
      setSelectedImage(null);
      setImageURL("");
      setError("Please select an image files (png/jpeg)");
    }
  };

  const handleRemove = (e) => {
    setSelectedImage(null);
  };

  return (
    <div
      className={`file-upload-btn ${
        selectedImage ? "file-upload-btn-selected" : ""
      }`}
    >
      {selectedImage ? (
        <>
          <div>
            <img
              style={{ maxHeight: "95px", maxWidth: "150px" }}
              src={URL.createObjectURL(selectedImage)}
              alt=""
            />
          </div>
          {text && (
            <div>
              <input
                type="text"
                placeholder="Alt Text"
                style={{ border: "none" }}
              />
              <Button type="secondary" text="REMOVE" onClick={handleRemove} />
            </div>
          )}
        </>
      ) : (
        <>
          <div>
            <UploadIcon />
          </div>
          <input type="file" name="Select image" onChange={handleUpload} />
          {error ? (
            <div style={{ color: "red" }}>{error}</div>
          ) : (
            text && <div>Upload / Drag and Drop the {text}</div>
          )}
        </>
      )}
    </div>
  );
};

export default ReadingPage;
