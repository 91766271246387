/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-has-content */
import {React, useEffect} from 'react'
import Footer from '../layout/Footer/Footer'
import Header from '../layout/Header/header'
import {Helmet} from "react-helmet";
import './Register.css'

function Payment() {
    const course = { cname: 'Machine Learning', amount: 0, hybridAmount: 0, blendedAmount: 10, cid: 'C13' }
    const amount = 10
    const csubs = true
    const subs = false

    // useEffect(()=>{
    //     const Script = document.createElement("script");
    //     const Form = document.getElementById('paymentForm');
    //     Script.setAttribute('src','https://checkout.razorpay.com/v1/checkout.js')
    //     Script.setAttribute('data-key','rzp_test_muEwSD79LPUJVx')
    //     Script.setAttribute('data-amount','1000')
    //     Script.setAttribute('data-buttontext','Pay Now')
    //     Script.setAttribute('data-name','Foxmula')
    //     Script.setAttribute('data-prefill.name','Dev')
    //     Script.setAttribute('data-prefill.email','devparmar37@gmail.com')
    //     Script.setAttribute('data-theme.color',"#F37254")
    //     Form.appendChild(Script);
    //   },[])
    return (
        <div>
            <Header />
            <main>
                <section id="hero_in" class="cart_section">
                    <div class="wrapper">
                        <div class="container">
                            <div class="bs-wizard clearfix">
                                <div class="bs-wizard-step">
                                    <div class="text-center bs-wizard-stepnum" style={{ color: "black" }}>{course?.cname}</div>
                                    <div class="progress">
                                        <div class="progress-bar"></div>
                                    </div>
                                    <a href="cart-1.html" class="bs-wizard-dot"></a>
                                </div>

                                <div class="bs-wizard-step active">
                                    <div class="text-center bs-wizard-stepnum" style={{ color: "black" }}>Payment</div>
                                    <div class="progress">
                                        <div class="progress-bar"></div>
                                    </div>
                                    <a href="#0" class="bs-wizard-dot"></a>
                                </div>

                                <div class="bs-wizard-step disabled">
                                    <div class="text-center bs-wizard-stepnum" style={{ color: "black" }}>Finish!</div>
                                    <div class="progress">
                                        <div class="progress-bar"></div>
                                    </div>
                                    <a href="#0" class="bs-wizard-dot"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="bg_color_1">
                    <div class="container margin_60_35">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="box_cart">
                                    <div class="form_title">
                                        <h3><strong>2</strong>Registration Information</h3>
                                        <br />
                                        <p>
                                            Please find the details of your registration below!
                                        </p>
                                    </div>
                                    <div class="step">
                                        <p>
                                            <br />
                                            <span style={{ fontSize: "20px" }}>Course CID <span
                                                class="float-right">{course.cid}</span></span>
                                            <br />
                                            <br />
                                            <span style={{ fontSize: "20px" }}>Course Name <span
                                                class="float-right">{course?.cname}</span></span>
                                            <br />
                                            <br />
                                            {(csubs === false) ?
                                                <span style={{ fontSize: "20px" }}>Net Price (incl. GST) <span
                                                    class="float-right">{amount} INR</span></span>
                                                :
                                                <span style={{ fontSize: "20px" }}>First Installment <span class="float-right">{amount}
                                                    INR</span></span>
                                            }
                                            <br />
                                            <br />
                                            {(subs === false && csubs === false) &&
                                                <span style={{ fontSize: "20px" }}>GST <span class="float-right">{amount * 0} INR</span></span>
                                            }
                                        </p>
                                    </div>
                                    <hr />
                                    <div class="form-group" style={{ marginTop: "15px" }}>
                                        <form id="paymentForm" action="/continue/completePayment/<%= rid %>" method="POST">

                                            <input class="ui huge primary button" type="hidden" value="Hidden Element"
                                                name="hidden" />
                                            <br />
                                        </form>
                                    </div>

                                </div>
                            </div>
                            <aside class="col-lg-4" id="sidebar">

                                <div class="box_detail">
                                    <div id="total_cart">
                                        Total <span class="float-right">{Math.floor(amount)} INR</span>

                                    </div>
                                    <div class="add_bottom_30">Almost ready for an adventure to a Job-ready profile. Also, beside
                                        the above total fees, we'll be needing a little extra. Just a small touch of dedication,
                                        focus and Internet from your end.</div>
                                    <br />
                                    <i>This screen will be redirected in 300 seconds!</i><br />
                                </div>
                                <a href="cart-3.html" class="btn_1 full-width">Checkout</a>
                            </aside>
                        </div>


                    </div>
                </div>

            </main>
            <Footer />
        </div>
    )
}

export default Payment

