import React, { useState } from 'react';
import './HowDoesItWork.scss';
import work from '../../../../assests/images/work.png';
import Plus from '../../../../assests/Graphics/Icons/Plus.svg';
import Minus from '../../../../assests/Graphics/Icons/Minus.svg';
import Table from '../../../../components/table/table';
import Button from '../../../../components/botton/button';

import CirriculumModal from '../../../../components/CirriculumModal/CirriculumModal';
import data from '../../data'


const HowDoesItWork = () => {
    const [tables, settables] = useState([
        {
          id:1,
          title: "TRAINING",
          expanded: true,
          items:["At Foxmula we do not only provide you with the certification to grace your Resume but also the necessary training to back it up. Apart from the ease of self-paced courses, we will also provide you with live interactive sessions with our experienced trainers. They will provide you with practical knowledge that will significantly improve your skill-set and train you for the job market. "]
        },
        {
          id:2,
          title: "CERTIFICATION",
          expanded: false,
          items:["Microsoft Certified: Azure AI Fundamentals", "Microsoft certifications give you a professional advantage by providing globally recognized and industry-endorsed evidence of mastering skills in machine learning and artificial intelligence. This Microsoft certificate will demonstrate knowledge of common ML and AI workloads and how to implement them on Azure."]

        },
        {
          id:3,
          title: "INTERNSHIP",
          expanded: false,
          items:["We offer our learners project-based internships that will help them gain real-life experience of practically working on the concepts they learnt during their certification. These internships not only add more weightage to your experience but also act as an entry point into the industry. It is a real-life experience like no other which will equip you well for whatever you decide to do next!"]

        }
      ]);

    ///////////////////////////
    //handleClick
    const [open, setOpen] = useState(false)


  const handleClick = (id) => {
    console.log("clicked");
    let newtables = [...tables];
    newtables[id - 1].expanded = !newtables[id - 1].expanded;
    settables(newtables);
  }
  return (
    <div className="HowDoesItWork">
      <div className="HowDoesItWork-header">
        <div className="HowDoesItWork-header-title text-bold">How Does It Work</div>
        <div className="HowDoesItWork-header-subtitle">How do we help students to land thier dream job </div>
      </div>
      <div className="HowDoesItWork-container">
        <div className="HowDoesItWork-container-left">
          <img src={work} alt="" />
        </div>
        <div className="HowDoesItWork-container-right">
          {
            tables.map(table => <Table key={table.id} table={table} handleClick={handleClick} open={open} setOpen={setOpen} />)
          }
        </div>
        <CirriculumModal open={open} setOpen={setOpen} data={data[4]?.data} />
      </div>

    </div>
  )
}

export default HowDoesItWork;

const TableCpy = ({ table, handleClick, open, setOpen }) => {
  return (
    <div className="worktable">
      <div className="worktable-header text-bold">
        <span>{table.title}</span>
        {
          table.expanded ?
            <img onClick={() => handleClick(table.id)} className={table.expanded ? "vector" : "rotated vector"} src={Minus} alt="" /> :
            <img onClick={() => handleClick(table.id)} className={table.expanded ? "vector" : "rotated vector"} src={Plus} alt="" />
        }

      </div>
      <div className={table.expanded ? "worktable-desc" : "hidden worktable-desc"}>
        {
          table.items.map((item, idx) => <p key={idx} className="worktable-desc-item">{item}</p>)
        }
        {table.title === 'TRAINING' ? <Button type="accentsecondary" text="VIEW CURRICULUM" onClick={() => setOpen(!open)} /> : null}
      </div>
    </div>
  )
}
