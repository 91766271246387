/* eslint-disable default-case */
import React, { useState } from "react";
import "../LMSSection.scss";

// Components
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import Popover from "@mui/material/Popover";
import Modal from "../../../../components/Modal/Modal";
import Button from "../../../../../components/botton/button";

import QuizCard from "../QuizCard/QuizCard";
import { useHistory, useParams } from "react-router-dom";

function WeekCard({ week, weeks, setWeeks }) {
  // console.log(week.routines);

  // Structure of Routines Object comming from the server
  // quiz: [{questions:[{correct_answer:"",explanation:"",name:"", other_options:["","",""]}], title:""}]
  // reading:[{title:"", description:""}]
  // sample_paper:[{elemets:[{},{}], title:""}]
  // sessisons:[{link:"", name: ""}]

  const [isMouseOver, setisMouseOver] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSession, setShowSession] = useState(false);
  const { certificateID } = useParams();
  let history = useHistory();
  const handleMouseOver = () => {
    setisMouseOver(true);
  };
  const handleMouseOut = () => {
    setisMouseOver(false);
  };

  const handleEdit = (element, index) => {
    switch (element) {
      case "quiz":
        //TODO: Save the existing data and then redirect to the quiz page
        history.push(
          `/admin/dashboard/quiz/${certificateID}?week=${week.week}&update=${index}`
        );
        break;
      case "reading":
        //TODO: Save the existing data and then redirect to the reading page
        history.push(
          `/admin/dashboard/reading/${certificateID}?week=${week.week}&update=${index}`
        );
        break;
      case "sample_paper":
        //TODO: Open the popup of sample paper
        break;
      case "sessions":
        //TODO: Open the popup of sample paper
        break;
    }
  };
  const handleRemove = (element, index) => {
    let weekIndex = week.week - 1;
    let newWeeks = [...weeks];
    console.log(newWeeks);
    switch (element) {
      case "quiz":
        newWeeks[weekIndex].routines.quiz.splice(index, 1);
        break;
      case "reading":
        newWeeks[weekIndex].routines.reading.splice(index, 1);
        break;
      case "sample_paper":
        newWeeks[weekIndex].routines.sample_paper.splice(index, 1);
        break;
      case "sessions":
        newWeeks[weekIndex].routines.sessions.splice(index, 1);
        break;
    }
    console.log("After Remove: ", newWeeks);
    setWeeks(newWeeks);
  };
  const handleAdd = (element) => {
    switch (element) {
      case "quiz":
        //TODO: Save the existing data and then redirect to the quiz page
        history.push(
          `/admin/dashboard/quiz/${certificateID}?week=${week.week}`
        );
        break;
      case "reading":
        //TODO: Save the existing data and then redirect to the reading page
        history.push(
          `/admin/dashboard/reading/${certificateID}?week=${week.week}`
        );
        break;
      case "sample_paper":
        //TODO: Open the popup of sample paper
        break;
      case "sessions":
        //TODO: Open the popup of sample paper
        setShowSession(true);
        setisMouseOver(null);
        setAnchorEl(null);
        break;
    }
  };

  return (
    <div
      className="weekCard-single"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <span className="weekCard-single-header">Week {week.week}</span>
      {week.routines && (
        <div className="weekCard-single-list">
          {/* QUIZES */}
          {week.routines.quiz && week.routines.quiz.length > 0 && (
            <>
              {week.routines.quiz.map((quiz, index) => (
                <QuizCard
                  key={index}
                  data={quiz}
                  name="QUIZ"
                  title={quiz.title}
                  handleEdit={() => handleEdit("quiz", index)}
                  handleRemove={() => handleRemove("quiz", index)}
                />
              ))}
            </>
          )}
          {/* READINGS */}
          {week.routines.reading && week.routines.reading.length > 0 && (
            <>
              {week.routines.reading.map((reading, index) => (
                <QuizCard
                  key={index}
                  data={reading}
                  name="READING"
                  title={reading.title}
                  handleEdit={() => handleEdit("reading", index)}
                  handleRemove={() => handleRemove("reading", index)}
                />
              ))}
            </>
          )}
          {/* SAMPLE PAPERS */}
          {week.routines.sample_paper && week.routines.sample_paper.length > 0 && (
            <>
              {week.routines.sample_paper.map((samplePaper, index) => (
                <QuizCard
                  key={index}
                  data={samplePaper}
                  name="SAMPLE PAPER"
                  title={samplePaper.title}
                  handleEdit={() => handleEdit("sample_paper", index)}
                  handleRemove={() => handleRemove("sample_paper", index)}
                />
              ))}
            </>
          )}
          {/* SESSIONS/VIDEOS */}
          {week.routines.sessions && week.routines.sessions.length > 0 && (
            <>
              {week.routines.sessions.map((session, index) => (
                <QuizCard
                  key={index}
                  data={session}
                  name="VIDEO"
                  title={session.name}
                  handleEdit={() => handleEdit("sessions", index)}
                  handleRemove={() => handleRemove("sessions", index)}
                />
              ))}
            </>
          )}
        </div>
      )}
      <AddPopover
        isMouseOver={isMouseOver}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      >
        <div
          className="add-popover-content"
          onClick={() => handleAdd("sessions")}
        >
          Session
        </div>
        <div
          className="add-popover-content"
          onClick={() => handleAdd("reading")}
        >
          Reading
        </div>
        <div className="add-popover-content" onClick={() => handleAdd("quiz")}>
          Quiz
        </div>
        <div
          className="add-popover-content"
          onClick={() => handleAdd("sample_paper")}
        >
          Sample Paper
        </div>
      </AddPopover>
      <SessionModal showModal={showSession} setShowModal={setShowSession} />
    </div>
  );
}

const SessionModal = ({
  showModal,
  setShowModal,
  data = {},
  setData = (e) => e,
  handleAddVideo = (e) => e,
}) => {
  return (
    <Modal
      title="My Modal"
      onClose={() => setShowModal(false)}
      show={showModal}
    >
      <h4>data.title</h4>
      <input
        type="text"
        className="input-field"
        placeholder="Name of the session"
        value={data.name}
        onChange={(e) => setData(e.target.value)}
      />
      <input
        type="text"
        className="input-field"
        placeholder="Link to the video"
        value={data.link}
        onChange={(e) => setData(e.target.value)}
      />
      <Button type="primary" text="UPDATE" onClick={handleAddVideo} />
    </Modal>
  );
};


const AddPopover = ({ children, isMouseOver, anchorEl, setAnchorEl }) => {
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {anchorEl && <span className="black-coverover"></span>}
      <div
        className="add-popover-btn"
        style={
          isMouseOver || anchorEl
            ? anchorEl
              ? { visibility: "visible", transform: "rotate(45deg)" }
              : { visibility: "visible" }
            : null
        }
      >
        {/* <span > */}
        <SpeedDialIcon
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
        />
        {/* </span> */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          {children}
        </Popover>
      </div>
    </>
  );
};

export default WeekCard;
