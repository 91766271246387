import React from 'react'
import Header from '../components/layout/Header/header';
import Footer from '../components/layout/Footer/Footer';
import FAQ from '../components/layout/FAQ/FAQ';
function PrivacyPage() {
  return <div>
  <Header />
  <FAQ header={'Privacy Policy'} />
  <Footer />
</div>;
}

export default PrivacyPage