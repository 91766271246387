import React from 'react';
import './CourseCard.scss';
import { ReactComponent as Enrolled } from '../../assests/Graphics/Icons/Enrolled.svg';
import { Link } from 'react-router-dom';


const CourseCard = ({ trending, name, img, enrolled, href }) => {
    return (
        <div className="CourseCard">
            <Link to={href}>
                <div className="CourseCard-img">
                    <img width={293} src={img} alt="" />
                    {
                        trending ?
                            <div className="CourseCard-img-trending color-accent-2">
                                Trending
                            </div> : ""
                    }

                </div>
                <div className="CourseCard-desc">
                    <div className="CourseCard-desc-title text-bold">{name}</div>
                    <div className="CourseCard-desc-subtitle text-bold">essential</div>
                </div>
            </Link>
        </div>
    )
}

export default CourseCard;