/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./QuizPage.scss";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import { ReactComponent as UploadQuizIcon } from "../../assets/icons/upload-quiz-icon.svg";
import Button from "../../../components/botton/button";
import ProfileInfo from "../../components/ProfileInfo/ProfileInfo";
import { getProgramById, updateProgram } from "../../helpers/adminapicall";
import UploadMethodButton from "../../components/Utilities/UploadMethodButton/UploadMethodButton";

const QuizPage = ({ quiz = {}, setQuiz = (e) => e }) => {
  const [quizData, setQuizData] = useState({ ...quiz });
  const { certificateID } = useParams();
  const queryParams = new URLSearchParams(useLocation().search);
  const week = queryParams.get("week") > 0 ? queryParams.get("week") - 1 : null;
  const updateIndex =
    queryParams.get("update") > 0 ? queryParams.get("update") - 1 : null;
  let history = useHistory();

  const [isValid, setIsValid] = useState(false);

  const [programObj, setProgramObj] = useState(null);

  useEffect(() => {
    getProgramById(certificateID).then((res) => {
      setProgramObj({
        certificate: res.data.certificate,
        lms: res.data.lms,
      });
    });
  }, []);

  const checkIsValid = () => {
    programObj.lms.weeks[week]
      ? programObj.lms.weeks[week].routines.reading[updateIndex]
        ? setIsValid(true)
        : setIsValid(false)
      : setIsValid(false);
  };

  useEffect(() => {
    if (updateIndex >= 0 && week >= 0 && programObj) {
      checkIsValid();
      if (isValid) {
        // TODO: get old data and store it in state
        console.log(programObj.lms.weeks[week]);
      }
    }
  }, [programObj]);

  // const handleSave = () => {
  //   let updatedLMS = programObj.lms;

  //   if (isValid) {
  //     //  Update old reading
  //     //updatedLMS.weeks[week].routines.reading[updateIndex].title = title;
  //     //updatedLMS.weeks[week].routines.reading[updateIndex].description =
  //     // editorData;
  //   } else {
  //     //Push new reading
  //     //TODO: Add a check for week is present or not in the url
  //     if (week) {
  //       updatedLMS.weeks[week].routines.reading.push({
  //         // title,
  //         // description: editorData,
  //       });
  //     }
  //   }

  //   // Finally update the program
  //   let newProgramObj = {
  //     certificates: [
  //       {
  //         certificate_name: programObj.certificate.certificateName,
  //         certificate_description:
  //           programObj.certificate.certificateDescription,
  //         certificate_image: programObj.certificate.certificateImage,
  //         certificate_link: programObj.certificate.certificateLink,
  //         template_image: programObj.certificate.templateImage,
  //         key_highlights: programObj.certificate.key_highlights,
  //         skills: programObj.certificate.skills,
  //         jobs: programObj.certificate.jobs,
  //         exams: programObj.certificate.exams,
  //         certificateFeatures: programObj.certificate.certificateFeatures,
  //       },
  //     ],
  //     lms: [updatedLMS],
  //   };
  //   updateProgram(certificateID, newProgramObj).then((res) => {
  //     console.log("Certificate Updated");
  //     history.push(`/admin/dashboard/manage-certificate/${certificateID}/lms`);
  //   });
  // };

  return (
    <>
      <div className="quiz-nav quiz-container">
        <Link to={`/admin/dashboard/manage-certificate/${certificateID}/lms`}>
          <BackIcon />
        </Link>
        <ProfileInfo />
      </div>

      <div
        className="quiz-container quiz-body"
        style={{ backgroundColor: "#E8E8E8" }}
      >
        <div
          className="flex-align-center"
          style={{
            justifyContent: "space-between",
            minHeight: "5.1rem",
            padding: "1rem 0",
          }}
        >
          <span className="text-accent-2">Quiz - 1</span>
          {quiz !== quizData && <Button type="primary" text="COMMIT CHANGES" />}
        </div>
        {/* Quiz */}
        <div
          className="quiz-container quiz-card-conatiner flex-all-center flex-column"
          style={{ gap: "2rem" }}
        >
          <QuizDefault quizData={quizData} setQuizData={setQuizData} />
        </div>
      </div>
    </>
  );
};

const QuizDefault = ({ quizData, setQuizData }) => {
  const [quizMethod, setQuizMethod] = useState("");
  const [quizName, setQuizName] = useState("");

  const [uploadMethod, setUploadMethod] = useState(null);

  const toggleUploadMethod = () => {
    if (uploadMethod === "csv") {
      setUploadMethod("manual");
    } else if (uploadMethod === "manual") {
      setUploadMethod("csv");
    }
  };

  const handleUploadCSV = (e) => {
    e.preventDefault();
    if (uploadMethod === "csv") {
      //
    }
  };
  const handleManualCSV = (e) => {
    e.preventDefault();
    if (uploadMethod === "manual") {
      //
    }
  };
  const handleProceed = (e) => {
    e.preventDefault();
  };

  // const toggleQuizMethod = (method) => {
  //   if (method === "manual") {
  //     setQuizMethod("manual");
  //   } else if (method === "auto") {
  //     setQuizMethod("auto");
  //   }
  // };

  // const handleProceed = (e) => {
  //   e.preventDefault();
  //   setQuizData({ ...quizData, name: quizName, method: quizMethod });
  // };

  return (
    <>
      <input
        value={quizName}
        onChange={(e) => {
          setQuizName(e.target.value);
        }}
        type="text"
        className="input-field"
        placeholder="Name of the quiz"
        style={{ width: "100%", maxWidth: "50rem" }}
      />
      {/* <div className="flex-align-start" style={{ gap: "2rem" }}>
        <UploadQuizButton
          toggleQuizMethod={toggleQuizMethod}
          quizMethod={quizMethod}
          type="manual"
        />
        <div className="flex-align-center flex-column" style={{ gap: "1rem" }}>
          <UploadQuizButton
            toggleQuizMethod={toggleQuizMethod}
            quizMethod={quizMethod}
            type="auto"
          />
          <div className="download-text">DOWNLOAD SAMPLE</div>
        </div>
      </div>
      <Button
        type={quizName && quizMethod ? "primary" : "disabled"}
        text="PROCEED"
        onClick={handleProceed}
      /> */}
      {/* Upload Buttons */}
      <div className="flex-align-start" style={{ gap: "2rem" }}>
        <UploadMethodButton
          setUploadMethod={setUploadMethod}
          uploadMethod={uploadMethod}
          type="manual"
        />
        <div className="flex-align-center flex-column" style={{ gap: "1rem" }}>
          <UploadMethodButton
            setUploadMethod={setUploadMethod}
            uploadMethod={uploadMethod}
            type="csv"
          />
          <div className="download-text">DOWNLOAD SAMPLE</div>
        </div>
      </div>
      <Button
        type={uploadMethod && quizName ? "primary" : "disabled"}
        text="PROCEED"
        onClick={handleProceed}
      />
    </>
  );
};

const UploadQuizButton = ({ toggleQuizMethod, quizMethod, type }) => {
  const [selectedCSV, setSelectedCSV] = useState(null);
  const [error, setError] = useState(null);

  const allowedTypes = ["image/png", "image/jpeg", "csv"];

  const handleUpload = (e) => {
    let selected = e.target.files[0];

    if (selected && allowedTypes.includes(selected.type)) {
      setSelectedCSV(selected);
      setError("");
    } else {
      setSelectedCSV(null);
      setError("Please select an CSV files only");
    }
  };

  return (
    <div
      className={`quiz-upload-btn ${
        selectedCSV && quizMethod === type ? "quiz-upload-btn-selected" : ""
      }`}
      onClick={() => toggleQuizMethod(type)}
    >
      <div>
        <UploadQuizIcon />
      </div>
      <input type="file" name="Select image" onChange={handleUpload} />
      UPLOAD CSV
    </div>
  );
};

// const NewQuizButton = () => {
//   return <></>;
// };

const MakeQuiz = () => {};
// const QuizDefault = () => {};

export default QuizPage;
