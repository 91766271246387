import { CHANGE_NAME } from "../actions/actionTypes";

const initialState = {
  name: "FOXMULA",
  age: 24,
};

const changeNameReducer = (state = initialState, action) => {
    switch (action.type) {
      case CHANGE_NAME:
        return {
          ...state,
          name: action.payload,
        };
      default:
        return {
          ...state,
        };
    }
  };
  
  export default changeNameReducer;