import React from 'react';
import Button from "../../../components/botton/button.jsx";
import HomePageAboutVideoXL from "../../../assests/images/HomePageAboutVideoXL.png";
import "./About.scss";
import { useHistory } from 'react-router-dom';
import HER from '../../../assests/images/HER_logo.png'
import BW from '../../../assests/images/BW_logo.png'
import INC from '../../../assests/images/INC_logo.jpg'
import YS from '../../../assests/images/YS_logo.png'
import CIIE from '../../../assests/images/CIEE_logo.png'
import CEO from '../../../assests/images/CEO_logo.png'
import ECO from '../../../assests/images/ECO_logo.png'
import STR from '../../../assests/images/STARTUP_logo.png'
const About = () => {
  const history = useHistory()
  return (
    <div>
      <div className="outside-container">
        <div className="container justify-content-center">
          <h2 className=" about-heading text-bold">A little about us</h2>
          <p className="text-regular about-desc-before-image">
            There's no end to learning but there are endless beginnings. We
            bring you an exciting hamper of now.
          </p>
          <img
            src={HomePageAboutVideoXL}
            alt="video about us"
            className="video"
            style={{
              display: 'inline-block',
              height: 'auto',
            }}
          />
          <p className="about-desc-after-image">
            From hiring fresh talent to training your busy bees, we make the
            hive work as smooth and efficient as it can be. We hand pick the
            cream of the crop and train them to exceed all company expectations.
            Offshoring, outsourcing, direct and indirect hiring. We do it all
            for you.
          </p>
          <div className="dummy-container">
            <div className="dummyitem" onClick={()=>window.open("https://inc42.com/features/30-startups-to-watch-the-startups-that-caught-our-eye-in-october-2020/")}><img src={INC} style={{ width: '100%', height: '100%' }} alt=""></img> </div>
            <div className="dummyitem" onClick={()=>window.open("https://yourstory.com/2019/07/edtech-startup-bengaluru-upskilling-engineering-graduates/amp")}><img src={YS} style={{ width: '100%', height: '100%' }} alt=""></img> </div>
            <div className="dummyitem" onClick={()=>window.open("http://bweducation.businessworld.in/article/5-Emerging-Career-Options-To-Choose-From/26-06-2020-291344/")}> <img src={BW} style={{ width: '100%', minHeight: '100%' }} alt=""></img></div>
            <div className="dummyitem" onClick={()=>window.open("https://www.linkedin.com/feed/update/urn:li:activity:6612265883375108096/")}><img src={CIIE} style={{ width: '100%', minHeight: '100%' }} alt=""></img> </div>
            <div className="dummyitem" onClick={()=>window.open("https://www.ceoinsightsindia.com/leader/foxmula-the-smart-way-to-grow-academically--cid-1390.html")}><img src={CEO} style={{ width: '100%', minHeight: '100%' }} alt=""></img> </div>
            <div className="dummyitem" onClick={()=>window.open("https://economictimes.indiatimes.com/magazines/panache/this-ceo-feels-books-help-change-perspective-in-life-spends-sundays-reading-sipping-on-hot-cocoa/articleshow/78718614.cms?utm_source%3Dlinkedin_web%26utm_medium%3Dsocial%26utm_campaign%3Dsocialsharebuttons")}><img src={ECO} style={{ width: '100%', height: '100%' }} alt=""></img> </div>
            <div className="dummyitem" onClick={()=>window.open("https://highereducationplus.com/horses-for-courses/")}><img src={HER} style={{ width: '100%', minHeight: '100%' }} alt=""></img> </div>
            <div className="dummyitem" onClick={()=>window.open("https://startuptalky.com/foxmula-success-story/")}><img src={STR} style={{ width: '100%', minHeight: '100%' }} alt=""></img> </div>
          </div>
            <Button customStyle={{width:'10%', height:'10%', margin:'0 auto', marginBottom:'20px'}} type="primary" text="KNOW MORE" onClick={() => history.push("/about")} />


        </div>
      </div>
    </div>
  )
}

export default About;
