import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Timer } from './Timer';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import PhoneInput, {
	getCountryCallingCode,
	isPossiblePhoneNumber,
	parsePhoneNumber,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './LoginInput.css';
import axios from 'axios';

export default function AddressForm({
	setState,
	state,
	error,
	setError,
	userDetails,
	email,
	setEmail,
	newUser,
	setNewUser,
	showEmail,
	setShowEmail,
}) {
	console.log('state:', state);
	const [verified, setVerified] = React.useState(false);
	const [phone, setPhone] = React.useState('');
	const [otpDisable, setOtpDisable] = React.useState(false);
	const [resendDisable, setResendDisable] = React.useState(false);
	const [userOtp, setUserOtp] = React.useState(['', '', '', '']);
	const [otpSent, setOtpSent] = React.useState(false);
	const [resend, setResend] = React.useState(false);
	const [otp, setOTP] = React.useState('');
	const [isLoggedIn, setIsLoggedIn] = React.useState(false);
	const [name, setName] = React.useState(
		userDetails?.name ? userDetails?.name : ''
	);
	const [checkEmail, setCheckEmail] = React.useState(false);
	const [checkUser, setCheckUser] = React.useState(false);
	// const [newUser, setNewUser] = React.useState(false);
	// const [email, setEmail] = React.useState('');

	const getOtp = () => {
		if (!name) {
			alert('Please fill your name');
		} else if (!otpDisable) {
			setOtpDisable(true);
			console.log(phone);
			// if (phone?.length === 10) {
			if (isPossiblePhoneNumber(phone) && !phone?.length < 10) {
				axios
					// .post('http://localhost:8889/user/checkout/get-otp', {
					.post('/user/checkout/get-otp', {
						phone: phone,
						countryCode: getCountryCallingCode(
							parsePhoneNumber(phone).country
						),
					})
					.then((res) => {
						console.log(res);
						// setShowEmail(!res?.data?.emailFound);
						setCheckEmail(!res?.data?.emailFound);
						// setNewUser(res?.data?.newUser);
						setCheckUser(res?.data?.newUser);
						setOTP(res?.data?.Otp);
						setOtpSent(true);
						setOtpDisable(false);
					})
					.catch((err) => {
						console.log(err);
						console.log(err.message);
						setOtpDisable(false);
					});
			} else {
				setError({ ...error, phone: true });
				setOtpDisable(false);
				alert('Please enter correct phone number!!');
			}
		}
	};

	const resendOtp = () => {
		// if (phone?.length === 10 && resend && !resendDisable) {
		if (
			isPossiblePhoneNumber(phone) &&
			resend &&
			!resendDisable &&
			!phone?.length < 10
		) {
			setResendDisable(true);
			setResend(false);
			axios
				.post('/user/checkout/get-otp', {
					phone: phone,
					countryCode: getCountryCallingCode(
						parsePhoneNumber(phone).country
					),
				})
				.then((res) => {
					// setShowEmail(!res?.data?.emailFound);
					setCheckEmail(!res?.data?.emailFound);
					// setNewUser(res?.data?.newUser);
					setCheckUser(res?.data?.newUser);
					setOTP(res?.data?.Otp);
					setOtpSent(true);
				})
				.catch((err) => {
					setResendDisable(false);
					setResend(true);
				});
		} else if (!isPossiblePhoneNumber(phone)) {
			setError({ ...error, phone: true });
			alert('Please enter correct phone number!!');
		} else if (resendDisable) {
			alert('Wait for timer!!');
		}
	};

	React.useEffect(() => {
		if (window.localStorage.getItem('uid')) {
			axios
				.get(`/checkEmail/${window.localStorage.getItem('uid')}`)
				.then((res) => {
					console.log(res?.data);
					setShowEmail(!res?.data?.emailFound);
				})
				.catch((error) => {
					console.log(error);
				});
			setIsLoggedIn(true);
			if (userDetails?.phone[0] === '+') {
				setPhone(userDetails?.phone);
			} else {
				setPhone('+91' + userDetails?.phone);
			}
		}
		console.log('this is user otp', userOtp);
		if (
			!isLoggedIn &&
			userOtp?.length === 4 &&
			!isNaN(userOtp[0]) &&
			!isNaN(userOtp[1]) &&
			!isNaN(userOtp[2]) &&
			!isNaN(userOtp[3])
		) {
			if (
				otp ===
				Number(`${userOtp[0]}${userOtp[1]}${userOtp[2]}${userOtp[3]}`)
			) {
				setVerified(true);
				setError({ ...error, otp: false });
				console.log(newUser, name);
				setShowEmail(checkEmail);
				setNewUser(checkUser);
				axios
					// .post('http://localhost:8889/checkout/signInViaOtp', {
					.post('/checkout/signInViaOtp', {
						phone: phone,
						countryCode: getCountryCallingCode(
							parsePhoneNumber(phone).country
						),
						newUser: checkUser,
						name,
					})
					.then((res) => {
						console.log(res);
						window.localStorage.setItem('token', res?.data?.token);
						window.localStorage.setItem(
							'uid',
							res?.data?.user?.uid
						);
						setIsLoggedIn(true);
						// window.location.reload(false);
					})
					.catch((err) => {
						console.log('error:', err);
					});
			} else {
				setVerified(false);
				setError({ ...error, otp: true });
			}
		}
	}, [userOtp, otp]);

	let stateList = [
		'Andhra Pradesh',
		'Arunachal Pradesh',
		'Assam',
		'Bihar',
		'Chhattisgarh',
		'Goa',
		'Gujarat',
		'Haryana',
		'Himachal Pradesh',
		'Jammu and Kashmir',
		'Jharkhand',
		'Karnataka',
		'Kerala',
		'Madhya Pradesh',
		'Maharashtra',
		'Manipur',
		'Meghalaya',
		'Mizoram',
		'Nagaland',
		'Odisha',
		'Punjab',
		'Rajasthan',
		'Sikkim',
		'Tamil Nadu',
		'Telangana',
		'Tripura',
		'Uttarakhand',
		'Uttar Pradesh',
		'West Bengal',
		'Andaman and Nicobar Islands',
		'Chandigarh',
		'Dadra and Nagar Haveli',
		'Daman and Diu',
		'Delhi',
		'Lakshadweep',
		'Puducherry',
	];

	return (
		<>
			<Typography variant='h6' gutterBottom>
				Create your account:
				{/* Fill out the details: */}
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<TextField
						required
						id='name'
						name='Name'
						label='Name'
						fullWidth
						autoComplete='given-name'
						variant='standard'
						value={name}
						defaultValue={userDetails?.name}
						onChange={(e) => setName(e.target.value)}
					/>
				</Grid>
				<Grid 
					item 
					xs={12}
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<div
						style={{
							width: isLoggedIn ? '100%' : '60%'
						}}
					>
						{isLoggedIn && (
							<Chip
								icon={<DoneIcon />}
								label='Verified'
								color='success'
								// variant='outlined'
								size='small'
							/>
						)}
						<PhoneInput
							placeholder='Phone Number'
							value={phone}
							onChange={setPhone}
							defaultCountry='IN'
							style={{
								marginBottom: !isLoggedIn ? '10px' : '',
								marginTop: !isLoggedIn ? '10px' : '7px',
								position: 'relative',
								// width: '60%'
							}}
							disabled={verified}
						/>
					</div>
					{!isLoggedIn && (
						<div
							// className={`d-flex justify-space-between gap-2 align-items-center mb-1`}
							style={{
								width: '40%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							{!otpSent && !verified && (
								<Button
									onClick={() => getOtp()}
									variant={'outlined'}
								>
									Get Otp
								</Button>
							)}

							{/* This is a timer for otp */}
							{otpSent && !verified && (
								<>
									<Button
										onClick={() => resendOtp()}
										variant={'outlined'}
									>
										Resend
									</Button>
									<div
										// className={`${styles.otp_btn}`}
										style={{
											border: 'none',
											fontSize: '16px',
											marginLeft: '6px',
										}}
									>
										{!resend && (
											<Timer
												minute={1}
												second={0}
												setResend={setResend}
												setResendDisable={
													setResendDisable
												}
											/>
										)}
									</div>
								</>
							)}
						</div>
					)}
				</Grid>
				{!isLoggedIn && (
					<Grid item xs={12}>
						<div
							className={`d-flex justify-space-between gap-1 align-items-center`}
							style={{ marginBottom: verified ? '6px' : '' }}
						>
							<label
								htmlFor='name'
								style={{
									marginRight: '6px',
									marginBottom: '0',
								}}
							>
								Verify OTP{' '}
							</label>
							{/* {verified && (
								<Chip
									icon={<DoneIcon />}
									label='Verified'
									color='success'
									// variant='outlined'
									size='small'
								/>
							)} */}
						</div>
						<Grid container spacing={1}>
							<Grid item xs={3} sm={3} style={{ maxWidth: '60px' }}>
								<input
									// type="number"
									type='tele'
									style={{
										maxWidth: '50px',
										borderRadius: '28px',
										minHeight: '40px',
										backgroundColor: '#f5f5f7',
										color: '#36335d',
										textAlign: 'center',
										border: (verified || !otpSent) ? '2px solid #1976d2' : '2px solid rgb(244, 67, 54)',
									}}
									name='otp-digit1'
									data-name='otp'
									id='otp1'
									required
									value={userOtp[0]}
									disabled={verified}
									onChange={(e) => {
										let u = [...userOtp];
										u[0] = e.target.value;
										setUserOtp(u);
										document.getElementById('otp2').focus();
									}}
								/>
							</Grid>
							<Grid item xs={3} sm={3} style={{ maxWidth: '60px' }}>
								<input
									// type="number"
									type='tele'
									style={{
										maxWidth: '50px',
										borderRadius: '28px',
										minHeight: '40px',
										backgroundColor: '#f5f5f7',
										color: '#36335d',
										textAlign: 'center',
										border: (verified || !otpSent) ? '2px solid #1976d2' : '2px solid rgb(244, 67, 54)',
									}}
									name='otp-digit2'
									data-name='otp'
									id='otp2'
									required
									disabled={verified}
									value={userOtp[1]}
									onChange={(e) => {
										let u = [...userOtp];
										u[1] = e.target.value;
										setUserOtp(u);
										document.getElementById('otp3').focus();
									}}
								/>
							</Grid>
							<Grid item xs={3} sm={3} style={{ maxWidth: '60px' }}>
								<input
									// type="number"
									type='tele'
									style={{
										maxWidth: '50px',
										borderRadius: '28px',
										minHeight: '40px',
										backgroundColor: '#f5f5f7',
										color: '#36335d',
										textAlign: 'center',
										border: (verified || !otpSent) ? '2px solid #1976d2' : '2px solid rgb(244, 67, 54)',
									}}
									name='otp-digit3'
									data-name='otp'
									id='otp3'
									disabled={verified}
									required
									value={userOtp[2]}
									onChange={(e) => {
										let u = [...userOtp];
										u[2] = e.target.value;
										setUserOtp(u);
										document.getElementById('otp4').focus();
									}}
								/>
							</Grid>
							<Grid item xs={3} sm={3} style={{ maxWidth: '60px' }}>
								<input
									// type="number"
									type='tele'
									style={{
										maxWidth: '50px',
										borderRadius: '28px',
										minHeight: '40px',
										backgroundColor: '#f5f5f7',
										color: '#36335d',
										textAlign: 'center',
										border: (verified || !otpSent) ? '2px solid #1976d2' : '2px solid rgb(244, 67, 54)',
									}}
									name='otp-digit4'
									data-name='otp'
									id='otp4'
									disabled={verified}
									required
									value={userOtp[3]}
									onChange={(e) => {
										let u = [...userOtp];
										u[3] = e.target.value;
										setUserOtp(u);
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				)}
				{(newUser || showEmail) && (
					<Grid item xs={12}>
						<TextField
							required={newUser || showEmail}
							id='email'
							name='Email'
							fullWidth
							autoComplete='given-name'
							variant='standard'
							defaultValue={userDetails?.email}
							label='Email'
							value={email}
							onChange={(e) => setEmail((e.target.value).toLowerCase())}
						/>
					</Grid>
				)}
				{isLoggedIn && (
					<React.Fragment>
						<Grid item xs={12}>
							<FormControl
								variant='standard'
								sx={{ minWidth: '100%' }}
							>
								<InputLabel id='demo-simple-select-standard-label'>
									State/Province/Region
								</InputLabel>
								<Select
									labelId='demo-simple-select-standard-label'
									id='demo-simple-select-standard'
									value={state}
									onChange={(e) => setState(e.target.value)}
									label='State/Province/Region'
								>
									<MenuItem value=''>
										<em>None</em>
									</MenuItem>
									{stateList.map((state, index) => (
										<MenuItem key={index} value={state}>
											{state}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<TextField
								aria-readonly
								id='country'
								name='country'
								label='Country'
								fullWidth
								autoComplete='shipping country'
								variant='standard'
								value={'India'}
							/>
						</Grid>
					</React.Fragment>
				)}
			</Grid>
		</>
	);
}
