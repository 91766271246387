import React, { useState } from "react";

//Icons
import { ReactComponent as MakeQuizIcon } from "../../../assets/icons/make-quiz-icon.svg";
import { ReactComponent as UploadQuizIcon } from "../../../assets/icons/upload-quiz-icon.svg";

//TODO: handleCSV and update data in array
const UploadMethodButton = ({ setUploadMethod, uploadMethod, type }) => {
  const [selectedCSV, setSelectedCSV] = useState(null);
  const allowedTypes = ["image/png", "image/jpeg", "csv"];

  const handleUpload = (e) => {
    let selected = e.target.files[0];

    if (selected && allowedTypes.includes(selected.type)) {
      setSelectedCSV(selected);
      setUploadMethod("csv");
    } else {
      setUploadMethod(null);
      setSelectedCSV(null);
    }
  };
  const handleSelectedMethod = () => {
    if (uploadMethod === type) {
      setUploadMethod(null);
      setSelectedCSV(null);
    } else if (type === "manual") {
      setUploadMethod(type);
    }
  };

  if (type === "csv") {
    return (
      <div
        className={`quiz-upload-btn ${
          selectedCSV && uploadMethod === type ? "quiz-upload-btn-selected" : ""
        }`}
      >
        <div>
          <UploadQuizIcon />
        </div>
        <input type="file" name="Select image" onChange={handleUpload} />
        UPLOAD CSV
      </div>
    );
  } else {
    return (
      <div
        className={`quiz-upload-btn ${
          uploadMethod === type ? "quiz-upload-btn-selected" : ""
        }`}
        onClick={handleSelectedMethod}
      >
        <div>
          <MakeQuizIcon />
        </div>
        MANUAL INPUT
      </div>
    );
  }
};

export default UploadMethodButton;
