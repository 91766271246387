import React from "react";
import { StyledEngineProvider } from "@mui/material/styles";

import Popover from "@mui/material/Popover";

import { ReactComponent as VerticalMenu } from "../../assets/icons/menu-vertical-icon.svg";

export default function TransitionsPopper({ children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <StyledEngineProvider injectFirst>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <VerticalMenu
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div sx={{ p: 2 }}>{children}</div>
        </Popover>
      </div>
    </StyledEngineProvider>
  );
}
