import React, { useState, useEffect } from "react";
import "./AllJobs.scss";
import { ReactComponent as DownArrow } from "../../../assests/Graphics/Icons/DownArrow.svg";
import logo from "../../../assests/images/logo.png";
// import Button from "../../../components/botton/button";
import Button from '@mui/material/Button';
import JobCard from "../JobCard/JobCard";
import { DropMenu } from "../DropMenu/DropMenu";
import ActiveJob from "../ActiveJobs/ActiveJob";
import Filter from "../Filter/Filter";
import Header from "../../../components/layout/Header/header";
import Footer from "../../../components/layout/Footer/Footer";
import { useParams } from "react-router";
import axios from "axios";
import Box from '@mui/material/Box';
import { Job } from "../../SingleJob/SingleJob"



import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import useMediaQuery from '@mui/material/useMediaQuery';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const AllJobs = () => {

  const locationData = ["All"]

  const [jobsInfo, setJobsInfo] = useState([])

  useEffect(() => {
    axios
      // .get(`https://www.back.foxmula.com/allJobs`, 0
      .get(`/allJobs`, 0
      ).then((res) => {
        const data = res.data.allJobs
        setJobsInfo(data)
        setActive(data[0])
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])



  // const [searchResult, setsearchResult] = useState({});
  const { searchInput, loc } = useParams(); //get url parameters
  const [save, setSave] = useState(false);
  const [jobType, setJobType] = useState('All');
  const [partTime, setPartTime] = useState('All');
  const [pay, setPay] = useState('All');
  const [duration, setDuration] = useState('All');
  const [timePosted, setTimePosted] = useState('All');
  const [active, setActive] = useState([]);
  const [location, setLocation] = useState('All');
  const [open, setOpen] = useState(false);
  const [jobSearchInput, setjobSearchInput] = useState('All');


  const matches = useMediaQuery('(min-width:600px)');


  const filters = [
    {
      id: 1,
      role: "Type of Job",
      name: "Internship",
      data: ["All", "Job 1", "Job 2", "Job 3"],
      value: jobType,
      setFunc: setJobType,
      md: 2,
      sm: 3
    },
    {
      id: 2,
      role: "Part-time/Full-time",
      name: "All",
      data: ["All", "Part-time", "Full-time", "Data 3"],
      value: partTime,
      setFunc: setPartTime,
      md: 2,
      sm: 3
    },
    {
      id: 3,
      role: "Pay Range",
      name: "All",
      data: ["All", "Pay 1", "Pay 2", "Pay 3"],
      value: pay,
      setFunc: setPay,
      md: 2,
      sm: 3
    },
    {
      id: 4,
      role: "Duration",
      name: "All",
      data: ["All", "Duration 1", "Duration 2", "Duration 3"],
      value: duration,
      setFunc: setDuration,
      md: 1,
      sm: 3
    },
    {
      id: 5,
      role: "Time posted",
      name: "All",
      data: ["All", "Time 1", "Time 2", "Time 3"],
      value: timePosted,
      setFunc: setTimePosted,
      md: 2,
      sm: 3
    },
  ];
  //////   Get Search result of parameters which we got from URL  (required for later )///////////////////

  // useEffect(() => {
  //   // const newLocation = { ...location };
  //   // newLocation.name = loc;
  //   setLocation(loc);
  //   setjobSearchInput(searchInput)
  //   axios
  //     .post(`https://www.back.foxmula.com/job/search/new`, {
  //       search: searchInput,
  //       location: loc,
  //     })
  //     .then((res) => {
  //       console.log(res.data.jobs);
  //       // setsearchResult(res.data);
  //       if (res.data.status) {
  //         // setJobs(res.data.jobs);
  //       } else {
  //         // setJobs([])
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);


  useEffect(() => {
    handleClose()
  }, [matches]);


  const handleSave = () => {
    setSave(!save);
  };


  const handleActive = (id) => {
    const selected = jobsInfo.filter((item) => item._id === id)
    setActive(selected[0]);
    localStorage.setItem('selectedJob', JSON.stringify(selected[0]));
  };

  const handleSearch = () => {
    console.log("In handleSearch");
  };


  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };

  const searchInputHandler = (e) => {
    setjobSearchInput(e.target.value);
  };


  const resetFilters = () => {
    setJobType("")
    setPartTime("")
    setPay("")
    setDuration("")
    setTimePosted("")
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const FilterDropDown = () => (
    <>{
      filters.map((filter, idx) => (
        <>
          <Grid key={idx} container direction={`${matches ? "row" : "column"}`} item md={filter.md} sm={filter.sm} className="filter__entity" >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{filter.role}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter.value}
                disabled
                label={`${filter.role}`}
                onChange={(e) => filter.setFunc(e.target.value)}
              >
                {
                  filter.data.map((option, i) => (
                    <MenuItem key={i} value={option}>{option}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          {idx + 1 % 3 === 0 ? <Grid item xs={12} sm={12} md={12} lg={12} xl={12} /> : <></>}
        </>
      ))
    }
    </>
  )

  return (
    <div className="alljobs">

      <Grid justifyContent="center" >
        <Header />
        <Grid spacing={2} container direction="row" justifyContent="center" >
          {/* <input type="text" className="job-input" placeholder={searchInput} /> */}
          <Grid item xs={10} sm={5} md={4} >
            <TextField className="textfield__title" id="outlined-basic"
              label="Search jobs by title, company or job function"
              variant="outlined"
              value={jobSearchInput}
              onChange={(event) => searchInputHandler(event)}
            />
          </Grid>
          {
            !matches && (<Grid item xs={12} sm={12} md={12} lg={12} xl={12} />)
          }
          <Grid item xs={5} sm={2} md={2} sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Location</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={location}
                disabled
                label="Location"
                onChange={handleChangeLocation}
              >
                {
                  locationData.map((loc) => (<MenuItem value={loc}>{loc}</MenuItem>))
                }
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={5} sm={3} md={2}>
            <Button className="search__button" variant="contained" size="large">
              Search
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <br />

      <Grid container justifyContent={`${matches ? "center" : "center"}`} className="alljobs-filters">
        {
          matches ?
            <>
              <FilterDropDown />
            </>
            :
            <Grid item >
              <Button onClick={handleClickOpen} className="filter__button" variant="outlined" size="large" >
                More Filter
              </Button>
            </Grid>
        }
        <Grid md={1} sm={3} xs={5} item className="reset text-bold" onClick={resetFilters}>
          RESET FILTERS
        </Grid>
      </Grid>


      <div className="alljobs-container">
        <div className="alljobs-container-left">
          <div className="alljobs-container-left-header">
            <span>Save this alert</span>
            <div className="toggle" onClick={handleSave}>
              <div
                className={`toggle-circle ${save ? "toggle-circle-right" : "toggle-circle-left"
                  }`}
              ></div>
            </div>
          </div>
          <div className="alljobs-container-left-cards">
            {jobsInfo.length ? (
              jobsInfo.map((job, idx) => {
                return (
                  <JobCard
                    key={idx}
                    logo={logo}
                    job={job}
                    active={active}
                    handleActive={handleActive}
                  />
                );
              })
            ) : (
              <center className="p-2 mt-4">There are no jobs for search profile in mentioned city</center>
            )}
          </div>
        </div>

        {
          matches
            ? <div className="alljobs-container-right">
              {
                active || active.length
                  ? <Job info={active} search={true} />
                  : null
              }
            </div>
            : null
        }
      </div>
      <Footer />
      <div>
        <Dialog
          open={open}
          // fullScreen={true}
          onClose={handleClose}>
          <DialogTitle className="reset" >
            <Grid container className="filter__dialog" justifyContent="space-between" >
              FILTER
              <IconButton
                aria-label="close"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent >
            <br />
            <Grid container rowSpacing={5}>
              <FilterDropDown />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div >
  );
};
export default AllJobs;

