import axios from "axios";

// Generate a new token for session
export const getNewAuthToken = () => {
  const url = `https://foxmula.herokuapp.com/user/refreshToken`;
  const config = {
    withCredentials: true,
  };

  return axios
    .get(url, config)
    .then((res) => {
      return res.data.newAuthToken;
    })
    .catch((err) => {
      console.log(err);
    });
};

// Get all programs
export const getAllPrograms = async () => {
  const accessToken = await getNewAuthToken();
  const url = `https://foxmula.herokuapp.com/programs`;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return axios.get(url, config);
};

// Get program by id
export const getProgramById = async (id) => {
  const accessToken = await getNewAuthToken();
  const url = `https://foxmula.herokuapp.com/programs/${id}`;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return axios.get(url, config);
};

// Update the program status to INACTIVE or Active
export const updateProgramStatus = async (programId, status) => {
  const accessToken = await getNewAuthToken();
  const url = `https://foxmula.herokuapp.com/programs/status/${programId}`;
  const body = {
    program_status: status,
  };
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .put(url, body, config)
    .then((res) => {
      console.log("Program Status Updated", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

//TODO: Get all issues of program
export const getAllIssues = async (programId) => {
  const accessToken = await getNewAuthToken();
  const url = `https://foxmula.herokuapp.com/programs/${programId}`;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return axios
    .get(url, config)
    .then((res) => {
      console.log("All Issues", res.data.issues);
      return res.data.issues;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

// Update Issue Status
//TODO: test
export const updateIssueStatus = async (issueId, status) => {
  const accessToken = await getNewAuthToken();
  const url = `https://foxmula.herokuapp.com/issues/${issueId}`;
  const body = {
    status: status,
  };
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .put(url, body, config)
    .then((res) => {
      console.log("Issue Status Updated", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

// Create new certificate
export const createCertificate = async (programObj) => {
  const accessToken = await getNewAuthToken();
  const url = `https://foxmula.herokuapp.com/programs`;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
    .post(url, programObj, config)
    .then((res) => {
      console.log("Certificate Created", res.data);
      return res.data.program.programId;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

//update certificate
export const updateProgram = async (programId, updatedProgram) => {
  const accessToken = await getNewAuthToken();
  const url = `https://foxmula.herokuapp.com/programs/${programId}`;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios

    .put(url, updatedProgram, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

// Get Students of Exam
export const getBookingInfo = async (examId, month, year, token) => {
  const accessToken = await getNewAuthToken();
  const url = `https://foxmula.herokuapp.com/exams/students/${examId}?month=${month}&year=${year}`;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return axios.get(url, config);
};

//saveExamLink
export const saveExamLink = async (examLink, examDate, examId) => {
  const accessToken = await getNewAuthToken();
  const url = `https://foxmula.herokuapp.com/exams`;
  const body = {
    exam_date: examDate,
    exam_link: examLink,
    program_id: examId,
  };
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
    .post(url, body, config)
    .then((res) => {
      console.log("Exam Link Saved", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

//Update Booking status // eg. Cancle booking.
export const updateBookingStatus = async (bookingId, status, token) => {
  const accessToken = await getNewAuthToken();
  const url = `https://foxmula.herokuapp.com/exams/students/${bookingId}`;
  const body = {
    booking_status: status,
  };
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
  };

  return axios
    .put(url, body, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
